import http from "./httpService";

const apiEndpoint = http.apiUrl + "school-home";

// service is used to get all available job
export function getAllAvailableJob() {
  return http.get(apiEndpoint + "/available");
}

// service to get activity stats
export function getSchoolActivityStats() {
  return http.get(apiEndpoint + "/activity");
}

// service to get recently posted jobs
export function getRecentlyPostedJobs() {
  return http.get(apiEndpoint + "/recently-posted-jobs");
}

// service to get recently verified teachers
export function getRecentlyVerifiedTeachers() {
  return http.get(apiEndpoint + "/recently-verified-teachers");
}
