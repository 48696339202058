import React from "react";

// components
import { CardTitle } from "../../../../../../../components";
import { HomeCard } from "../../../../../../components/dashboard";
import { ContentItem, ContentWrapper } from "./ScheduleDetails";

function AdditionalDetails({ notes }) {
  return (
    <HomeCard>
      <CardTitle>Additional Details</CardTitle>
      <ContentWrapper>
        <ContentItem title="Notes">{notes ? notes : "-"}</ContentItem>
      </ContentWrapper>
    </HomeCard>
  );
}

export default AdditionalDetails;
