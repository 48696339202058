import React from "react";

import styles from "./allTeacher.module.css";

function AllTeacherHeader() {
  return (
    <tr className={`${styles.itemContainer} ${styles.headerCellContainer}`}>
      <th className={styles.headerText}>Name & TRN</th>
      <th className={`${styles.headerText} ${styles.expertiesColumn}`}>
        Expertise
      </th>
      <th className={`${styles.headerText} ${styles.expertiesColumn}`}>
        Availability
      </th>
      <th className={`${styles.headerText} ${styles.verifyDate}`}>
        Verified Date
      </th>
      <th className={styles.headerText}>Action</th>
    </tr>
  );
}

export default AllTeacherHeader;
