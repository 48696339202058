import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// components
import JobHeaderFilter from "./JobHeaderFilter";
import JobItem from "./JobItem";
import JobListingHeader from "./JobListingHeader";
import { Pagination } from "../../../../../../components";
import { DotLoader, NoRecordFound } from "../../../../../../../components";

// hooks
// import useResponse from "../../../../../../../hooks/useResponse";
// services
import { getAllJobWithPagination } from "../../../../../../../services/jobService";

import * as toast from "../../../../../../../Toast";

import styles from "./jobListing.module.css";

const defaultFilter = {
  jobType: "",
  status: "",
  category: "",
};

function Listing() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);

  const [filter, setFilter] = useState(defaultFilter);

  const page = currentPage;

  const setPage = (page) => {
    setCurrentPage(page);
  };

  const setPageToStart = () => {
    setCurrentPage(1);
  };

  const fetchData = useCallback(async ({ page, filter }) => {
    try {
      setLoading(true);
      const response = await getAllJobWithPagination({
        page: page,
        limit: 5,
        filter,
      });
      const { data } = await response.data;
      setData(data.result);
      setTotalCount(data.total);
    } catch (ex) {
      toast.error(ex.response.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData({ page: page, filter });
  }, [fetchData, page, filter]);

  if (loading) {
    return <DotLoader />;
  }

  const handleCellClick = (jobId) => {
    navigate(`/school/dashboard/job/details/${jobId}`);
  };

  let listings;
  if (data && data.length > 0) {
    listings = (
      <tbody>
        {data.map((job, index) => (
          <JobItem job={job} key={index + 1} onCellClick={handleCellClick} />
        ))}
      </tbody>
    );
  } else {
    listings = (
      <tbody>
        <tr>
          <td>
            <NoRecordFound />
          </td>
        </tr>
      </tbody>
    );
  }

  const handleJobTypeChange = (jobType) => {
    setFilter((prev) => {
      return { ...prev, jobType };
    });
    setPageToStart();
  };

  const handleStatusChange = (status) => {
    setFilter((prev) => {
      return { ...prev, status };
    });
    setPageToStart();
  };

  const handleCategoryChange = (category) => {
    setFilter((prev) => {
      return { ...prev, category };
    });
    setPageToStart();
  };

  const handleResetFilter = () => {
    setFilter(defaultFilter);
    setPageToStart();
  };

  return (
    <div>
      <JobHeaderFilter
        filter={filter}
        onChangeJobCategory={handleCategoryChange}
        onChangeJobStatus={handleStatusChange}
        onChangeJobType={handleJobTypeChange}
        onReset={handleResetFilter}
      />
      <table className={styles.listingContainer}>
        <thead className={styles.headerContainer}>
          <JobListingHeader />
        </thead>
        {listings && listings}
      </table>

      <Pagination
        currentPage={page}
        totalCount={totalCount}
        pageSize={5}
        onPageChange={setPage}
      />
    </div>
  );
}

export default Listing;
