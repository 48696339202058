export const radiusList = [
  { key: "2Km", value: "2Km" },
  { key: "5km", value: "5km" },
  {
    key: "10km",
    value: "10km",
  },
  {
    key: "50km",
    value: "50km",
  },
  {
    key: "100km",
    value: "100km",
  },
];

export const availabilityList = [
  { key: "Emergency", value: "Emergency" },
  { key: "Long-term", value: "Long-term" },
];
