import React from "react";
import { useNavigate } from "react-router-dom";
// components
import Layout from "../../../../components/Layout";
import LoginForm from "../../../components/LoginForm";

// hooks
import useResponse from "../../../../hooks/useResponse";

// image
import WelcomeImage from "../../../../assets/images/t-login.png";

// services
import * as schoolAuthService from "../../../../services/schoolAuthService";
import * as auth from "../../../../services/authService";
import * as toast from "../../../../Toast";

function SchoolLogin() {
  const navigator = useNavigate();
  const [state, action] = useResponse();

  const navigateToSchoolRegistation = () => {
    navigator("/school/register-step-first");
  };

  const handleSubmit = async (loginData, formikObject) => {
    action.sendingRequst();
    // triming object
    try {
      const response = await schoolAuthService.schoolLogin(loginData);
      const { data } = await response.data;

      // setting auth token for login user
      schoolAuthService.setJWT(data.token);
      auth.setJWT(data.token);

      window.location = "/school/dashboard/";
      // formikObject.resetForm();
      toast.success("Successfully logged in.");
      action.successResponse(response.data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        action.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <Layout imageUrl={WelcomeImage} showTermPrivary>
      <LoginForm
        title="Hi There!"
        path="/school/forgot-password"
        onRegister={navigateToSchoolRegistation}
        onSubmit={handleSubmit}
        error={state.error}
        loading={state.loading}
      />
    </Layout>
  );
}

export default SchoolLogin;
