import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import styles from "../home.module.css";
import { HomeCard } from "../../../../../../components/dashboard";

import { useQuery } from "react-query";
import { getSchoolActivityStats } from "../../../../../../../services/schoolHomeService";

import { Loader } from "../../../../../../../components";

const Card = styled("div")`
  border: 1px solid #eee9e8;
  border-radius: 12px;
  height: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
`;

const CardValue = styled("p")`
  font-size: 34px;
  line-height: 36px;
  color: ${(props) => props.variant === "applied" && "#4a7d9a"};
  color: ${(props) => props.variant === "completed" && "#D28540"};
  color: ${(props) => props.variant === "pending" && "#50AB7F"};
  color: ${(props) => props.variant === "cancelled" && "#A04D47"};
`;

const CardDesc = styled("p")`
  text-align: center;
  letter-spacing: 0.1px;
  color: #181c29;
  font-size: 17px;
  line-height: 24px;
`;

const CardTitle = styled("h2")`
  font-size: 2rem;
  font-weight: 500;
  color: #181c29;
  margin-bottom: 1rem;
`;

function ActivityCard() {
  const { isError, isLoading, data } = useQuery(
    ["school-activity"],
    () => getSchoolActivityStats(),
    { refetchOnWindowFocus: true, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <p>Some error occurred</p>;
  }

  const jobsOpenings = data.data.activity.jobsOpenings;
  const jobsBooked = data.data.activity.jobsBooked;
  const jobsCompleted = data.data.activity.jobsCompleted;
  const jobsCancelled = data.data.activity.jobsCancelled;

  // const jobsOpenings = 10;
  // const jobsBooked = 10;
  // const jobsCompleted = 10;
  // const jobsCancelled = 10;

  return (
    <HomeCard className={styles.gridfirstchild}>
      <CardTitle>Activity</CardTitle>

      <Grid container spacing={2} columns={12}>
        <Grid item xs={6}>
          <Card>
            <CardValue variant="applied">{jobsOpenings}</CardValue>
            <CardDesc>Job Openings</CardDesc>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardValue variant="completed">{jobsBooked}</CardValue>
            <CardDesc>Jobs Booked</CardDesc>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardValue variant="pending">{jobsCompleted}</CardValue>
            <CardDesc>Jobs Completed</CardDesc>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardValue variant="cancelled">{jobsCancelled}</CardValue>
            <CardDesc>Jobs Cancelled</CardDesc>
          </Card>
        </Grid>
      </Grid>
    </HomeCard>
  );
}

export default ActivityCard;
