import React from "react";

import styles from "./jobListing.module.css";

function JobListingHeader() {
  return (
    <tr className={`${styles.itemContainer} ${styles.headerCellContainer}`}>
      <th className={styles.headerText}>Job Title</th>
      <th className={styles.headerText}>Class Year</th>
      <th className={`${styles.headerText} ${styles.dateTimeColumn}`}>
        Date & Time
      </th>
      <th className={`${styles.headerText}`}>Applicants</th>
      <th className={styles.headerText}>Job Type</th>
      <th className={styles.headerText}>Job Status</th>
    </tr>
  );
}

export default JobListingHeader;
