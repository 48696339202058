import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";

// components
import Card from "./Card";
import {
  FormInput,
  FormLocationInput,
  FormInputPassword,
  FormButton,
} from "../../components/Forms";

// common validator
import {
  whitespaceValidation,
  numberTypeValidation,
  passwordValidation,
} from "../validator/common";

import styles from "./form.module.css";

// school registeration validationSchema
const validationSchema = Yup.object().shape({
  schoolName: Yup.string()
    .required()
    .min(2)
    .max(200)
    .label("School Name")
    .trim(),
  schoolEmail: Yup.string()
    .required()
    .min(5)
    .max(100)
    .email()
    .label("Email")
    .trim(),
  password: Yup.string()
    .required()
    .min(6)
    .max(30)
    .trim()
    .test(
      "type",
      "Password must not contain Whitespaces.",
      whitespaceValidation
    )
    .test(
      "type",
      "Password must contain at least one Special Character(Special characters @ # $ ! allowed) and one digit.",
      passwordValidation
    )
    .label("Password"),
  cPassword: Yup.string()
    .required()
    .min(6)
    .max(30)
    .label("Confirm Password")
    .oneOf([Yup.ref("password")], "Confirm password does not match.")
    .trim(),
  schoolPhoneNumber: Yup.string()
    .required()
    .test("type", "Please type only number", numberTypeValidation)
    .min(10, "Contact Number must be at min of 10 digit number")
    .max(10, "Contact Number must be at max of 10 digit number")
    .label("Contact Number")
    .trim()
    .test(
      "type",
      "Contact number must not contain Whitespaces.",
      whitespaceValidation
    ),
  schoolAddress: Yup.string().required().min(2).label("Street Address").trim(),
  schoolLatitude: Yup.number().required().label("School Latitude"),
  schoolLongitude: Yup.number().required().label("School Longitude"),
  suburbName: Yup.string()
    .required()
    .min(2)
    .max(50)
    .label("Suburb Name")
    .trim(),
  state: Yup.string().required().min(2).max(50).label("State").trim(),
  postcode: Yup.string()
    .required()
    .test("type", "Please type only number", numberTypeValidation)
    .min(4, "Postcode must be at min of 4 digit number")
    .max(6, "Postcode must be at max of 6 digit number")
    .label("Postcode")
    .trim()
    .test(
      "type",
      "Postcode must not contain Whitespaces.",
      whitespaceValidation
    ),
});

function SchoolSignupFormFirst({ title, onSubmit, error, loading }) {
  return (
    <div className={styles.container}>
      <h1 className={styles.titleHeading}>{title}</h1>
      <div className={styles.barLine}>
        <div className={styles.fillLine}></div>
      </div>

      <Formik
        initialValues={{
          schoolName: "",
          schoolEmail: "",
          password: "",
          cPassword: "",
          schoolPhoneNumber: "",
          schoolAddress: "",
          schoolLatitude: 0,
          schoolLongitude: 0,
          suburbName: "",
          state: "",
          postcode: "",
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        className={styles.formContainer}
      >
        {({ setFieldValue }) => (
          <>
            <Card>
              <div className={styles.formChildContainer}>
                <h1 className={styles.formTitle}>Contact Details</h1>
                <FormInput
                  label="Enter school name"
                  name="schoolName"
                  type="text"
                />
                <FormInput
                  label="Enter school’s email address"
                  name="schoolEmail"
                  type="text"
                />
                <FormInputPassword
                  label="Password"
                  name="password"
                  type="password"
                />
                <FormInputPassword
                  label="Confirm Password"
                  name="cPassword"
                  type="password"
                />
                <FormInput
                  label="Enter school’s contact number"
                  name="schoolPhoneNumber"
                  type="text"
                />
                <FormLocationInput
                  label="Enter school’s street address"
                  name="schoolAddress"
                  type="text"
                  placeholder=""
                  onCordinateChange={({ lat, lng }) => {
                    setFieldValue("schoolLatitude", lat);
                    setFieldValue("schoolLongitude", lng);
                  }}
                />
                <FormInput label="Suburb name" name="suburbName" type="text" />

                <div className={styles.gridContainer}>
                  <FormInput label="State" name="state" type="text" />
                  <FormInput label="Postcode" name="postcode" type="text" />
                </div>
              </div>

              {/* {error && <p className="error">{error}</p>} */}
            </Card>
            <FormButton
              buttonText="Continue"
              type="submit"
              submitButton={true}
              isDisabled={true}
              loading={loading}
            />
          </>
        )}
      </Formik>
    </div>
  );
}

export default SchoolSignupFormFirst;
