import http from "./httpService";

const apiEndpoint = http.apiUrl + "profile";

// service is used to get teacher information and teacher documents
export function getTeacherInfo() {
  return http.get(apiEndpoint + "/teacher");
}

// service is used to get school information
export function getSchoolInfo() {
  return http.get(apiEndpoint + "/school");
}

// service to get teacher information
export const getTeacherEditProfile = () => {
  return http.get(apiEndpoint + "/edit-profile");
};

// service to update teacher profile
export function updateTeacherEditProfile(data) {
  return http.put(apiEndpoint + "/teacher/edit-profile", data);
}

// service to update teacher password
export function updateTeacherChangePassord(data) {
  return http.put(apiEndpoint + "/teacher/change-password", data);
}

// service to upload certificate uploaded by teacher
export function addCertificateTeacher(data) {
  return http.put(apiEndpoint + "/teacher/add-certificate", data);
}

// service to delete certificate uploaded by teacher
export function deleteCertificateTeacher(id) {
  return http.delete(apiEndpoint + `/teacher/delete-certificate/${id}`);
}

// service to get the values for about page - teacher
export const getConfigInfo = () => {
  return http.get(http.apiUrl + `config/teacher`);
};

// service to update school profile
export function updateSchoolEditProfile(data) {
  return http.put(apiEndpoint + "/school/edit-profile", data);
}

// service to update school password
export function updateSchoolChangePassord(data) {
  return http.put(apiEndpoint + "/school/change-password", data);
}

// service to get the values for about page - school
export const getConfigInfoSchool = () => {
  return http.get(http.apiUrl + `config/school`);
};
