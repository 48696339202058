import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  FormImageInput,
  FormInput,
  FormLocationInput,
  FormMultiSelect,
  FormSelect,
} from "../../../../../../../../components/Forms";
import { HomeCard } from "../../../../../../../components/dashboard";
import { CardTitle, Loader } from "../../../../../../../../components";
import { imageUploadAPI } from "../../../../../../../../services/uploadService";
import generateImageUrl from "../../../../../../../../utils/generateImageUrl";
import Button from "../../components/Button";
import { editProfileUpdate } from "../../../../../../../../stores/teacherRegisterationReducer";

import { ProfilePhoto } from "./EditProfile.styles";
import {
  availabilityList,
  radiusList,
} from "../../../../../../../../constants/teacher";
import {
  numberTypeValidation,
  whitespaceValidation,
} from "../../../../../../../validator/common";
import {
  categoryOptions,
  classYearOptions,
  expertiesOptions,
} from "../../../../../../../components/job-form-options";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required().min(1).label("Name").trim(),
  email: Yup.string().required().min(6).email().label("Email").trim(),
  profileURL: Yup.string().label("Profile Photo").trim(),
  phoneNumber: Yup.string()
    .required()
    .test("type", "Please type only number", numberTypeValidation)
    .min(10, "Contact Number must be at min of 10 digit number")
    .max(10, "Contact Number must be at max of 10 digit number")
    .label("Phone Number")
    .trim(),
  locationAddress: Yup.string()
    .required()
    .min(6)
    .label("Location Address")
    .trim(),
  latitude: Yup.number().required().label("Latitude"),
  longitude: Yup.number().required().label("Longitude"),
  radius: Yup.string().required().label("Radius"),
  suburbName: Yup.string()
    .required()
    .min(2)
    .max(50)
    .label("Suburb Name")
    .trim(),
  state: Yup.string().required().min(2).max(50).label("State").trim(),
  postcode: Yup.string()
    .required()
    .test("type", "Please type only number", numberTypeValidation)
    .min(4, "Postcode must be at min of 4 digit")
    .max(6, "Postcode must be at max of 6 digit")
    .label("Postcode")
    .trim()
    .test(
      "type",
      "Postcode must not contain Whitespaces.",
      whitespaceValidation
    ),
  teacherRegNumber: Yup.string()
    .required()
    .min(2)
    .max(50)
    .label("Teacher Registration Number")
    .trim(),
  availability: Yup.array()
    .required("Required")
    .min(1, "Please select availability.")
    .label("Availability"),

  category: Yup.string().required().label("Category"),
  classPreference: Yup.array()
    .required("Required")
    .min(1, "Please select class preference.")
    .label("Class Year Preference"),

  expertise: Yup.array()
    .required("Required")
    .min(1, "Please select expertise.")
    .label("Expertise"),
  bio: Yup.string().required().min(2).max(2000).label("Bio").trim(),
});

const EditProfile = () => {
  const { loading, teacher, error } = useSelector(
    (state) => state.entities.teacherRegisteration
  );
  const [category, setCategory] = useState("Early Learning");

  const dispatch = useDispatch();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>Some Error Occurred</p>;
  }

  const onSubmit = async (data, options) => {
    if (data.selectedProfilePhoto) {
      const profilePhoto = await imageUploadAPI(data.selectedProfilePhoto);
      data.profileURL = profilePhoto.data.file.filename;
    }

    delete data.email;
    delete data.selectedProfilePhoto;
    delete data.checkResolution;

    // resetForm();
    dispatch(editProfileUpdate(data));

    options.resetForm();
  };

  const initialValues = {
    fullName: teacher.fullName,
    email: teacher.email,
    phoneNumber: teacher.phoneNumber,
    profileURL: teacher.profileURL,
    selectedProfilePhoto: "",
    locationAddress: teacher.locationAddress,
    latitude: teacher.latitude,
    longitude: teacher.longitude,
    radius: teacher.radius,
    suburbName: teacher.suburbName,
    state: teacher.state,
    postcode: teacher.postcode,
    teacherRegNumber: teacher.teacherRegNumber,
    availability: teacher.availability,
    category: teacher.category,
    classPreference: teacher.classPreference,
    expertise: teacher.expertise,
    bio: teacher.bio,
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        setFieldValue,
        values,
        setFieldTouched,
        setFieldError,
        handleSubmit,
      }) => (
        <Stack spacing={2}>
          <HomeCard>
            <CardTitle
              style={{
                marginBottom: "2rem",
              }}
            >
              Profile Photo
            </CardTitle>
            <Stack direction="row" spacing={4}>
              {values.profileURL.length > 0 ? (
                <ProfilePhoto
                  src={generateImageUrl(values.profileURL)}
                  alt={values.fullName}
                />
              ) : null}

              <FormImageInput
                name="selectedProfilePhoto"
                label="Profile Photo"
                labelResolution="450 * 300 px"
                imageValues={values.profileURL}
                onChangeSelectedImage={(event) => {
                  setFieldTouched(event.target.name);
                  setFieldValue(event.target.name, event.target.files[0]);
                }}
                onError={(message) => {
                  setFieldError("selectedProfilePhoto", message);
                }}
                onCheckRes={(checkResolution) => {
                  setFieldValue("checkResolution", checkResolution);
                }}
                // checkResolution={values.checkResolution}
              />
            </Stack>
          </HomeCard>

          <HomeCard>
            <CardTitle
              style={{
                marginBottom: "2rem",
              }}
            >
              Personal Details
            </CardTitle>
            <Stack spacing={1}>
              <FormInput label="Enter Name" name="fullName" type="text" />
              <FormInput
                disabled
                label="Email address"
                name="email"
                type="email"
              />
              <FormInput label="Phone number" name="phoneNumber" type="text" />
            </Stack>

            <CardTitle
              style={{
                marginTop: "3rem",
                marginBottom: "2rem",
              }}
            >
              Location Details
            </CardTitle>
            <Stack spacing={1}>
              <FormLocationInput
                label="Current location"
                name="locationAddress"
                type="text"
                placeholder=""
                onCordinateChange={({ lat, lng }) => {
                  setFieldValue("latitude", lat);
                  setFieldValue("longitude", lng);
                }}
              />
              <FormSelect
                label="Location radius"
                name="radius"
                options={radiusList}
              />

              <FormInput
                label="Enter your suburb name"
                name="suburbName"
                type="text"
              />

              <Stack direction="row" spacing={1}>
                <FormInput label="State" name="state" type="text" />
                <FormInput label="Postcode" name="postcode" type="text" />
              </Stack>
            </Stack>

            <CardTitle
              style={{
                marginTop: "3rem",
                marginBottom: "2rem",
              }}
            >
              Job Details/Preferences
            </CardTitle>
            <Stack spacing={1}>
              <FormInput
                label="Enter your teacher registration number"
                name="teacherRegNumber"
                type="text"
              />
              <FormMultiSelect
                label="Select your availability"
                name="availability"
                options={availabilityList}
              />
              <FormSelect
                label="Select your experience category preference"
                name="category"
                options={categoryOptions}
                onChange={(value) => {
                  if (value !== category) {
                    setCategory(value);
                    setFieldValue("classPreference", []);
                    setFieldValue("expertise", []);
                  }
                }}
              />
              <FormMultiSelect
                label="Select your class year preference"
                name="classPreference"
                options={classYearOptions[values.category]}
              />
              <FormMultiSelect
                label="Select your expertise"
                name="expertise"
                options={expertiesOptions[values.category]}
              />
              <FormInput
                label="Type a bio about your experience"
                name="bio"
                type="textarea"
              />
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                marginTop: "2rem",
              }}
            >
              <Button onClick={handleSubmit} type="submit" primary>
                Save
              </Button>
            </Stack>
          </HomeCard>
        </Stack>
      )}
    </Formik>
  );
};

export default EditProfile;
