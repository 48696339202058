import React from "react";
import { useQuery } from "react-query";
import { Stack } from "@mui/system";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// components
import {
  ConfirmButton,
  ErrorMessage,
} from "../../../../../../../../components/Forms";
import { HomeCard } from "../../../../../../../components/dashboard";
import { CardTitle } from "../../../../../../../components/JobForm";
import { AddCardModal } from "../../../../../../../../components/Modal";
import {
  AddDocumentButton,
  AddIconStyles,
  CertificateRow,
  CertificateRowValue,
  GridContainer,
} from "../CertificatesLicenses/CertificatesLicenses.styles";
import { Loader } from "../../../../../../../../components";
import { ConfirmationModal } from "../../../../../../../components";

// hooks
import useVisible from "../../../../../../../../hooks/useVisible";
import useResponse from "../../../../../../../../hooks/useResponse";

// services
import {
  getTeacherCardInfo,
  deleteTeacherCard,
} from "../../../../../../../../services/paymentMethodService";

// services
import * as toast from "../../../../../../../../Toast";

import cardIcon from "../assets/debit-cards.png";

function PaymentMethod() {
  const stripePromise = loadStripe(
    "pk_test_51M7FvkARvof8LIj66KdRnv7oX3t2diWmBkKyRWhv1mnzpovMkcyfcNCRrhbTQWFzzUKC4h9eoRF8hU5us73h7cMc00tgm2Kiov"
  );

  const [showDeleteModal, toggleDeleteModal] = useVisible(false);
  const [state, action] = useResponse(false);
  const [showAddCard, toggleAddCard] = useVisible(false);
  const { isError, isLoading, data, error, refetch } = useQuery(
    ["teacher-job-details"],
    () => getTeacherCardInfo(),
    { refetchOnWindowFocus: true, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const customerCard = data.data.data["customerCard"];
  // console.log(customerCard);

  const handleDeleteCardConfirm = async () => {
    try {
      action.sendingRequst();

      const response = await deleteTeacherCard();
      const { message } = await response.data;

      // refetching card information again
      await refetch();

      toggleDeleteModal();

      toast.success(message);
      action.successResponse(message);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        action.gotError(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  };

  const handleAddCard = async () => {
    await refetch();
  };

  return (
    <>
      <ConfirmationModal
        subtitle="Are you sure wanted to delete card?"
        ConfirmButtonText="Yes"
        CancelButtonText="No"
        showModal={showDeleteModal}
        onConfirm={handleDeleteCardConfirm}
        toggleModal={toggleDeleteModal}
        loading={state.loading}
      />
      <Elements stripe={stripePromise}>
        <AddCardModal
          showModal={showAddCard}
          toggleModal={toggleAddCard}
          onSubmit={handleAddCard}
        />
      </Elements>
      <HomeCard>
        <CardTitle
          style={{
            marginBottom: "1rem",
          }}
        >
          Payment Method
        </CardTitle>

        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore
          repellat ut voluptatibus esse debitis dolores magnam nisi qui nemo ab,
          cum incidunt quos, labore nihil. Dolores perspiciatis cupiditate vitae
          quis!
        </p>

        {customerCard ? (
          <CertificateRow container columns={12} padding={2}>
            <GridContainer item xs={5}>
              <CertificateRowValue>
                {customerCard.brand}card ending in {customerCard.last4}
              </CertificateRowValue>
            </GridContainer>
            <GridContainer item xs={4}>
              <CertificateRowValue>
                Expires
                {" " + customerCard.exp_month}/{customerCard.exp_year}
              </CertificateRowValue>
            </GridContainer>

            <GridContainer item xs={3}>
              <ConfirmButton
                buttonText="Delete Card"
                size={1.6}
                isBackground={false}
                onClick={toggleDeleteModal}
              />
            </GridContainer>
          </CertificateRow>
        ) : (
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            my={2}
            width="100%"
          >
            <AddDocumentButton
              transparent
              type="button"
              secondary
              style={{
                border: "2px solid var(--primary-full)",
                padding: "1rem 2rem",
                width: "40rem",
              }}
              onClick={toggleAddCard}
            >
              <AddIconStyles src={cardIcon} alt="plus" />
              Add New Payment Method
            </AddDocumentButton>
          </Stack>
        )}
      </HomeCard>
    </>
  );
}

export default PaymentMethod;
