import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// school pages
import TeacherLogin from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import TeacherDashboard from "./pages/dashboard";
import ActiveTeacherAccount from "./pages/active";

// guest & protected route
import { GuestRoute, ProtectedRoute } from "../../components";

// register step form
import {
  RegisterStepFirst,
  RegisterStepSecond,
  RegisterStepThird,
} from "./pages/register";

function Teacher() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/teacher/login" />} />
        <Route
          path="/login"
          element={
            <GuestRoute>
              <TeacherLogin />
            </GuestRoute>
          }
        />

        <Route
          path="/register-step-first"
          element={
            <GuestRoute>
              <RegisterStepFirst />
            </GuestRoute>
          }
        />

        <Route
          path="/register-step-second"
          element={
            <GuestRoute>
              <RegisterStepSecond />
            </GuestRoute>
          }
        />
        <Route
          path="/register-step-third"
          element={
            <GuestRoute>
              <RegisterStepThird />
            </GuestRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <GuestRoute>
              <ForgotPassword />
            </GuestRoute>
          }
        />

        <Route
          path="/active/:token"
          element={
            <GuestRoute>
              <ActiveTeacherAccount />
            </GuestRoute>
          }
        />

        <Route
          path="/reset/:token"
          element={
            <GuestRoute>
              <ResetPassword />
            </GuestRoute>
          }
        />
        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute>
              <TeacherDashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default Teacher;
