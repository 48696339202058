import React from "react";
import moment from "moment";
import { Stack } from "@mui/system";

// components
import { CardTitle } from "../../../../../../../components";
import { HomeCard } from "../../../../../../components/dashboard";
import { ContentItem, ContentWrapper } from "./ScheduleDetails";

import styles from "./jobDetails.module.css";

function JobDetail({ job }) {
  const {
    jobTitle,
    jobType,
    startDate,
    endDate,
    time,
    weekDays,
    recurrence,
    classYear,
    expertise,
    position,
    notes,
    schoolId,
  } = job;
  const { schoolName, schoolAddress } = schoolId;

  let dateAndTime;

  // dateAndTime = `${moment(startDate).format("DD MMM YYYY")} at
  //   ${moment(time).format("h:mm A")}`;

  if (recurrence === "Recurring") {
    dateAndTime = `${moment(startDate).format("DD MMM YYYY ")}  to
      ${moment(endDate).format("DD MMM YYYY ")} at
      ${moment(time).format("  h:mm A")} (${weekDays.map((day) => day + " ")})`;
  } else {
    dateAndTime = `${moment(startDate).format("DD MMM YYYY")} at
      ${moment(time).format("h:mm A")}`;
  }

  return (
    <HomeCard>
      <Stack spacing={2}>
        <div>
          <CardTitle>School Details</CardTitle>
          <ContentWrapper>
            <ContentItem title="School Name & Location">
              {schoolName}{" "}
              <span className={styles.schoolAddress}> {schoolAddress}</span>
            </ContentItem>
          </ContentWrapper>
        </div>
        <div>
          <CardTitle>Basic Details</CardTitle>
          <ContentWrapper>
            <ContentItem title="Job Title">{jobTitle}</ContentItem>
            <ContentItem title="Date & Time">
              {dateAndTime && dateAndTime}
            </ContentItem>
          </ContentWrapper>
        </div>
        <div>
          <CardTitle>Schedule Details</CardTitle>
          <ContentWrapper>
            <ContentItem title="Job Type">{jobType}</ContentItem>
            <ContentItem title="Recurrence">{recurrence}</ContentItem>
          </ContentWrapper>
        </div>
        <div>
          <CardTitle>Experience Details</CardTitle>
          <ContentWrapper>
            <ContentItem title="Class Year">{classYear}</ContentItem>
            <ContentItem title="Position to Cover">
              {position}, {expertise}
            </ContentItem>
          </ContentWrapper>
        </div>
        <div>
          <CardTitle>Additional Details</CardTitle>
          <ContentWrapper>
            <ContentItem title="Notes">{notes ? notes : "-"}</ContentItem>
          </ContentWrapper>
        </div>
      </Stack>
    </HomeCard>
  );
}

export default JobDetail;
