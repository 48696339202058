import http from "./httpService";

const apiEndpoint = http.apiUrl + "payment";

// service to check teacher has added card details or not.
export function checkTeacherHasAddedCard() {
  return http.get(apiEndpoint + "/check-card");
}

// service to get payment card information
export function getTeacherCardInfo() {
  return http.get(apiEndpoint + "/card-info");
}

// service to add card in teacher accounts
export function addTeacherCard(data) {
  return http.post(apiEndpoint + "/add-card", data);
}

// service to delete card in teacher account
export function deleteTeacherCard() {
  return http.delete(apiEndpoint + "/delete-card");
}
