import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// components
import Layout from "../../../../components/Layout";
import SchoolSignupFormSecond from "../../../components/SchoolSignupFormSecond";

// hooks
import useResponse from "../../../../hooks/useResponse";

// image
import StepSecondImage from "../../../../assets/images/t-step2.png";

// action creator
import {
  setSchoolStepSecondData,
  getSchoolRegisterInfo,
} from "../../../../stores/schoolRegisterationReducer";

// services
import * as schoolAuthService from "../../../../services/schoolAuthService";
import * as toast from "../../../../Toast";
// utils
import { clearSchoolRegistrationData } from "../../../../utils/schoolRegisterData";

function RegisterStepSecond() {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [state, action] = useResponse();
  const schoolRegisterInfo = useSelector(getSchoolRegisterInfo);

  const handleSubmit = async (schoolData) => {
    action.sendingRequst();

    try {
      const response = await schoolAuthService.schoolRegister({
        ...schoolRegisterInfo,
        ...schoolData,
      });
      const { data } = await response.data;
      console.log(data);
      dispatch(
        setSchoolStepSecondData({
          ...schoolData,
        })
      );
      navigator("/school");
      // formkiObject.resetForm();
      toast.success(response.data.message);
      action.successResponse(response.data.message);

      // clear school registration data from global storage
      clearSchoolRegistrationData();
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        action.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <Layout imageUrl={StepSecondImage}>
      <SchoolSignupFormSecond
        title="Create Your Account"
        onSubmit={handleSubmit}
        error={state.error}
        loading={state.loading}
      />
    </Layout>
  );
}

export default RegisterStepSecond;
