import http from "./httpService";

const apiEndpoint = http.apiUrl + "teacher-home";

// service is used to get all available job
export function getAllAvailableJob() {
  return http.get(apiEndpoint + "/available");
}

// service to get activity stats
export function getActivityStats() {
  return http.get(apiEndpoint + "/activity");
}

// service to get recently applied jobs
export function getRecentlyAppliedJobs() {
  return http.get(apiEndpoint + "/recently-applied-jobs");
}
