import React, { useRef, useState } from "react";

// components
import { CheckBoxSingleSelectFilter } from "../Forms";

// hooks
import useOnClickOutside from "../../hooks/useOnClickOutside";

import styles from "../Forms/multiSelect.module.css";
import styles2 from "./formInput.module.css";

function ListFilterDropdownModal(props) {
  const {
    label,
    name,
    options,
    showModal,
    value,
    onChange,
    toggleShowModal,
    ...rest
  } = props;
  const ref = useRef(null);
  const [isOpen, toggleOpen] = useState(showModal);

  useOnClickOutside(ref, () => {
    toggleOpen(false);
    toggleShowModal();
  });

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const field = { value, onChange: handleChange };

  return (
    <div
      className={`${styles2.InputfieldContainer} ${styles2.filterMainContainer}`}
      ref={ref}
    >
      <div
        className={`${styles.checkboxContainer} ${styles2.checkboxContainer} ${
          styles2.filterCheckbox
        }  ${isOpen && styles.open}`}
        // style={{ width: "40rem" }}
      >
        {options.map((option) => (
          <CheckBoxSingleSelectFilter
            key={option.key}
            field={field}
            option={option}
            setValue={onChange}
            {...rest}
          />
        ))}
      </div>
    </div>
  );
}

export default ListFilterDropdownModal;
