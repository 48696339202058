import { Stack } from "@mui/material";
import React from "react";
import { HomeCard } from "../../../../../../../components/dashboard";
import { CardTitle } from "../../../../../../../components/JobForm";
import { styled } from "@mui/material/styles";
import { useQuery } from "react-query";
import { getConfigInfoSchool } from "../../../../../../../../services/profileService";
import { Loader } from "../../../../../../../../components";

const Text = styled("p")`
  color: var(--text-secondary);
  line-height: 20px;
  letter-spacing: 0.25px;
  font-size: 13px;
`;

const About = () => {
  const { isLoading, data, error } = useQuery(
    ["config-teacher"],
    getConfigInfoSchool,
    { refetchOnWindowFocus: true, cacheTime: 100 }
  );

  const termsCondition = data?.data?.data?.config?.termsConditionsDetail;
  const privacy = data?.data?.data?.config?.privacyPolicyDetail;

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <p>Something went wrong</p>;
  }

  return (
    <Stack spacing={2}>
      <HomeCard>
        <CardTitle>Terms of Service</CardTitle>
        {termsCondition && <Text>{termsCondition}</Text>}
      </HomeCard>
      <HomeCard>
        <CardTitle>Privacy Policy</CardTitle>
        {privacy && <Text>{privacy}</Text>}
      </HomeCard>
    </Stack>
  );
};

export default About;
