import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import TopBar from "../../../components/TopBar";
import TeacherSidebar from "../../../components/TeacherSidebar";

// screens
import TeacherDashboardHome from "./pages/home";
// import TeacherDashboardCalender from "./pages/calendar";
import TeacherDashboardJobs from "./pages/jobs";
import TeacherDashboardSettings from "./pages/settings";

// async action creator
import {
  fetchTeacherInfo,
  getTeacherHeaderInfo,
} from "../../../../stores/teacherRegisterationReducer";

import styles from "../../../school/pages/dashboard/dashboard.module.css";
import {
  getTeacherNotifications,
  markAllNotificationsReadTeacher,
} from "../../../../services/notificationService";
import { useQuery } from "react-query";
import Notifications from "./pages/Notifications/Notifications";

function TeacherDashboard() {
  const dispatch = useDispatch();
  const teacherHeaderInfo = useSelector(getTeacherHeaderInfo);

  useQuery(["notifications"], () => getTeacherNotifications(), {
    refetchOnWindowFocus: true,
    cacheTime: 100,
  });

  useEffect(() => {
    dispatch(fetchTeacherInfo());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div>
        <TeacherSidebar />
        <TopBar
          imageUrl={teacherHeaderInfo.profileURL}
          name={teacherHeaderInfo.fullName}
          fetchNotification={getTeacherNotifications}
          markNotificationRead={markAllNotificationsReadTeacher}
        />
      </div>
      <div className={styles.mainContainer}>
        <Routes>
          <Route path="/" element={<Navigate to="/teacher/dashboard/home" />} />
          <Route path="/home" element={<TeacherDashboardHome />} />
          {/* <Route path="/calender" element={<TeacherDashboardCalender />} /> */}
          <Route path="/jobs/*" element={<TeacherDashboardJobs />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/settings/*" element={<TeacherDashboardSettings />} />
        </Routes>
      </div>
    </div>
  );
}

export default TeacherDashboard;
