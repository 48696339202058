import React from "react";
import styles from "./multiSelect.module.css";

function CheckBoxMultiSelect({ field, option, otherProps }) {
  return (
    <div className={styles.checkBoxItem} key={option.key}>
      <label className={styles.label} htmlFor={option.value}>
        {option.key}
      </label>
      <input
        className={styles.checkbox}
        type="checkbox"
        id={option.value}
        {...otherProps}
        {...field}
        value={option.value}
        checked={field.value.includes(option.value)}
      />
    </div>
  );
}

export default CheckBoxMultiSelect;
