import React from "react";
import { useField } from "formik";

// components
import { FormErrorMessage } from ".";

import styles from "./input.module.css";

function FormInput({
  label,
  signal,
  showError = true,
  error,
  success,
  ...otherProps
}) {
  const [field, meta] = useField(otherProps);
  let className;

  if (meta.touched && meta.error) {
    className = "error";
  } else if (meta.touched && !meta.error) {
    className = "success";
  }

  return (
    <div className={styles.main}>
      <div className={`${styles.container} ${styles[className]}`}>
        <input
          type="text"
          className={styles.inputText}
          required
          {...otherProps}
          {...field}
        />
        <span className={styles.floatingLabel}>{label}</span>
        {/* {icon && icon} */}
      </div>
      {showError && <FormErrorMessage name={field.name} />}
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
    </div>
  );
}

export default FormInput;
