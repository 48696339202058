import React from "react";
import { Title } from "../../../../../../../../../components";
import styles from "./header.module.css";

function Header({ title, children }) {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.textContainer}>
        <Title>{title}</Title>
      </div>
      <div className={styles.buttonContainer}>{children}</div>
    </div>
  );
}

export default Header;
