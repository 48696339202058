import React from "react";
// components
import { CardTitle } from "../../../../../../../../components";
import styles from "./styles.module.css";

function CardWrapper({ title, children, ...otherProps }) {
  return (
    <div className={styles.cardWrapperContainer} {...otherProps}>
      {title && <CardTitle>{title}</CardTitle>}
      <div>{children}</div>
      {/* {children} */}
    </div>
  );
}

export default CardWrapper;
