import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

// components
import SchoolSideBar from "../../../components/SchoolSidebar";
import TopBar from "../../../components/TopBar";

// components pages
import SchoolDashboardHome from "./pages/home";
// import SchoolDashboardCalendar from "./pages/calendar";
import SchoolDashboardJob from "./pages/job";
import SchoolDashboardMyTeachers from "./pages/my-teachers";
import SchoolDashboardSettings from "./pages/settings";

import Notifications from "./pages/Notifications/Notifications";

// async action creator
import {
  fetchSchoolInfo,
  getSchoolHeaderInfo,
} from "../../../../stores/schoolRegisterationReducer";

import styles from "./dashboard.module.css";
import { useQuery } from "react-query";
import {
  getSchoolNotifications,
  markAllNotificationsReadSchool,
} from "../../../../services/notificationService";

function SchoolDashboard() {
  const dispatch = useDispatch();
  const schoolHeaderInfo = useSelector(getSchoolHeaderInfo);

  useQuery(["notifications"], () => getSchoolNotifications(), {
    refetchOnWindowFocus: true,
    cacheTime: 100,
  });

  useEffect(() => {
    dispatch(fetchSchoolInfo());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div>
        <SchoolSideBar />
        <TopBar
          imageUrl={schoolHeaderInfo.profileURL}
          name={schoolHeaderInfo.schoolName}
          fetchNotification={getSchoolNotifications}
          markNotificationRead={markAllNotificationsReadSchool}
        />
      </div>
      <div className={styles.mainContainer}>
        <Routes>
          <Route path="/" element={<Navigate to="/school/dashboard/home" />} />
          <Route path="/home" element={<SchoolDashboardHome />} />
          {/* <Route path="/calender" element={<SchoolDashboardCalendar />} /> */}
          <Route path="/job/*" element={<SchoolDashboardJob />} />
          <Route
            path="/my-teachers/*"
            element={<SchoolDashboardMyTeachers />}
          />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/settings/*" element={<SchoolDashboardSettings />} />
        </Routes>
      </div>
    </div>
  );
}

export default SchoolDashboard;
