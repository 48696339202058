import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";

// components
import Card from "./Card";
import { TextArea } from "../../components/job-form";
import {
  FormInput,
  FormButton,
  FormMultiSelect,
  ApplyJobMultiSelect,
} from "../../components/Forms";

// common validator
import {
  numberTypeValidation,
  whitespaceValidation,
} from "../validator/common";

// job form options
import { classYearOptions, expertiesOptions } from "./job-form-options";

import styles from "./form.module.css";

// teacher registeration validationSchema
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required().min(2).max(50).label("Full Name").trim(),
  email: Yup.string().required().min(5).max(100).email().label("Email").trim(),
  phoneNumber: Yup.string()
    .required()
    .min(10, "Contact Number must be at min of 10 digit")
    .max(10, "Contact Number must be at max of 10 digit")
    .label("Contact Number")
    .trim()
    .test(
      "type",
      "Contact number must not contain Whitespaces.",
      whitespaceValidation
    )
    .test("type", "Please type only number", numberTypeValidation),
  experienceClassYears: Yup.array()
    .required("Required")
    .min(1, "Please select Class Year.")
    .label("Class Year"),
  experienceExpertise: Yup.array()
    .required("Required")
    .min(1, "Please select Expertise.")
    .label("Expertise"),
  licenses: Yup.array().label("Licenses"),
  certificates:
    Yup.array()
    .label("Certificates"),
  notes: Yup.string().min(2).max(2000).label("Notes").trim(),
});

const initialValues = {
  fullName: "",
  email: "",
  phoneNumber: "",
  experienceClassYears: [],
  experienceExpertise: [],
  licenses: [],
  certificates: [],
  notes: "",
};

function ApplyJobForm({
  category,
  onSubmit,
  onCancel,
  licenseOptionList,
  certificateOptionList,
  initialValue = initialValues,
}) {
  return (
    <div className={styles.container} style={{ maxWidth: "100%" }}>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        className={styles.formContainer}
      >
        {({ setFieldValue }) => (
          <>
            <Card>
              <div className={styles.formChildContainer}>
                <h1 className={styles.formTitle}>Personal Details</h1>
                <FormInput
                  label="Enter your full name"
                  name="fullName"
                  type="text"
                />
                <div className={styles.gridContainer}>
                  <FormInput
                    label="Enter your email address"
                    name="email"
                    type="text"
                  />
                  <FormInput
                    label="Enter your contact number"
                    name="phoneNumber"
                    type="text"
                  />
                </div>
              </div>
            </Card>

            <Card>
              <div className={styles.formChildContainer}>
                <h1 className={styles.formTitle}>Experience Details</h1>
                <FormMultiSelect
                  label="Select your previous class years"
                  name="experienceClassYears"
                  options={classYearOptions[category]}
                />
                <FormMultiSelect
                  label="Select your expertise"
                  name="experienceExpertise"
                  options={expertiesOptions[category]}
                />
              </div>
            </Card>
            <Card>
              <div className={styles.formChildContainer}>
                <h1 className={styles.formTitle}>
                  Certificates & Licenses Details
                </h1>
                <ApplyJobMultiSelect
                  label="Select your licenses"
                  name="licenses"
                  options={licenseOptionList}
                  note="License are not found in your account, please add it from settings."
                />
                <ApplyJobMultiSelect
                  label="Select your certificates"
                  name="certificates"
                  options={certificateOptionList}
                  note="Certificate are not found in your account, please add it from settings."
                />
              </div>
            </Card>
            <Card>
              <div className={styles.formChildContainer}>
                <h1 className={styles.formTitle}>Additional Notes</h1>
                <TextArea
                  label="Type any additional notes here"
                  name="notes"
                  type="text"
                />
              </div>
            </Card>
            <div className={styles.gridContainer}>
              <FormButton
                buttonText="Cancel"
                type="button"
                submitButton={false}
                isBackground={false}
                onClick={onCancel}
              />
              <FormButton
                buttonText="Apply"
                type="submit"
                submitButton={true}
                isDisabled={true}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}

export default ApplyJobForm;
