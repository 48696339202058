import React from "react";
import styles from "./home.module.css";

function HomeCard({ children, className, ...otherProps }) {
  return (
    <div className={`${styles.homeCard} ${className}`} {...otherProps}>
      {children}
    </div>
  );
}

export default HomeCard;
