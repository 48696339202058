// validate given string only contain numbers from 0-9
const numberTypeValidation = (value) => {
  if (!value) return true;

  const isNumberAllow = /^[0-9]+$/;
  return isNumberAllow.test(value);
};

// validate given string should not contain white space
const whitespaceValidation = (value) => {
  if (!value) return true;

  const isNonWhiteSpace = /^\S*$/;
  return isNonWhiteSpace.test(value);
};

// validate password length and special characters
const passwordValidation = (value) => {
  if (!value) return true;

  // const isContainsSymbol = /^(?=.*[~`!@#$]).*$/;
  const isContainsSymbol =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
  return isContainsSymbol.test(value);
};

export { numberTypeValidation, whitespaceValidation, passwordValidation };
