import React from "react";
import moment from "moment";
import * as Yup from "yup";

// component
import Card from "./Card";
import {
  Form,
  FormInput,
  MFormInputTime,
  FormButton,
} from "../../components/Forms";

// common validator
import {
  whitespaceValidation,
  numberTypeValidation,
} from "../validator/common";

import styles from "./form.module.css";

const isAfter = (startTime, endTime) => {
  return moment(endTime).isAfter(moment(startTime));
};

// school registeration validationSchema
const validationSchema = Yup.object().shape({
  startBellTime: Yup.string("Please select time")
    .required()
    .label("Start Bell Time")
    .nullable(),
  firstBreakBell: Yup.string("Please select time")
    .required()
    .label("First Break Bell")
    .nullable(),
  secondBreakBell: Yup.string("Please select time")
    .required()
    .label("Second Break Bell")
    .nullable()
    .test(
      "first-bell-time",
      "Please select First Bell Time",
      function (secondBreakBell) {
        const { firstBreakBell } = this.parent;
        if (firstBreakBell) {
          return true;
        } else {
          return false;
        }
      }
    )
    .test(
      "second-bell-time",
      "Second time should be greater than first time",
      function (secondBreakBell) {
        const { firstBreakBell } = this.parent;
        if (secondBreakBell) {
          return isAfter(firstBreakBell, secondBreakBell);
        } else {
          return false;
        }
      }
    ),
  endBellTime: Yup.string("Please select time")
    .required()
    .label("End Bell Time")
    .nullable()
    .test(
      "start-bell-time",
      "Please select Start Bell Time",
      function (endBellTime) {
        const { startBellTime } = this.parent;
        if (startBellTime) {
          return true;
        } else {
          return false;
        }
      }
    )
    .test(
      "end-bell-time",
      "End time should be greater than start time",
      function (endBellTime) {
        const { startBellTime } = this.parent;
        if (endBellTime) {
          return isAfter(startBellTime, endBellTime);
        } else {
          return false;
        }
      }
    ),
  partnerName: Yup.string().min(2).max(50).label("Partner Name").trim(),
  partnerPhoneNumber: Yup.string()
    .test("type", "Please type only number", numberTypeValidation)
    .min(10, "Partner Contact Number be at min of 10 digit number")
    .max(10, "Partner Contact Number be at max of 10 digit number")
    .label("Partner Contact Number")
    .trim()
    .test(
      "type",
      "Partner Contact Number must not contain Whitespaces.",
      whitespaceValidation
    ),
});

function SchoolSignupFormSecond({ title, onSubmit, error, loading }) {
  return (
    <div className={styles.container}>
      <h1 className={styles.titleHeading}>{title}</h1>
      <div className={styles.barLine}>
        <div className={styles.fillLine} />
        <div className={styles.fillLine} />
      </div>
      <Form
        initialValues={{
          startBellTime: null,
          firstBreakBell: null,
          secondBreakBell: null,
          endBellTime: null,
          partnerName: "",
          partnerPhoneNumber: "",
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        className={styles.formContainer}
      >
        <Card>
          <div
            className={`${styles.formChildContainer}  ${styles.schoolChildContainer}`}
          >
            <h1 className={styles.formTitle}>School Details</h1>
            <MFormInputTime label="Start bell time" name="startBellTime" />
            <MFormInputTime label="First break bell" name="firstBreakBell" />
            <MFormInputTime label="Second break bell" name="secondBreakBell" />
            <MFormInputTime label="End bell time" name="endBellTime" />
          </div>
        </Card>
        <Card>
          <div className={styles.formChildContainer}>
            <h1 className={styles.formTitle}>Partnership Details</h1>
            <FormInput label="Enter name" name="partnerName" />
            <FormInput
              label="Enter partnership contact number"
              name="partnerPhoneNumber"
              type="text"
            />
            {/* {error && <p className="error">{error}</p>} */}
          </div>
        </Card>
        <FormButton
          buttonText="Continue"
          type="submit"
          submitButton={true}
          isDisabled={true}
          loading={loading}
        />
      </Form>
    </div>
  );
}

export default SchoolSignupFormSecond;
