import React, { useRef, useState } from "react";
import { useField } from "formik";

// components
import { CheckBoxSingleSelect, FormErrorMessage } from "../Forms";

// hooks
import useOnClickOutside from "../../hooks/useOnClickOutside";

// eye icons
import chevrondown from "../../assets/icon/chevron-down.png";

import styles from "../Forms/multiSelect.module.css";
import styles2 from "./formInput.module.css";

function Select(props) {
  const { label, name, options, showError = true, ...rest } = props;
  const ref = useRef(null);
  const [isOpen, toggleOpen] = useState(false);
  const [field, meta, helper] = useField(name);
  const { setValue, setTouched } = helper;

  useOnClickOutside(ref, () => {
    toggleOpen(false);
    // setTouched(true);
  });

  let className;
  if (meta.touched && meta.error) {
    className = "error";
  } else if (meta.touched && !meta.error) {
    className = "success";
  }

  const handleChange = (value) => {
    setValue(value);
    toggleOpen(false);
  };

  const isValue = field.value;

  return (
    <div className={styles2.InputfieldContainer} ref={ref}>
      <div
        className={`${styles2.selectBtn}  ${styles[className]}`}
        onClick={() => {
          toggleOpen(true);
          setTouched(true);
        }}
      >
        <div>
          {label && (
            <p className={`${styles2.label} ${isValue && styles2.hideLabel}`}>
              {label}
            </p>
          )}
          {field.value && <p className={styles.selectedValue}>{field.value}</p>}
        </div>
        <img
          src={chevrondown}
          className={styles2.icon}
          alt="chevrondown icon"
        />
      </div>

      <div
        className={`${styles.checkboxContainer} ${styles2.checkboxContainer} ${
          isOpen && styles.open
        }`}
      >
        {options.map((option) => (
          <CheckBoxSingleSelect
            key={option.key}
            field={field}
            option={option}
            setValue={handleChange}
            {...rest}
          />
        ))}
      </div>

      {showError && <FormErrorMessage name={name} />}
    </div>
  );
}

export default Select;
