import React from "react";

const titleStyles = { fontSize: "1.7rem", fontWeight: 500, linHeight: " 24px" };

function CardTitle({ children, style = {}, ...props }) {
  return (
    <h2 style={{ ...titleStyles, ...style }} {...props}>
      {children}
    </h2>
  );
}

export default CardTitle;
