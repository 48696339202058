import http from "./httpService";

const apiEndpoint = http.apiUrl + "verified-teacher";

// service is used to make a verify teacher by school
export function verifyTeacherBySchool(data) {
  return http.post(apiEndpoint + "/verfied", data);
}

// service is used to remove unverify teacher by school
export function unVerifiedTeacherBySchool(data) {
  return http.post(apiEndpoint + "/unverfied", data);
}
