import React from "react";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";

// cross icon
import crossIcon from "../../assets/icon/cross@2x.png";

// components
import Card from "./Card";
import {
  FormInput,
  FormInputDate,
  FormButton,
  FormSelect,
  SmallButton,
} from "../../components/Forms";

import styles from "./form.module.css";

const documentTypeList = [
  {
    key: "Certificate",
    value: "Certificate",
  },
  {
    key: "License",
    value: "License",
  },
];

// teacher registeration validationSchema
const validationSchema = Yup.object().shape({
  teacherdocuments: Yup.array()
    .of(
      Yup.object().shape({
        documentNumber: Yup.string()
          .min(2)
          .max(50)
          .label("Document number")
          .required("Document number is required"),
        documentTitle: Yup.string()
          .min(2)
          .max(100)
          .label("Document title")
          .required("Document title is required"),
        documentType: Yup.string()
          .min(2)
          .label("Document Type")
          .required("Document Type is required"),
        expiryDate: Yup.string().required().label("Expiry date"),
      })
    )
    .min(1, "Please select at least one tag")
    .required("Required")
    .label("Tag"),
  count: Yup.number(),
});

const initialValue = {
  documentNumber: "",
  documentTitle: "",
  documentType: "",
  expiryDate: "",
};

function TeacherSignupFormThird({ title, onSubmit, onSkip, error }) {
  return (
    <div className={styles.container}>
      <h1 className={styles.titleHeading}>{title}</h1>
      <div className={styles.barLine}>
        <div className={`${styles.fillLine} ${styles.teacherFillLine}`} />
        <div className={`${styles.fillLine} ${styles.teacherFillLine}`} />
        <div className={`${styles.fillLine} ${styles.teacherFillLine}`} />
      </div>
      <Formik
        initialValues={{
          count: 1,
          teacherdocuments: [initialValue],
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        className={styles.formContainer}
      >
        {({ values, setFieldValue }) => (
          <>
            <FieldArray
              name="teacherdocuments"
              render={(arrayHelpers) => (
                <>
                  <div className={styles.formChildContainer}>
                    {values.teacherdocuments.map((teacherdocument, index) => (
                      <Card>
                        <div className={styles.formChildContainer}>
                          <div className={styles.titleContainer}>
                            <h1 className={styles.formTitle}>
                              Certificates & Licenses Details
                            </h1>
                            {index !== 0 && (
                              <img
                                src={crossIcon}
                                className={styles.icon}
                                alt="Nav icons"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                  setFieldValue("count", values.count - 1);
                                }}
                              />
                            )}
                          </div>
                          <div
                            key={index}
                            className={styles.formChildContainer}
                          >
                            <div className={styles.gridContainer}>
                              <FormInput
                                label="Document Number"
                                name={`teacherdocuments[${index}].documentNumber`}
                                type="text"
                              />
                              <FormInput
                                label="Document Title"
                                name={`teacherdocuments[${index}].documentTitle`}
                                type="text"
                              />
                            </div>
                            <div className={styles.gridContainer}>
                              <FormSelect
                                label="Select Document"
                                name={`teacherdocuments[${index}].documentType`}
                                placeholder=""
                                options={documentTypeList}
                              />
                              <FormInputDate
                                label="Expiry Date"
                                name={`teacherdocuments[${index}].expiryDate`}
                                type="date"
                              />
                            </div>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                  <div className={styles.gridContainer}>
                    <div></div>
                    <SmallButton
                      buttonText="Add"
                      type="button"
                      disabled={values.count === 3}
                      onClick={() => {
                        arrayHelpers.push(initialValue);
                        setFieldValue("count", values.count + 1);
                      }}
                    />
                  </div>
                </>
              )}
            />
            <div className={styles.gridContainer}>
              <FormButton
                buttonText="Skip & Continue"
                type="button"
                isBackground={false}
                onClick={onSkip}
              />
              <FormButton
                buttonText="Continue"
                type="submit"
                submitButton={true}
                isDisabled={true}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}

export default TeacherSignupFormThird;
