import React from "react";

// components
import { Button } from "../../../../../../components";
import Header from "../../../../../components/Header";
import Listing from "./components/Listing";

// icon
import plusCircleIcon from "../../../../../../assets/icon/plus-circle-white.png";

function JobListing({ onCreateNew }) {
  return (
    <div style={{ paddingTop: "3rem" }}>
      <Header title="All Jobs Summary">
        <Button
          buttonText="Create New Job"
          icon={plusCircleIcon}
          onClick={onCreateNew}
        />
      </Header>
      <Listing />
    </div>
  );
}

export default JobListing;
