import React from "react";
// import ProfileImage from "../../assets/images/profile.png";

import Avatar from "@mui/material/Avatar";

// bell icon
// import BellIcon from "../../assets/icon/bell.png";

import styles from "./topBar.module.css";
// import styles2 from "./sideNav.module.css";

import http from "../../services/httpService";
import ShowNameAvatar from "./ShowNameAvatar";
import NotificationPopup from "./NotificationPopup";

function TopBar({
  imageUrl,
  name = "Something",
  fetchNotification,
  markNotificationRead,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.profileContainer}>
        <NotificationPopup
          fetchNotification={fetchNotification}
          markNotificationRead={markNotificationRead}
        />

        <ProfileImage imageUrl={imageUrl} name={name} />

        <p className={styles.title}>{name}</p>
      </div>
    </div>
  );
}

export default TopBar;

export const ProfileImage = ({ imageUrl, name }) => {
  if (imageUrl) {
    return (
      <Avatar
        src={http.imageBaseUrl + imageUrl}
        title={name}
        sx={{ width: 36, height: 36 }}
      />
    );
  }

  return <ShowNameAvatar name={name} />;
};
