import React from "react";
import { useNavigate } from "react-router-dom";

// image
import WelcomeImage from "../assets/images/t-login.png";

// components
import Layout from "../components/Layout";
import { ConfirmButton } from "../components/Forms";

import styles from "./components/form.module.css";

function Home() {
  const navigator = useNavigate();

  const navigateToSchool = () => {
    navigator("/school/login");
  };

  const navigateToTeacher = () => {
    navigator("/teacher/login");
  };
  return (
    <Layout imageUrl={WelcomeImage}>
      <div className={styles.container}>
        <div
          className={styles.formContainer}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/* <DotLoader /> */}
          <ConfirmButton
            buttonText="School Login"
            type="submit"
            submitButton={false}
            onClick={navigateToSchool}
          />
          <ConfirmButton
            buttonText="Teacher Login"
            isBackground={false}
            onClick={navigateToTeacher}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Home;
