import http from "./httpService";

const apiEndpoint = http.apiUrl + "job-application";

const constructionURL = (API_URL_ROUTE, { page, limit, jobId }) => {
  const url = new URL(apiEndpoint + API_URL_ROUTE);

  url.searchParams.set("page", page);
  url.searchParams.set("limit", limit);

  if (jobId) {
    url.searchParams.set("jobId", jobId);
  }

  return url;
};

// service is used to get all job applications with pagination
export function getAllJobApplicationWithPagination({
  page = 1,
  limit = 10,
  jobId,
}) {
  const url = constructionURL("/listing", { page, limit, jobId });
  return http.get(url);
}

// service is used to check if teacher have applied for job or not
export function checkTeacherAppliedToJob(data) {
  return http.post(apiEndpoint + "/check-applied", data);
}

// service is used to create job application
export function applyForJob(data) {
  return http.post(apiEndpoint + "/apply", data);
}

// service is used to withdraw job application by teacher
export function sendWithdrawJobApplicant(data) {
  return http.put(apiEndpoint + "/withdraw", data);
}

// service is used to send shift request to job applicant
export function sendRequestToJobApplicant(data) {
  return http.put(apiEndpoint + "/send-request", data);
}

// service is used to reject job application of teacher
export function sendRejectJobApplicant(data) {
  return http.put(apiEndpoint + "/reject", data);
}
