import React, { useState, useEffect, useCallback } from "react";

// components
import { DotLoader, NoRecordFound } from "../../../../../../../../components";
import { Pagination } from "../../../../../../../components";
import JobHeaderFilter from "../../../../../../../school/pages/dashboard/pages/job/components/JobHeaderFilter";
import AllJobHeader from "./AllJobHeader";
import AllJobItem from "./AllJobItem";

import styles from "./allJobListing.module.css";

import * as toast from "../../../../../../../../Toast";

const defaultFilter = {
  jobType: "",
  status: "",
  category: "",
};

function TeacherJobListing({ apiFunc, onCellClick, statusOptions }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);

  const [filter, setFilter] = useState(defaultFilter);

  const page = currentPage;

  const setPage = (page) => {
    setCurrentPage(page);
  };

  const setPageToStart = () => {
    setCurrentPage(1);
  };

  const fetchData = useCallback(async ({ page, filter }) => {
    try {
      setLoading(true);
      const response = await apiFunc({
        page: page,
        limit: 5,
        filter,
      });
      const { data } = await response.data;
      setData(data.result);
      setTotalCount(data.total);
    } catch (ex) {
      toast.error(ex.response.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData({ page: page, filter });
  }, [fetchData, page, filter]);

  if (loading) {
    return <DotLoader />;
  }

  let listings;
  if (data && data.length > 0) {
    listings = (
      <tbody>
        {data.map((job, index) => (
          <AllJobItem job={job} key={index + 1} onCellClick={onCellClick} />
        ))}
      </tbody>
    );
  } else {
    listings = (
      <tbody>
        <tr>
          <td>
            <NoRecordFound />
          </td>
        </tr>
      </tbody>
    );
  }

  const handleJobTypeChange = (jobType) => {
    setFilter((prev) => {
      return { ...prev, jobType };
    });
    setPageToStart();
  };

  const handleStatusChange = (status) => {
    setFilter((prev) => {
      return { ...prev, status };
    });
    setPageToStart();
  };

  const handleCategoryChange = (category) => {
    setFilter((prev) => {
      return { ...prev, category };
    });
    setPageToStart();
  };

  const handleResetFilter = () => {
    setFilter(defaultFilter);
    setPageToStart();
  };

  return (
    <div>
      <JobHeaderFilter
        filter={filter}
        onChangeJobCategory={handleCategoryChange}
        onChangeJobStatus={handleStatusChange}
        onChangeJobType={handleJobTypeChange}
        onReset={handleResetFilter}
        statusOptions={statusOptions}
      />
      <table className={styles.listingContainer}>
        <thead className={styles.headerContainer}>
          <AllJobHeader />
        </thead>
        {listings && listings}
      </table>
      <Pagination
        currentPage={page}
        totalCount={totalCount}
        pageSize={5}
        onPageChange={setPage}
      />
    </div>
  );
}

export default TeacherJobListing;
