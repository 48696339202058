import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";

// bell icon
import BellIcon from "../../assets/icon/bell.png";
import { useQuery } from "react-query";

import { Loader, NotificationCard } from "../../components";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const NotificationPopupContainer = styled("div")`
  position: relative;
`;

const NotificationDropdown = styled("div")`
  position: absolute;
  top: 35px;
  background: #ffffff;
  border-radius: 12px;
  left: -320px;
  border: 1px solid #eee9e8;
  width: 361px;
`;

const NotificationButton = styled("button")`
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const NotificationDot = styled("span")`
  position: absolute;
  top: -5px;
  right: 0px;
  width: 10px;
  height: 10px;
  background: #de5f35;
  border-radius: 50%;
`;

const NotificationPopup = ({ fetchNotification, markNotificationRead }) => {
  const NotificationButtonRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const ToggleDropdown = () => setShowDropdown(!showDropdown);
  const CloseDropdown = () => setShowDropdown(false);

  useOnClickOutside(NotificationButtonRef, CloseDropdown);

  const { isError, isLoading, data, refetch } = useQuery({
    queryKey: [
      "notifications",
      {
        page: 1,
        limit: 5,
      },
    ],
    queryFn: fetchNotification,
    refetchOnWindowFocus: true,
    cacheTime: 100,
  });

  //   console.log(data.data.data);

  const notifications = data?.data?.data?.result;
  const total = data?.data?.data?.total;
  const unread = data?.data?.data?.unread;

  const handleDropdownOpen = async () => {
    if (unread > 0) {
      await markNotificationRead();
      refetch();
    }
    ToggleDropdown();
  };

  return (
    <NotificationPopupContainer ref={NotificationButtonRef}>
      <NotificationButton onClick={handleDropdownOpen}>
        <img src={BellIcon} alt="Nav icons" />
        {isLoading ? null : isError ? null : unread > 0 ? (
          <NotificationDot />
        ) : null}
      </NotificationButton>

      {showDropdown && (
        <NotificationDropdown>
          {isLoading ? (
            <Loader />
          ) : isError ? (
            <p
              style={{
                padding: "20px",
              }}
            >
              Some Error Occurred
            </p>
          ) : total === 0 ? (
            <p
              style={{
                padding: "20px",
              }}
            >
              No Notification to Show
            </p>
          ) : (
            notifications.map((notification) => (
              <NotificationCard
                key={notification._id}
                notification={notification}
              />
            ))
          )}
        </NotificationDropdown>
      )}
    </NotificationPopupContainer>
  );
};

export default NotificationPopup;
