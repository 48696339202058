import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// components
import { DotLoader, NoRecordFound } from "../../../../../../../../components";
import ApplicantItem from "./ApplicantItem";
import ApplicantListingHeader from "./ApplicantListingHeader";
import { ConfirmationModal, Pagination } from "../../../../../../../components";

// hooks
import useVisible from "../../../../../../../../hooks/useVisible";
import useResponse from "../../../../../../../../hooks/useResponse";

// services
import {
  getAllJobApplicationWithPagination,
  sendRequestToJobApplicant,
  sendRejectJobApplicant,
} from "../../../../../../../../services/jobApplicationService";

import * as toast from "../../../../../../../../Toast";

import styles from "../jobListing.module.css";

function ApplicantList({ jobId, refechJob }) {
  const navigate = useNavigate();
  const [showSendShiftModal, toggleSendShiftModal] = useVisible();
  const [showSendRejectModal, toggleSendRejectModal] = useVisible();
  const [showPaymentFailedModal, togglePaymentFailedModal] = useVisible();
  const [sendRequestState, sendRequestAction] = useResponse();
  const [sendRejectState, sendRejectAction] = useResponse();
  const [loading, setLoading] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  // const [applicantId, setApplicantId] = useState(null);
  const [applicantInfo, setApplicantInfo] = useState({
    applicantId: null,
    teacherId: null,
  });

  const page = currentPage;

  const fetchData = useCallback(async ({ page, jobId }) => {
    try {
      setLoading(true);
      const response = await getAllJobApplicationWithPagination({
        page: page,
        limit: 5,
        jobId,
      });
      const { data } = await response.data;
      setData(data.result);
      setTotalCount(data.total);
    } catch (ex) {
      toast.error(ex.response.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData({ page: page, jobId });
  }, [fetchData, page, jobId]);

  useEffect(() => {
    if (refreshState) fetchData({ page: page, jobId });
  }, [refreshState, fetchData, page, jobId]);

  if (loading) {
    return <DotLoader />;
  }

  const handleCellClick = (teacherId) => {
    navigate(
      `/school/dashboard/job/details/${jobId}/applicant-list/${teacherId}`
    );
  };

  const refreshPage = () => {
    // set refresh state to false,
    setRefreshState(true);
    setInterval(() => {
      setRefreshState(false);
    }, 4000);
  };

  const handleSendShiftRequestConfirm = async () => {
    sendRequestAction.sendingRequst();
    try {
      const response = await sendRequestToJobApplicant({
        ...applicantInfo,
        jobId,
      });

      const { message, data } = await response.data;
      console.log("Send shift request : ", data);
      if (data.paymentFailed) {
        toggleSendShiftModal();

        // open payment failed modal
        togglePaymentFailedModal();
      } else if (data.isAlreadyBooked) {
        toast.info(message);

        // toggle send shift request modal
        toggleSendShiftModal();
      } else {
        await refechJob();
        toast.success(message);

        // refresh applicant list
        refreshPage();

        // toggle send shift request modal
        toggleSendShiftModal();
      }
      sendRequestAction.successResponse(message);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        toast.error(error.response.data.message);
        sendRequestAction.gotError(error.response.data.message);
      }
    }
  };

  const handleSendShiftRequest = ({ applicantId, teacherId }) => {
    // setApplicantId(applicantId);
    setApplicantInfo({ applicantId, teacherId });
    toggleSendShiftModal();
  };

  const handleSendRejectRequest = ({ applicantId, teacherId }) => {
    // setApplicantId(applicantId);
    setApplicantInfo({ applicantId, teacherId });
    toggleSendRejectModal();
  };

  const handleSendRejectRequestConfirm = async () => {
    sendRejectAction.sendingRequst();
    try {
      const response = await sendRejectJobApplicant({
        ...applicantInfo,
        jobId,
      });

      const { message } = await response.data;

      sendRejectAction.successResponse(message);
      toast.success(message);

      // toggle send reject request modal
      toggleSendRejectModal();

      // refresh applicant list
      refreshPage();
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        toast.error(error.response.data.message);
        sendRejectAction.gotError(error.response.data.message);
      }
    }
  };

  let listings;
  if (data && data.length > 0) {
    listings = (
      <tbody>
        {data.map((jobApplicant, index) => (
          <ApplicantItem
            jobApplicant={jobApplicant}
            key={index + 1}
            onCellClick={handleCellClick}
            onSendRequest={handleSendShiftRequest}
            onSendReject={handleSendRejectRequest}
          />
        ))}
      </tbody>
    );
  } else {
    listings = (
      <tbody>
        <tr>
          <td>
            <NoRecordFound />
          </td>
        </tr>
      </tbody>
    );
  }

  const jobApplicant = data.filter(
    (jobApplicant) => jobApplicant.id === applicantInfo.applicantId
  );

  const subtitle = (
    <>
      Teacher's added payment method is not active/valid.
      <br />
      So you can't send shift request to this teacher.
    </>
  );

  return (
    <>
      <ConfirmationModal
        subtitle={subtitle}
        ConfirmButtonText="Okay"
        showModal={showPaymentFailedModal}
        onConfirm={togglePaymentFailedModal}
        toggleModal={togglePaymentFailedModal}
        hideCancel
      />
      <ConfirmationModal
        subtitle={`Are you sure you want to assign this job to teacher '${jobApplicant[0]?.fullName}'?`}
        ConfirmButtonText="Yes"
        CancelButtonText="No"
        showModal={showSendShiftModal}
        onConfirm={handleSendShiftRequestConfirm}
        toggleModal={toggleSendShiftModal}
        loading={sendRequestState.loading}
      />

      <ConfirmationModal
        subtitle={`Are you sure you want to reject '${jobApplicant[0]?.fullName}' from this job?`}
        ConfirmButtonText="Yes"
        CancelButtonText="No"
        showModal={showSendRejectModal}
        onConfirm={handleSendRejectRequestConfirm}
        toggleModal={toggleSendRejectModal}
        loading={sendRejectState.loading}
      />

      <div>
        <h2>Applicants List</h2>
        <table className={styles.listingContainer}>
          <thead className={styles.headerContainer}>
            <ApplicantListingHeader />
          </thead>
          {listings && listings}
        </table>

        <Pagination
          currentPage={page}
          totalCount={totalCount}
          pageSize={5}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
}

export default ApplicantList;
