import React from "react";
import { useField } from "formik";

// components
import { FormErrorMessage } from "../Forms";

import styles from "./formInput.module.css";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function Input({ name, label, type = "text" }) {
  const [field, meta] = useField(name);
  let className;

  if (meta.touched && meta.error) {
    className = "error";
  } else if (meta.touched && !meta.error) {
    className = "success";
  }

  let inputValue =
    type === "text" &&
    typeof field.value === "string" &&
    field.value.length >= 1
      ? capitalizeFirstLetter(field.value)
      : field.value;

  const handleChange = (event) => {
    let textData = event.target.value;

    if (
      type === "text" &&
      typeof textData === "string" &&
      textData.length >= 1
    ) {
      textData = capitalizeFirstLetter(textData);
      event.target.value = textData;
    }

    field.onChange(event);
  };

  return (
    <div className={styles.InputfieldContainer}>
      <input
        type="text"
        className={`${styles.inputText} ${styles[className]}`}
        placeholder={label}
        required
        {...field}
        value={inputValue}
        onChange={handleChange}
      />
      <FormErrorMessage name={field.name} />
    </div>
  );
}

export default Input;
