import React, { useRef, useState } from "react";
import { useField } from "formik";

// components
import { CheckBoxMultiSelect } from ".";
import { FormErrorMessage } from ".";
// hooks
import useOnClickOutside from "../../hooks/useOnClickOutside";

// eye icons
import chevrondown from "../../assets/icon/chevron-down.png";

import styles from "./multiSelect.module.css";
import styles2 from "./input.module.css";

function FormMultiSelect(props) {
  const { label, name, options = [], showError = true, note, ...rest } = props;
  const [isOpen, toggleOpen] = useState();
  const ref = useRef(null);
  const [field, meta, helper] = useField(name);
  const { setTouched } = helper;

  useOnClickOutside(ref, () => {
    toggleOpen(false);
  });

  let className;
  if (meta.touched && meta.error) {
    className = "error";
  } else if (meta.touched && !meta.error) {
    className = "success";
  }

  const lastIndex = field.value.length;
  const optionLength = options.length;

  return (
    <div className={styles.container} ref={ref}>
      <div
        className={`${styles.selectBtn}  ${styles[className]}`}
        onClick={() => {
          if (isOpen) toggleOpen(false);
          else toggleOpen(true);

          setTouched(true);
        }}
      >
        <div>
          {label && (
            <span
              className={`${styles.btnText} ${
                lastIndex && styles.btnTextSmall
              }`}
            >
              {label}
            </span>
          )}
          {field.value.length > 0 && (
            <p className={styles.selectedValue}>
              {field.value.map((value, index) => (
                <>
                  {index ? ", " : ""} {value}
                </>
              ))}
            </p>
          )}
        </div>
        <img
          src={chevrondown}
          className={styles2.icon}
          alt="chevrondown icon"
        />
      </div>

      <div className={`${styles.checkboxContainer} ${isOpen && styles.open}`}>
        {optionLength ? (
          <>
            {options.map((option, index) => (
              <CheckBoxMultiSelect
                key={index + 1}
                field={field}
                option={option}
                {...rest}
              />
            ))}
          </>
        ) : (
          <p className={styles.selectedValue} style={{ padding: "2rem 0px" }}>
            {note}
          </p>
        )}
      </div>

      {showError && <FormErrorMessage name={field.name} />}
    </div>
  );
}

export default FormMultiSelect;
