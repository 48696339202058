import React from "react";
import { styled } from "@mui/material/styles";

const CustomButton = styled("button")`
  outline: none;
  width: 100%;
  padding: 0.8rem 1.6rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 20px;
  letter-spacing: 0.5px;
  border-radius: var(--border-radius);
  font-family: inherit;

  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => {
    if (props.primary) {
      return "var(--primary-full)";
    }

    if (props.transparent) {
      return "transparent";
    }

    return "var(--primary-full)";
  }};

  color: ${(props) => {
    if (props.primary) {
      return "#fff";
    }

    if (props.transparent) {
      return "var(--primary-full)";
    }

    return "#fff";
  }};

  border: ${(props) => {
    if (props.primary) {
      return "none";
    }

    if (props.transparent) {
      return "none";
    }

    if (props.secondary) {
      return "2px solid var(--primary-full)";
    }

    return "none";
  }};

  opacity: 1;

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Button = ({ children, ...otherProps }) => {
  return <CustomButton {...otherProps}>{children}</CustomButton>;
};

export const AddIconStyles = styled("img")`
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1.5rem;
`;

export const AddButton = styled(Button)`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 24px;
  color: var(--primary-full);
  background-color: ${(props) => (props.white ? "#fff" : "inherit")};
`;

function IconButton({ icon, children, ...otherProps }) {
  return (
    <AddButton transparent type="button" {...otherProps}>
      <AddIconStyles src={icon} alt="plus" />
      {children}
    </AddButton>
  );
}

export default IconButton;
