import { styled } from "@mui/material/styles";
import { Avatar } from "@mui/material";

export const Image = styled(Avatar)`
  height: 10rem;
  width: 10rem;
  border-radius: var(--border-radius);
`;

export const DescriptionText = styled("p")`
  color: var(--text-secondary);
  line-height: 20px;
  letter-spacing: 0.25px;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
`;

export const LocationText = styled(DescriptionText)`
  font-size: 1.5rem;
  font-weight: 300;
`;

export const DateText = styled(DescriptionText)`
  font-size: 1rem;
  font-weight: 300;
  color: var(--text-tertiary);
  text-transform: uppercase;
`;
