import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// component
import AllJobListing from "../all/AllJobListing";
import JobDetailPage from "../JobDetailPage";

// services
import { getAllAppliedJobWithPagination } from "../../../../../../../services/teacherJobService";

// options
import { teacherAppliedJobStatusOptions } from "../../../../../../components/job-form-options";

function getNavLinkList(jobId) {
  const navLinkList = [
    {
      to: "/teacher/dashboard/jobs",
      tabName: "Jobs",
    },
    {
      to: "/teacher/dashboard/jobs/applied-jobs",
      tabName: "Applied Jobs",
    },
    {
      to: `/teacher/dashboard/jobs/detail/${jobId}`,
      tabName: "Job Details",
    },
  ];

  return navLinkList;
}

function AppliedJobs() {
  const navigate = useNavigate();
  const [jobId, setJobId] = useState("");

  const handleCellClick = (jobId) => {
    setJobId(jobId);
    navigate(`/teacher/dashboard/jobs/applied-jobs/detail/${jobId}`);
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AllJobListing
            apiFunc={getAllAppliedJobWithPagination}
            onCellClick={handleCellClick}
            statusOptions={teacherAppliedJobStatusOptions}
          />
        }
      />
      <Route
        path="/detail/:id"
        element={
          <JobDetailPage
            navList={getNavLinkList(jobId)}
            activeTabName="active"
            activeTabTitle="Active Clients"
            setJobId={setJobId}
          />
        }
      />
    </Routes>
  );
}

export default AppliedJobs;
