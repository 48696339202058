import React from "react";
import styles from "./formButton.module.css";

function Button({
  buttonText,
  onClick,
  isBackground = true,
  borderRadius = 8,
  disabled = false,
  ...otherProps
}) {
  return (
    <button
      className={`${styles.button} ${
        isBackground ? styles.withBackground : styles.noBackground
      }`}
      onClick={onClick}
      style={{
        borderRadius: `${borderRadius}px`,
        // height: "4rem",
      }}
      disabled={disabled}
      {...otherProps}
    >
      {buttonText}
    </button>
  );
}

export default Button;
