import http from "./httpService";

const apiEndpoint = http.apiUrl + "school-teacher";

const constructionURL = (API_URL_ROUTE, { page, limit, filter = {} }) => {
  const url = new URL(apiEndpoint + API_URL_ROUTE);

  url.searchParams.set("page", page);
  url.searchParams.set("limit", limit);

  if (filter.availability) {
    url.searchParams.set("availability", filter.availability);
  }

  return url;
};

// service is used to get all school teacher with pagination
export function getAllSchoolTeacherWithPagination({
  page = 1,
  limit = 10,
  filter = {},
}) {
  const url = constructionURL("/listing", { page, limit, filter });
  return http.get(url);
}

// service is used to get all school favorite teacher with pagination
export function getAllSchoolFavoriteTeacherWithPagination({
  page = 1,
  limit = 10,
  filter = {},
}) {
  const url = constructionURL("/favorite-listing", { page, limit, filter });
  return http.get(url);
}

// service is used to make a school teacher favourite
export function makeTeacherFavorite(data) {
  return http.post(apiEndpoint + "/favourite", data);
}

// service is used to remove favourite teacher from favourite teacher listing
export function removeFavoriteTeacher(data) {
  return http.post(apiEndpoint + "/unfavourite", data);
}
