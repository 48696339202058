import React from "react";

// components
import { CardTitle } from "../../../../../../../components";
import { HomeCard } from "../../../../../../components/dashboard";
import { ContentItem, ContentWrapper } from "./ScheduleDetails";

function ExpertiesAndExperience({ detail }) {
  const { classYear, position, expertise, description } = detail;

  return (
    <HomeCard>
      <CardTitle>Experience & Expertise Details</CardTitle>
      <ContentWrapper>
        <ContentItem title="Class Year">{classYear}</ContentItem>
        <ContentItem title="Position to Cover">
          {position}, {expertise}
        </ContentItem>
        <ContentItem title="Description">{description}</ContentItem>
      </ContentWrapper>
    </HomeCard>
  );
}

export default ExpertiesAndExperience;
