import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// pages component
import JobListing from "./JobListingPage";
import NewJob from "./NewJob";
import JobDetails from "./JobDetails";
import ApplicantProfile from "./ApplicantProfile/ApplicantProfile";

function SchoolDashboardJob() {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <JobListing
            onCreateNew={() => {
              navigate(`/school/dashboard/job/new-job`);
            }}
          />
        }
      />

      <Route path="/new-job" element={<NewJob />} />
      <Route path="/details/:id" element={<JobDetails />} />
      <Route
        path="/details/:jobId/applicant-list/:id"
        element={<ApplicantProfile />}
      />

      {/* <Route
          path="/:id/"
          element={
            <RecipeDetails
              activeTabName="active"
              activeTabTitle="Active Clients"
            />
          }
        /> */}
    </Routes>
  );
}

export default SchoolDashboardJob;
