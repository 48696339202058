import React from "react";
import Avatar from "@mui/material/Avatar";
import http from "../../../../../../../../../services/httpService";

// const Image = styled("img")`
//   height: 22rem;
//   width: 22rem;
//   border-radius: 50%;
// `;

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: "22rem",
      width: "22rem",
      borderRadius: "50%",
      fontSize: 15,
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

function ShowNameAvatar({ name }) {
  return <Avatar {...stringAvatar(name)} />;
}

export const ProfileImage = ({ imageUrl, name }) => {
  if (imageUrl) {
    return (
      <Avatar
        src={http.imageBaseUrl + imageUrl}
        title={name}
        sx={{ height: "22rem", width: "22rem", borderRadius: "50%" }}
      />
    );
  }

  return <ShowNameAvatar name={name} />;
};

export default ProfileImage;
