import React from "react";
import { useField } from "formik";

// components
import { FormErrorMessage } from ".";

import styles from "./input.module.css";
import styles2 from "./formDate.module.css";

function getDate(date = new Date()) {
  var today = new Date(date);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  return today;
}

function FormInputDate({
  label,
  signal,
  showError = true,
  error,
  success,
  ...otherProps
}) {
  const [field, meta] = useField(otherProps);
  let className;

  if (meta.touched && meta.error) {
    className = "error";
  } else if (meta.touched && !meta.error) {
    className = "success";
  }

  return (
    <div className={styles.main}>
      <div className={`${styles.container} ${styles[className]}`}>
        <input
          type="date"
          className={styles.inputText}
          required
          {...otherProps}
          {...field}
          // onChange={(event) => console.log(event)}
          min={getDate()}
        />
        <span className={styles2.floatingLabel}>{label}</span>
        {/* {icon && icon} */}
      </div>
      {showError && <FormErrorMessage name={field.name} />}
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
    </div>
  );
}

export default FormInputDate;
