import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";

// components
import Card from "./Card";
import {
  FormInput,
  FormButton,
  FormMultiSelect,
  FormSelect,
  FormLocationInput,
} from "../../components/Forms";

// job form options
import {
  categoryOptions,
  classYearOptions,
  expertiesOptions,
} from "./job-form-options";

import styles from "./form.module.css";
import { availabilityList, radiusList } from "../../constants/teacher";

// teacher registeration validationSchema
const validationSchema = Yup.object().shape({
  teacherRegNumber: Yup.string()
    .required()
    .min(2)
    .max(50)
    .label("Teacher Registration Number")
    .trim(),
  availability: Yup.array()
    .required("Required")
    .min(1, "Please select availability.")
    .label("Availability"),

  category: Yup.string().required().label("Category"),
  classPreference: Yup.array()
    .required("Required")
    .min(1, "Please select class preference.")
    .label("Class Year Preference"),

  expertise: Yup.array()
    .required("Required")
    .min(1, "Please select expertise.")
    .label("Expertise"),
  bio: Yup.string().required().min(2).max(2000).label("Bio").trim(),
  locationAddress: Yup.string()
    .required()
    .min(6)
    .label("Location Address")
    .trim(),
  latitude: Yup.number().required().label("Latitude"),
  longitude: Yup.number().required().label("Longitude"),
  radius: Yup.string().required().min(2).label("Radius"),
});

// const classPreferenceList = [
//   { key: "Kindergarten", value: "Kindergarten" },
//   { key: "Pre-School", value: "Pre-School" },
//   {
//     key: "Year I",
//     value: "Year I",
//   },
//   {
//     key: "Year II",
//     value: "Year II",
//   },
//   {
//     key: "Year III",
//     value: "Year III",
//   },
//   {
//     key: "Year IV",
//     value: "Year IV",
//   },
//   {
//     key: "Year V",
//     value: "Year V",
//   },
// ];

// const expertiseList = [
//   { key: "Science", value: "Science" },
//   { key: "Mathematics", value: "Mathematics" },
//   {
//     key: "English",
//     value: "English",
//   },
//   {
//     key: "Sports",
//     value: "Sports",
//   },
// ];

function TeacherSignupFormSecond({ title, onSubmit }) {
  const [category, setCategory] = useState("Early Learning");

  return (
    <div className={styles.container}>
      <h1 className={styles.titleHeading}>{title}</h1>
      <div className={styles.barLine}>
        <div className={`${styles.fillLine} ${styles.teacherFillLine}`} />
        <div className={`${styles.fillLine} ${styles.teacherFillLine}`} />
      </div>
      <Formik
        initialValues={{
          teacherRegNumber: "",
          availability: [],
          category: "Early Learning",
          classPreference: [],
          expertise: [],
          bio: "",
          locationAddress: "",
          latitude: 0,
          longitude: 0,
          radius: "",
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        className={styles.formContainer}
      >
        {({ setFieldValue, values }) => (
          <>
            <Card>
              <div className={styles.formChildContainer}>
                <h1 className={styles.formTitle}>Job Details</h1>
                <FormInput
                  label="Enter your teacher registration number"
                  name="teacherRegNumber"
                  type="text"
                />
                <FormMultiSelect
                  label="Select your availability"
                  name="availability"
                  options={availabilityList}
                />
                <FormSelect
                  label="Select your experience category preference"
                  name="category"
                  options={categoryOptions}
                  onChange={(value) => {
                    if (value !== category) {
                      setCategory(value);
                      setFieldValue("classPreference", []);
                      setFieldValue("expertise", []);
                    }
                  }}
                />
                <FormMultiSelect
                  label="Select your class year preference"
                  name="classPreference"
                  options={classYearOptions[values.category]}
                />
                <FormMultiSelect
                  label="Select your expertise"
                  name="expertise"
                  options={expertiesOptions[values.category]}
                />
                <FormInput
                  label="Type a bio about your experience"
                  name="bio"
                  type="textarea"
                />
              </div>
            </Card>
            <Card>
              <div className={styles.formChildContainer}>
                <h1 className={styles.formTitle}>Location Details</h1>
                <FormLocationInput
                  label="Current location"
                  name="locationAddress"
                  type="text"
                  placeholder=""
                  onCordinateChange={({ lat, lng }) => {
                    setFieldValue("latitude", lat);
                    setFieldValue("longitude", lng);
                  }}
                />
                <FormSelect
                  label="Location radius"
                  name="radius"
                  options={radiusList}
                />
              </div>
            </Card>
            <FormButton
              buttonText="Continue"
              type="submit"
              submitButton={true}
              isDisabled={true}
            />
          </>
        )}
      </Formik>
    </div>
  );
}

export default TeacherSignupFormSecond;
