import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  FormImageInput,
  FormInput,
  MFormInputTime,
} from "../../../../../../../../components/Forms";
import { HomeCard } from "../../../../../../../components/dashboard";
import { CardTitle, Loader } from "../../../../../../../../components";
import { imageUploadAPI } from "../../../../../../../../services/uploadService";
import generateImageUrl from "../../../../../../../../utils/generateImageUrl";
import Button from "../../components/Button";

import { ProfilePhoto } from "./EditProfile.styles";
import { editSchoolProfileUpdate } from "../../../../../../../../stores/schoolRegisterationReducer";
import {
  numberTypeValidation,
  whitespaceValidation,
} from "../../../../../../../validator/common";
import { TextArea } from "../../../../../../../../components/job-form";

const isAfter = (startTime, endTime) => {
  return moment(endTime).isAfter(moment(startTime));
};

const validationSchema = Yup.object().shape({
  schoolName: Yup.string().required().min(1).label("School Name").trim(),
  schoolEmail: Yup.string()
    .required()
    .min(6)
    .email()
    .label("School Email")
    .trim(),
  profileURL: Yup.string().label("Profile Photo").trim(),
  schoolPhoneNumber: Yup.string()
    .required()
    .test("type", "Please type only number", numberTypeValidation)
    .min(10, "Contact Number must be at min of 10 digit number")
    .max(10, "Contact Number must be at max of 10 digit number")
    .label("School Phone Number")
    .trim(),

  startBellTime: Yup.string("Please select time")
    .required()
    .label("Start Bell Time")
    .nullable(),
  firstBreakBell: Yup.string("Please select time")
    .required()
    .label("First Break Bell")
    .nullable(),
  secondBreakBell: Yup.string("Please select time")
    .required()
    .label("Second Break Bell")
    .nullable()
    .test(
      "first-bell-time",
      "Please select First Bell Time",
      function (secondBreakBell) {
        const { firstBreakBell } = this.parent;
        if (firstBreakBell) {
          return true;
        } else {
          return false;
        }
      }
    )
    .test(
      "second-bell-time",
      "Second time should be greater than first time",
      function (secondBreakBell) {
        const { firstBreakBell } = this.parent;
        if (secondBreakBell) {
          return isAfter(firstBreakBell, secondBreakBell);
        } else {
          return false;
        }
      }
    ),
  endBellTime: Yup.string("Please select time")
    .required()
    .label("End Bell Time")
    .nullable()
    .test(
      "start-bell-time",
      "Please select Start Bell Time",
      function (endBellTime) {
        const { startBellTime } = this.parent;
        if (startBellTime) {
          return true;
        } else {
          return false;
        }
      }
    )
    .test(
      "end-bell-time",
      "End time should be greater than start time",
      function (endBellTime) {
        const { startBellTime } = this.parent;
        if (endBellTime) {
          return isAfter(startBellTime, endBellTime);
        } else {
          return false;
        }
      }
    ),
  partnerName: Yup.string().min(2).max(50).label("Partner Name").trim(),
  partnerPhoneNumber: Yup.string()
    .test("type", "Please type only number", numberTypeValidation)
    .min(10, "Partner Contact Number be at min of 10 digit number")
    .max(10, "Partner Contact Number be at max of 10 digit number")
    .label("Partner Contact Number")
    .trim()
    .test(
      "type",
      "Partner Contact Number must not contain Whitespaces.",
      whitespaceValidation
    ),
  schoolDescription: Yup.string()
    .min(2)
    .max(2000)
    .label("School Description")
    .trim(),
});

const EditProfile = () => {
  const { loading, school, error } = useSelector(
    (state) => state.entities.schoolRegisteration
  );

  // console.log(school);

  const dispatch = useDispatch();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>Some Error Occurred</p>;
  }

  const onSubmit = async (data, options) => {
    if (data.selectedProfilePhoto) {
      const profilePhoto = await imageUploadAPI(data.selectedProfilePhoto);
      data.profileURL = profilePhoto.data.file.filename;
    }

    delete data.schoolEmail;
    delete data.selectedProfilePhoto;
    delete data.checkResolution;

    // resetForm();
    dispatch(editSchoolProfileUpdate(data));

    options.resetForm();
  };

  const initialValues = {
    schoolName: school.schoolName,
    schoolEmail: school.schoolEmail,
    schoolPhoneNumber: school.schoolPhoneNumber,
    profileURL: school.profileURL,
    selectedProfilePhoto: "",
    startBellTime: school.startBellTime,
    firstBreakBell: school.firstBreakBell,
    secondBreakBell: school.secondBreakBell,
    endBellTime: school.endBellTime,
    partnerName: school.partnerName,
    partnerPhoneNumber: school.partnerPhoneNumber,
    schoolDescription: school.schoolDescription,
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        setFieldValue,
        values,
        setFieldTouched,
        setFieldError,
        handleSubmit,
      }) => (
        <Stack spacing={2}>
          <HomeCard>
            <CardTitle
              style={{
                marginBottom: "2rem",
              }}
            >
              Profile Photo
            </CardTitle>
            <Stack direction="row" spacing={4}>
              {values.profileURL.length > 0 ? (
                <ProfilePhoto
                  src={generateImageUrl(values.profileURL)}
                  alt={values.fullName}
                />
              ) : null}

              <FormImageInput
                name="selectedProfilePhoto"
                label="Profile Photo"
                labelResolution="450 * 300 px"
                imageValues={values.profileURL}
                onChangeSelectedImage={(event) => {
                  setFieldTouched(event.target.name);
                  setFieldValue(event.target.name, event.target.files[0]);
                }}
                onError={(message) => {
                  setFieldError("selectedProfilePhoto", message);
                }}
                onCheckRes={(checkResolution) => {
                  setFieldValue("checkResolution", checkResolution);
                }}
                // checkResolution={values.checkResolution}
              />
            </Stack>
          </HomeCard>

          <HomeCard>
            <CardTitle
              style={{
                marginBottom: "2rem",
              }}
            >
              Personal Details
            </CardTitle>
            <Stack
              spacing={2}
              sx={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <FormInput label="School Name" name="schoolName" type="text" />
              <FormInput
                disabled
                label="School email address"
                name="schoolEmail"
              />
              <FormInput
                label="School Contact number"
                name="schoolPhoneNumber"
                type="text"
              />

              <MFormInputTime label="Start bell time" name="startBellTime" />
              <MFormInputTime label="First break bell" name="firstBreakBell" />
              <MFormInputTime
                label="Second break bell"
                name="secondBreakBell"
              />
              <MFormInputTime label="End bell time" name="endBellTime" />

              <TextArea label="School Description" name="schoolDescription" />
            </Stack>

            <CardTitle
              style={{
                marginTop: "3rem",
                marginBottom: "2rem",
              }}
            >
              Partnership Details
            </CardTitle>
            <Stack spacing={2}>
              <FormInput label="Enter name" name="partnerName" />
              <FormInput
                label="Enter partnership contact number"
                name="partnerPhoneNumber"
                type="text"
              />
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                marginTop: "2rem",
              }}
            >
              <Button onClick={handleSubmit} type="submit" primary>
                Save
              </Button>
            </Stack>
          </HomeCard>
        </Stack>
      )}
    </Formik>
  );
};

export default EditProfile;
