import React from "react";

// components
import { CardWrapper } from "../../../components/details";
import { ContentItem } from "../../../../../../../../teacher/pages/dashboard/pages/jobs/components/ScheduleDetails";
import { ShowListWithComma } from "../../../../../../../../../components/job-form";

function AboutDetail({ title, detail }) {
  const { classPreference, expertise } = detail;
  return (
    <CardWrapper title={title} style={{ padding: "2.4rem 1.2rem 1.6rem" }}>
      <ContentItem title="Teaching Levels">
        <ShowListWithComma list={classPreference} />
      </ContentItem>
      <ContentItem title="Subjects">
        <ShowListWithComma list={expertise} />
      </ContentItem>
    </CardWrapper>
  );
}

export default AboutDetail;
