import React from "react";
import { Stack } from "@mui/material";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

// components
import { ConfirmButton } from "../../Forms";
import { ModalWrapper } from "../../../pages/components";

// hooks
import useResponse from "../../../hooks/useResponse";

// services
import { addTeacherCard } from "../../../services/paymentMethodService";
import * as toast from "../../../Toast";

// css
import "./addCard.css";

const useOptions = () => {
  const options = {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: "1.5rem",
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  return options;
};

function AddCardModal({
  showModal,
  toggleModal,
  onSubmit,
  title,
  subtitle,
  note,
}) {
  //   const [stripePromise, setStripePromise] = useState(null);
  const [state, action] = useResponse();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  if (!showModal) return null;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.

      toast.info(
        "Failed to connect with payment method, please try again later."
      );

      return;
    }

    action.sendingRequst();
    const cardElement = elements.getElement(CardElement);

    const { error, token } = await stripe.createToken(cardElement);

    if (!error) {
      console.log("Token : ", token.id);

      try {
        const response = await addTeacherCard({ token: token.id });
        const { message } = await response.data;

        await onSubmit();

        // toggle modal
        toggleModal();

        toast.success(message);
        action.successResponse(message);
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 409
        ) {
          action.gotError(error.response.data.message);
          toast.error(error.response.data.message);
        }
      }
    } else {
      toggleModal();
      toast.info("Failed to add your card, please try again later.");
      action.gotError("Failed to add your card, please try again later.");
    }
  };

  return (
    <ModalWrapper
      toggleModal={toggleModal}
      title={title}
      subtitle={subtitle}
      note={note}
      error={state.error}
    >
      <Stack>
        <CardForm
          onSubmit={handleSubmit}
          stripe={stripe}
          options={options}
          state={state}
          onCancel={toggleModal}
        />
      </Stack>
    </ModalWrapper>
  );
}

export default AddCardModal;

function CardForm({ onSubmit, options, stripe, state, onCancel }) {
  return (
    <form onSubmit={onSubmit}>
      <label className="cardLabel">
        Card details
        <CardElement
          options={options}
          // onReady={() => {
          //   console.log("CardElement [ready]");
          // }}
          // onChange={(event) => {
          //   console.log("CardElement [change]", event);
          // }}
          // onBlur={() => {
          //   console.log("CardElement [blur]");
          // }}
          // onFocus={() => {
          //   console.log("CardElement [focus]");
          // }}
        />
      </label>
      {/* <button type="submit" disabled={!stripe}>
        Add Card
      </button> */}
      <Stack
        direction="row"
        spacing={2}
        sx={
          {
            //   marginTop: "2rem",
          }
        }
      >
        <ConfirmButton
          type="button"
          buttonText="Cancel"
          size={1.6}
          isBackground={false}
          onClick={onCancel}
        />
        <ConfirmButton
          type="submit"
          buttonText="Add Card"
          disabled={!stripe}
          size={1.6}
          loading={state.loading}
        />
      </Stack>
    </form>
  );
}
