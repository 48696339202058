import React, { useState } from "react";
import { useField } from "formik";

// components
import { FormErrorMessage } from ".";

import styles from "./input.module.css";

// eye icons
import eye from "../../assets/icon/eye.png";
// close eye icon
import closeEye from "../../assets/icon/eye-off.png";

function FormInput({
  label,
  type = "text",
  name,
  showError = true,
  error,
  success,
  ...otherProps
}) {
  const [field, meta] = useField(name);
  const [showPassword, setShowPassword] = useState(false);

  const handleToggle = () => {
    setShowPassword((prev) => !prev);
  };

  let icon;
  if (showPassword) {
    icon = (
      <div className={styles.iconContainer} onClick={handleToggle}>
        <img src={eye} className={styles.icon} alt="eye icon" />
      </div>
    );
  } else {
    icon = (
      <div className={styles.iconContainer} onClick={handleToggle}>
        <img src={closeEye} className={styles.icon} alt="eye icon" />
      </div>
    );
  }

  let className;
  if (meta.touched && meta.error) {
    className = "error";
  } else if (meta.touched && !meta.error) {
    className = "success";
  }

  // if (meta.touched && meta.error) {
  //   className = "error";
  //   icon = (
  //     <div className={styles.iconContainer}>
  //       <FiX color="white" className={`${styles.icon} ${styles[className]}`} />
  //     </div>
  //   );
  // } else if (meta.touched && !meta.error) {
  //   className = "success";
  //   icon = (
  //     <div className={styles.iconContainer}>
  //       <FiCheck
  //         color="white"
  //         className={`${styles.icon} ${styles[className]}`}
  //       />
  //     </div>
  //   );
  // }

  return (
    <div className={styles.main}>
      <div className={`${styles.container} ${styles[className]}`}>
        <input
          type={showPassword ? "text" : type}
          className={styles.inputText}
          required
          {...otherProps}
          {...field}
        />
        <span className={styles.floatingLabel}>{label}</span>
        {icon && icon}
      </div>
      {showError && <FormErrorMessage name={field.name} />}
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
    </div>
  );
}

export default FormInput;
