import React from "react";
import moment from "moment";
import { Stack } from "@mui/system";
import { CardTitle } from "../../../../../../../../../components";
import { HomeCard } from "../../../../../../../../components/dashboard";
import {
  DateText,
  DescriptionText,
  Image,
  LocationText,
} from "./Testimonial.styles";
import { Rating } from "@mui/material";

import http from "../../../../../../../../../services/httpService";

function Testimonial({ testimonial }) {
  const { schoolId: school, comment, ratings, createdAt } = testimonial;
  const { profileURL, schoolName, suburbName, state } = school;

  return (
    <HomeCard style={{ width: "100%" }}>
      <Stack
        display="flex"
        flexDirection="column"
        alignItems="center"
        spacing={0.5}
      >
        <Image src={http.imageBaseUrl + profileURL} />
        <Stack display="flex" flexDirection="column" alignItems="center">
          <CardTitle>{schoolName}</CardTitle>
          <LocationText>
            {suburbName}, {state}
          </LocationText>
        </Stack>
        <Rating
          value={ratings}
          readOnly
          style={{ fontSize: "2rem" }}
          precision={0.5}
        />
        <DescriptionText>{comment}</DescriptionText>
        <DateText>{moment(createdAt).format("DD MMM YYYY")}</DateText>
      </Stack>
    </HomeCard>
  );
}

export default Testimonial;
