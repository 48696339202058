import React from "react";
import moment from "moment";

import styles from "./jobListing.module.css";
import { ShowListWithComma } from "../../../../../../../components/job-form";

const statusObject = {
  open: "Open",
  cancelled: "Cancelled",
  booked: "Booked",
  completed: "Completed",
};

function JobItem({ job, onCellClick }) {
  const {
    id,
    jobTitle,
    jobType,
    classYear,
    recurrence,
    startDate,
    endDate,
    time,
    weekDays,
    applicantsCount,
    status,
  } = job;

  return (
    <tr
      className={`${styles.itemContainer} ${styles.rowContainer}`}
      onClick={() => onCellClick(id)}
    >
      <td className={styles.rowColumnText}>{jobTitle}</td>
      <td className={styles.rowColumnText}>{classYear}</td>
      <td className={styles.dateTimeColumn}>
        {recurrence === "Recurring" ? (
          <>
            <p className={styles.rowColumnText}>
              {moment(startDate).format("DD MMM YYYY")} -{" "}
              {moment(endDate).format("DD MMM YYYY")}
            </p>
            <p className={styles.rowColumnText}>
              at {moment(time).format("h:mm A")}
              ( <ShowListWithComma list={weekDays} />)
            </p>
          </>
        ) : (
          <>
            <p className={styles.rowColumnText}>
              {moment(startDate).format("DD MMM YYYY")}
            </p>
            <p className={styles.rowColumnText}>
              {moment(time).format("h:mm A")}
            </p>
          </>
        )}
      </td>
      <td className={`${styles.rowColumnText} ${styles.applicantCount}`}>
        {applicantsCount}
      </td>
      <td className={styles.rowColumnText}>{jobType}</td>
      <td className={styles.rowColumnText}>{statusObject[status]}</td>
    </tr>
  );
}

export default JobItem;
