import React from "react";

// components
import { Header } from "../../../../../../components";
import { JobListingTabs } from "../components";
import TeacherJobListing from "./components/TeacherJobListing";

function AllJobListing({ apiFunc, onCellClick, statusOptions }) {
  return (
    <div>
      <Header title="Jobs" />
      <JobListingTabs />
      <TeacherJobListing
        apiFunc={apiFunc}
        onCellClick={onCellClick}
        statusOptions={statusOptions}
      />
    </div>
  );
}

export default AllJobListing;
