import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

// components
import { HomeCard } from "../../../../../../components/dashboard";
import { CardTitle } from "../../../../../../components/JobForm";

import styles from "../home.module.css";
import { useQuery } from "react-query";
import { getActivityStats } from "../../../../../../../services/teacherHomeService";
import { Loader } from "../../../../../../../components";

const Card = styled("div")`
  border: 1px solid #eee9e8;
  border-radius: 12px;
  height: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
`;

const CardValue = styled("p")`
  font-size: 34px;
  line-height: 36px;
  color: ${(props) => props.variant === "applied" && "#4a7d9a"};
  color: ${(props) => props.variant === "completed" && "#D28540"};
  color: ${(props) => props.variant === "pending" && "#50AB7F"};
  color: ${(props) => props.variant === "cancelled" && "#A04D47"};
`;

const CardDesc = styled("p")`
  text-align: center;
  letter-spacing: 0.1px;
  color: #181c29;
  font-size: 17px;
  line-height: 24px;
`;

function ActivityCard() {
  const { isError, isLoading, data } = useQuery(
    ["teacher-activity"],
    () => getActivityStats(),
    { refetchOnWindowFocus: true, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <p>Some error occurred</p>;
  }

  const appliedJobs = data.data.activity.appliedJobs;
  const shiftsCompleted = data.data.activity.shiftsCompleted;
  const jobsPending = data.data.activity.jobsPending;
  const jobsCancelled = data.data.activity.jobsCancelled;

  return (
    <HomeCard className={styles.gridfirstchild}>
      <CardTitle
        style={{
          marginBottom: "1rem",
        }}
      >
        Activity
      </CardTitle>

      <Grid container spacing={2} columns={12}>
        <Grid item xs={6}>
          <Card>
            <CardValue variant="applied">{appliedJobs}</CardValue>
            <CardDesc>Jobs Applied</CardDesc>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardValue variant="completed">{shiftsCompleted}</CardValue>
            <CardDesc>Shifts Completed</CardDesc>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardValue variant="pending">{jobsPending}</CardValue>
            <CardDesc>Jobs Pending</CardDesc>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardValue variant="cancelled">{jobsCancelled}</CardValue>
            <CardDesc>Jobs Cancelled</CardDesc>
          </Card>
        </Grid>
      </Grid>
    </HomeCard>
  );
}

export default ActivityCard;
