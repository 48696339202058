import React from "react";
import { Routes, Route } from "react-router-dom";

// pages components
import AllJobs from "./all";
import AppliedJobs from "./applied";
import BookedJobs from "./booked";

function TeacherDashboardJobs() {
  return (
    <Routes>
      {/* <Route path="/" element={<Navigate to="/teacher/dashboard/jobs/all" />} /> */}
      <Route path="/*" element={<AllJobs />} />

      <Route path="/applied-jobs/*" element={<AppliedJobs />} />
      <Route path="/booked-jobs/*" element={<BookedJobs />} />
    </Routes>
  );
}

export default TeacherDashboardJobs;
