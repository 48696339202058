import React from "react";
import styles from "./layout.module.css";
import { styled } from "@mui/material/styles";

const Para = styled("p")`
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 24px;
  color: var(--text-secondary);
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  translate: 18% 50%;
  margin-top: -10rem;
  letter-spacing: 0.25px;
`;

const Link = styled("p")`
  text-decoration: none;
  font-weight: 500;
  color: var(--primary-full);
  margin: 0px 2px;
  cursor: pointer;
`;

function Layout({ imageUrl, showTermPrivary = false, children }) {
  return (
    <>
      <div className={styles.layoutContainer}>
        {/* <div className={styles.contentContainer}> */}
        <img
          src={imageUrl}
          alt="staff view  url"
          className={styles.imageStyle}
        />
        {/* <div>{children}</div> */}
        {children}
        {/* </div> */}
      </div>
      {showTermPrivary && (
        <Para>
          By signing up I agree with the <Link> Terms of Service </Link>
          {"   "} and{"   "}
          <Link> Privacy Policy </Link>
        </Para>
      )}
    </>
  );
}

export default Layout;
