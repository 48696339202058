import React from "react";

// components
import { Title } from "../../../../../../components";

import AvailableJobCard from "./components/AvailableJobCard";
import RecentlyAppliedJobCard from "./components/RecentlyAppliedJobsCard";
import ActivityCard from "./components/ActivityCard";

import styles from "./home.module.css";

function TeacherDashboardHome() {
  return (
    <>
      <Title>Dashboard</Title>
      <div className={styles.mainContainer}>
        <ActivityCard />
        <RecentlyAppliedJobCard />
        <AvailableJobCard />
      </div>
    </>
  );
}

export default TeacherDashboardHome;
