import React from "react";
import { styled } from "@mui/material/styles";

const CustomButton = styled("button")`
  outline: none;
  width: 100%;
  padding: 1.5rem 2rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  border-radius: 8px;

  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  /* props.primary ? "var(--primary-full)" : "transparent" */

  background-color: ${(props) => {
    if (props.primary) {
      return "var(--primary-full)";
    }

    if (props.transparent) {
      return "transparent";
    }

    return "var(--primary-full)";
  }};

  color: ${(props) => {
    if (props.primary) {
      return "#fff";
    }

    if (props.transparent) {
      return "var(--primary-full)";
    }

    return "#fff";
  }};

  border: ${(props) => {
    if (props.primary) {
      return "none";
    }

    if (props.transparent) {
      return "none";
    }

    if (props.secondary) {
      return "2px solid var(--primary-full)";
    }

    return "none";
  }};

  opacity: 1;

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Button = ({ children, ...otherProps }) => {
  return <CustomButton {...otherProps}>{children}</CustomButton>;
};

export default Button;
