import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { CardTitle, Loader } from "../../../../../../../../components";
import { HomeCard } from "../../../../../../../components/dashboard";
import { FormInputPassword } from "../../../../../../../../components/Forms";
import Button from "../../components/Button";
import { schoolChangePassword } from "../../../../../../../../stores/schoolRegisterationReducer";

// common validator
import {
  passwordValidation,
  whitespaceValidation,
} from "../../../../../../../validator/common";

const initialValues = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required()
    .min(6)
    .max(30)
    .trim()
    .test(
      "type",
      "Password must not contain Whitespaces.",
      whitespaceValidation
    )
    .test(
      "type",
      "Password must contain at least one Special Character(Special characters @ # $ ! allowed) and one digit.",
      passwordValidation
    )
    .label("Current Password"),
  newPassword: Yup.string()
    .required()
    .min(6)
    .max(30)
    .trim()
    .test(
      "type",
      "New Password must not contain Whitespaces.",
      whitespaceValidation
    )
    .test(
      "type",
      "New Password must contain at least one Special Character(Special characters @ # $ ! allowed) and one digit.",
      passwordValidation
    )
    .label("New Password"),
  confirmNewPassword: Yup.string()
    .required()
    .min(6)
    .max(30)
    .label("Confirm New Password")
    .oneOf([Yup.ref("newPassword")], "Confirm new password does not match.")
    .trim(),
});

const AccountSettings = () => {
  const dispatch = useDispatch();

  const { loading, school, error } = useSelector(
    (state) => state.entities.schoolRegisteration
  );

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>Something went wrong</p>;
  }

  const onSubmit = async (data, options) => {
    data.schoolEmail = school.schoolEmail;
    dispatch(schoolChangePassword(data));

    // resetForm();
    options.resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <>
          <HomeCard>
            <CardTitle
              style={{
                marginBottom: "1rem",
              }}
            >
              Change Password
            </CardTitle>

            <Stack>
              <Stack
                spacing={1}
                sx={{
                  marginTop: "1rem",
                }}
              >
                <FormInputPassword
                  label="Enter Current Password"
                  name="currentPassword"
                  type="password"
                />
                <FormInputPassword
                  label="Enter New Password"
                  name="newPassword"
                  type="password"
                />
                <FormInputPassword
                  label="Enter New Password Again"
                  name="confirmNewPassword"
                  type="password"
                />
              </Stack>
            </Stack>
          </HomeCard>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              marginTop: "2rem",
            }}
          >
            <Button type="submit" onClick={handleSubmit} primary>
              Save
            </Button>
          </Stack>
        </>
      )}
    </Formik>
  );
};

export default AccountSettings;
