import React from "react";
import { useField } from "formik";

// components
import { FormErrorMessage } from ".";

import styles from "./input.module.css";
import styles2 from "./formTime.module.css";

// clock icons
import clockIcon from "../../assets/icon/clock.png";

// RC Time Picker
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

export default function MFormInputTime({
  label,
  name,
  placeholder = "Select Time",
}) {
  const [field, meta, helper] = useField(name);

  const { setValue, setTouched } = helper;

  const handleValueChange = (value) => {
    if (value) {
      setValue(moment(value).format());
    } else {
      setValue(null);
    }
  };

  let className;
  if (meta.touched && meta.error) {
    className = "error";
  } else if (meta.touched && !meta.error) {
    className = "success";
  }

  let value = field.value;

  if (field.value) {
    value = moment(field.value);
    // console.log("Is valid : ", value.isValid === true);
    // value = value.isValid === true ? value : null;
  }

  let icon = (
    <div className={styles2.iconContainer}>
      <img src={clockIcon} className={styles.icon} alt="eye icon" />
    </div>
  );

  return (
    <div className={styles.main} onBlur={() => setTouched(true)}>
      <div>
        <label className={` ${styles2.label}`}>{label}</label>
        <div className={`${styles2[className]} ${styles2.timecontainer}`}>
          <TimePicker
            id={name}
            className={` ${styles2.inputText} ${styles2[className]}`}
            placeholder={placeholder}
            value={value}
            onChange={handleValueChange}
            showSecond={false}
            use12Hours
            allowEmpty
            format="hh:mm A"
          />
          {icon && icon}
        </div>
      </div>
      <FormErrorMessage name={field.name} />
    </div>
  );
}
