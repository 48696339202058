import {
  clearRegistrationData,
  getRegistrationData,
  setRegistrationData,
} from "./localStorage";

const schoolFirstForm = "school-first-form";
const schoolSecondForm = "school-second-form";

export const getSchoolFirstFormData = () => {
  return getRegistrationData(schoolFirstForm);
};

export const getSchoolSecondFormData = () => {
  return getRegistrationData(schoolSecondForm);
};

export const setSchoolFirstFormData = (data) => {
  setRegistrationData(schoolFirstForm, data);
};

export const setSchoolSecondFormData = (data) => {
  setRegistrationData(schoolSecondForm, data);
};

export const clearSchoolRegistrationData = () => {
  clearRegistrationData(schoolFirstForm);
  clearRegistrationData(schoolSecondForm);
};
