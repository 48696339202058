import React from "react";
import { styled } from "@mui/material/styles";

// components
import { HomeCard } from "../../../../../../components/dashboard";

import styles from "../home.module.css";
import { useQuery } from "react-query";
import { Loader } from "../../../../../../../components";

import { Stack } from "@mui/material";
import { getRecentlyVerifiedTeachers } from "../../../../../../../services/schoolHomeService";
import generateImageUrl from "../../../../../../../utils/generateImageUrl";
import moment from "moment";
import { ProfileImage } from "../../../../../../components/TopBar";
import { ShowMoreList } from "../../../../../../../components/job-form";

const TeacherGridContainer = styled("div")`
  border-radius: 12px;
  border: 1px solid #eee9e8;
`;

const TeacherGrid = styled("div")`
  display: grid;
  border-bottom: 1px solid #eee9e8;
  padding: 16px 24px;
  grid-template-columns: 3fr 3fr 2fr;

  &:last-child {
    border-bottom: none;
  }
`;

const TeacherHeaderGrid = styled("div")`
  display: grid;
  border-bottom: 1px solid #eee9e8;
  grid-template-columns: 3fr 3fr 2fr;
  padding: 16px 24px;
`;

const TeacherName = styled("p")`
  font-size: 1.5rem;
  color: #181c29;
`;

const ProfilePhoto = styled("img")`
  width: 48px;
  height: 48px;
  display: block;
  border-radius: 50%;
`;

const Details = styled("p")`
  font-size: 1.5rem;
  color: #565b64;
`;

const TableHeaderText = styled("p")`
  font-size: 1.4rem;
  color: #181c29;
`;

const CardTitle = styled("h2")`
  font-size: 2rem;
  font-weight: 500;
  color: #181c29;
`;

const TableHeaders = ["Name & TRN", "Expertise", "Verified Date"];

const VerifiedTeacherRow = ({ teacher, verifiedDate }) => {
  const { profileURL, fullName, expertise, teacherRegNumber } = teacher;

  return (
    <TeacherGrid>
      <Stack
        direction="row"
        spacing={1}
        // sx={{
        //   alignItems: "center",
        // }}
      >
        {/* <ProfilePhoto src={generateImageUrl(profileURL)} alt={fullName} /> */}
        <ProfileImage imageUrl={profileURL} name={fullName} />
        <Stack>
          <TeacherName>{fullName}</TeacherName>
          <Details>{teacherRegNumber}</Details>
        </Stack>
      </Stack>

      {/* <Stack>
        {showMore ? (
          expertise.map((item, index) => (
            <Details key={index}>
              {item}
              {expertise.length - 1 !== index ? ", " : ""}
            </Details>
          ))
        ) : (
          <Details>{expertise[0]}</Details>
        )}

        {showMore ? null : (
          <Details
            style={{
              cursor: "pointer",
            }}
            onClick={() => setShowMore(true)}
          >
            +{expertise.length - 1} more
          </Details>
        )}
      </Stack> */}

      <Details>
        <ShowMoreList list={expertise} />
      </Details>

      <Stack>
        <Details>{moment(verifiedDate).format("D MMM YYYY")}</Details>
      </Stack>
    </TeacherGrid>
  );
};

function RecentlyVerifiedTeachers() {
  const { isError, isLoading, data } = useQuery(
    ["recently-verified-teachers"],
    () => getRecentlyVerifiedTeachers(),
    { refetchOnWindowFocus: true, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <p>Some error occurred</p>;
  }

  const verifiedTeachers = data.data.teachers;

  return (
    <HomeCard className={styles.gridSecondchild}>
      <CardTitle
        style={{
          marginBottom: "1rem",
        }}
      >
        Recently Verified Teachers
      </CardTitle>

      {verifiedTeachers.length > 0 ? (
        <TeacherGridContainer>
          <TeacherHeaderGrid>
            {TableHeaders.map((item, index) => (
              <div key={index}>
                <TableHeaderText>{item}</TableHeaderText>
              </div>
            ))}
          </TeacherHeaderGrid>
          {verifiedTeachers.map((verifiedTeacher) => (
            <VerifiedTeacherRow
              teacher={verifiedTeacher.teacher}
              verifiedDate={verifiedTeacher.updatedAt}
              key={verifiedTeacher._id}
            />
          ))}
        </TeacherGridContainer>
      ) : null}

      {verifiedTeachers.length === 0 ? (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <p
            style={{
              letterSpacing: "0.1px",
              color: "var(--text-tertiary)",
              fontSize: "17px",
              lineHeight: "24px",
              fontWeight: 600,
            }}
          >
            No Recently Verified Teachers
          </p>
        </Stack>
      ) : null}
    </HomeCard>
  );
}

export default RecentlyVerifiedTeachers;
