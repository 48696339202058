import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

// components
import {
  AdditionalDetails,
  ExpertiesAndExperience,
  ScheduleDetails,
  SchoolDetails,
} from "./components";
import { ConfirmButton } from "../../../../../../components/Forms";
import { ErrorMessage, Loader } from "../../../../../../components";
import { ConfirmationModal, RedirectTabs } from "../../../../../components";
import { CardTitle } from "../../../../../components/JobForm";

// hooks
import useResponse from "../../../../../../hooks/useResponse";
import useVisible from "../../../../../../hooks/useVisible";

// services
import { getJobDetailsForTeacher } from "../../../../../../services/jobDetailsService";
import {
  sendWithdrawJobApplicant,
  checkTeacherAppliedToJob,
} from "../../../../../../services/jobApplicationService";
import { checkTeacherHasAddedCard } from "../../../../../../services/paymentMethodService";

import * as toast from "../../../../../../Toast";

import styles from "./components/jobDetails.module.css";

// function getNavLinkList(jobId) {
//   const navLinkList = [
//     {
//       to: "/teacher/dashboard/jobs",
//       tabName: "Jobs",
//     },
//     {
//       to: `/teacher/dashboard/jobs/detail/${jobId}`,
//       tabName: "Job Details",
//     },
//   ];

//   return navLinkList;
// }

function JobDetailPage({ setJobId, navList }) {
  const navigate = useNavigate();
  const params = useParams();
  const [state, action] = useResponse();
  const [checkCardState, checkCardAction] = useResponse();
  const [showCardAlert, toggleCardAlert] = useVisible();
  const [showModal, toggleModal] = useVisible();
  const jobId = params.id;

  const onError = (error) => {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status <= 409
    ) {
      navigate("/not-found");
    }
  };

  const { isError, isLoading, data, error } = useQuery(
    ["teacher-job-details", jobId],
    () => getJobDetailsForTeacher(jobId),
    { refetchOnWindowFocus: true, onError, cacheTime: 100 }
  );

  const {
    isError: isErrorOfCheck,
    isLoading: isLoadingOfCheck,
    data: appliedData,
    error: errorOfCheck,
  } = useQuery(
    ["check-applied-for-job", jobId],
    () => checkTeacherAppliedToJob({ jobId }),
    { refetchOnWindowFocus: true, onError, cacheTime: 100 }
  );

  if (isLoading || isLoadingOfCheck) {
    return <Loader />;
  }

  if (isError || isErrorOfCheck) {
    return (
      <ErrorMessage
        message={
          error.response.data.message || errorOfCheck.response.data.message
        }
      />
    );
  }

  const handleClickOnApplyJob = () => {
    setJobId(jobId);
    navigate(`/teacher/dashboard/jobs/detail/${jobId}/apply`);
  };

  const navigateToListing = () => {
    navigate(-1);
  };

  const handleCheckTeacherHasAddedCard = async () => {
    try {
      checkCardAction.sendingRequst();
      const response = await checkTeacherHasAddedCard();
      const { message, data } = await response.data;
      console.log("data : ", data);

      if (!data.isCardAdded) {
        // open toggle modal, if card is not added
        toggleCardAlert();
      } else {
        handleClickOnApplyJob();
      }

      checkCardAction.successResponse(message);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        checkCardAction.gotError(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  };

  const handleWithdrawConfirm = async () => {
    try {
      action.sendingRequst();
      const response = await sendWithdrawJobApplicant({
        jobId,
      });
      const { message } = await response.data;

      // toggle modal
      toggleModal();
      // navigate to lisiting
      navigateToListing();

      toast.success(message);
      action.successResponse(message);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        action.gotError(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  };

  const isAppliedForJob = appliedData.data.data["isAppliedForJob"];

  const job = data.data.data["job"];
  const {
    jobTitle,
    jobType,
    startDate,
    endDate,
    time,
    weekDays,
    recurrence,
    classYear,
    expertise,
    position,
    description,
    notes,
    status,
    schoolId,
  } = job;

  const subtitle = (
    <>
      You've not added payment method yet.
      <br />
      Go to Settings &#62; &#62; Payment Method and add card details.
    </>
  );

  return (
    <>
      <ConfirmationModal
        subtitle="Are you sure you want to withdraw your job application?"
        ConfirmButtonText="Confirm"
        CancelButtonText="Cancel"
        showModal={showModal}
        onConfirm={handleWithdrawConfirm}
        toggleModal={toggleModal}
        loading={state.loading}
      />
      <ConfirmationModal
        subtitle={subtitle}
        ConfirmButtonText="Okay"
        showModal={showCardAlert}
        onConfirm={toggleCardAlert}
        toggleModal={toggleCardAlert}
        hideCancel
      />
      <div>
        <RedirectTabs redirectList={navList} />
        <CardTitle>Job Details</CardTitle>
        <div className={styles.mainContainer}>
          <div className={styles.jobDetailsContainer}>
            <ScheduleDetails
              scheduleDetail={{
                jobTitle,
                jobType,
                startDate,
                endDate,
                time,
                weekDays,
                recurrence,
              }}
            />
            <ExpertiesAndExperience
              detail={{ classYear, position, description, expertise }}
            />
            <AdditionalDetails notes={notes} />
            {/*        
            <ConfirmButton
              buttonText="Apply"
              size={1.6}
              onClick={handleClickOnApplyJob}
            /> */}
            {/* <label htmlFor=""></label> */}
            <ButtonContainer
              applied={isAppliedForJob}
              status={status}
              onApply={handleCheckTeacherHasAddedCard}
              onWithdraw={toggleModal}
              isLoading={checkCardState.loading}
            />
          </div>
          <div className={styles.schoolDetailsContainer}>
            <SchoolDetails school={schoolId} />
          </div>
        </div>
      </div>
    </>
  );
}

export default JobDetailPage;

const ButtonContainer = ({
  applied,
  status,
  onApply,
  onWithdraw,
  isLoading,
}) => {
  if (status === "cancelled" || status === "completed") {
    return null;
  }

  if (applied) {
    return (
      <ConfirmButton
        buttonText="Withdraw Application"
        size={1.6}
        onClick={onWithdraw}
        loading={isLoading}
      />
    );
  }

  return (
    <>
      <CardTitle>
        Note: You will be charged $3.95 AUD as flat fee per job if you are
        awarded any job.
      </CardTitle>
      <ConfirmButton
        buttonText="Apply"
        size={1.6}
        onClick={onApply}
        loading={isLoading}
      />
    </>
  );
};
