import http from "./httpService";

const apiEndpoint = http.apiUrl + "notification/";

const constructionURL = (API_URL_ROUTE, { page, limit, filter = {} }) => {
  const url = new URL(apiEndpoint + API_URL_ROUTE);

  url.searchParams.set("page", page);
  url.searchParams.set("limit", limit);

  if (filter.jobType) {
    url.searchParams.set("jobType", filter.jobType);
  }

  if (filter.category) {
    url.searchParams.set("category", filter.category);
  }

  if (filter.status) {
    url.searchParams.set("status", filter.status);
  }

  return url;
};

// service to get school notification
export function getSchoolNotifications({ queryKey }) {
  // console.log(props);

  const { page, limit } = queryKey[1];

  const url = constructionURL("school", {
    page,
    limit,
  });

  return http.get(url);
}

// service to get teacher notification
export function getTeacherNotifications({ page = 1, limit = 5 }) {
  const url = constructionURL("teacher", {
    page,
    limit,
  });

  return http.get(url);
}

// service to get school notification
export function markAllNotificationsReadSchool() {
  return http.put(apiEndpoint + "school/mark-all-notifications-read");
}

// service to get teacher notification
export function markAllNotificationsReadTeacher() {
  return http.put(apiEndpoint + "teacher/mark-all-notifications-read");
}

// markNotificationRead;
