import {
  createSelector,
  createSlice,
  createAsyncThunk,
  //   current,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  getSchoolInfo,
  updateSchoolChangePassord,
  updateSchoolEditProfile,
} from "../services/profileService";

import {
  setSchoolFirstFormData,
  setSchoolSecondFormData,
  getSchoolFirstFormData,
  // getSchoolSecondFormData,
} from "../utils/schoolRegisterData";

export const fetchSchoolInfo = createAsyncThunk("school/fetchAll", async () => {
  const response = await getSchoolInfo();
  const data = await response.data;

  return data;
});

export const editSchoolProfileUpdate = createAsyncThunk(
  "school/update-edit-profile",
  async (details) => {
    try {
      const response = await updateSchoolEditProfile(details);
      const data = await response.data;
      toast.success(response.data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      // console.log();
      return error;
    }
  }
);

export const schoolChangePassword = createAsyncThunk(
  "school/change-password",
  async (details) => {
    try {
      const response = await updateSchoolChangePassord(details);
      const data = await response.data;
      toast.success(response.data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      // console.log();
      return error;
    }
  }
);

const initialState = {
  loading: false,
  school: {
    // first step form fields
    id: "",
    schoolName: "",
    schoolEmail: "",
    password: "",
    schoolPhoneNumber: "",
    schoolAddress: "",
    schoolLatitude: "",
    schoolLongitude: "",
    suburbName: "",
    state: "",
    postcode: "",

    // second step form fields
    startBellTime: "",
    firstBreakBell: "",
    secondBreakBell: "",
    endBellTime: "",
    partnerName: "",
    partnerPhoneNumber: "",
    profileURL: "",
  },
  error: "",
};

const schoolRegisterationSlice = createSlice({
  name: "school-register",
  initialState: initialState,
  reducers: {
    setSchoolStepFirstData: (state, action) => {
      state.school = { ...state.school, ...action.payload };
      delete action.payload.cPassword;

      // storing in global storage
      setSchoolFirstFormData(action.payload);
    },
    setSchoolStepSecondData: (state, action) => {
      state.school = { ...state.school, ...action.payload };
      // storing in global storage
      setSchoolSecondFormData(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchoolInfo.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchSchoolInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.school = action.payload.data.school;
      })
      .addCase(fetchSchoolInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

    builder
      .addCase(editSchoolProfileUpdate.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(editSchoolProfileUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.school = {
          ...state.school,
          ...action.payload.data.school,
        };
      })
      .addCase(editSchoolProfileUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

    builder
      .addCase(schoolChangePassword.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(schoolChangePassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(schoolChangePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const { setSchoolStepFirstData, setSchoolStepSecondData } =
  schoolRegisterationSlice.actions;
export default schoolRegisterationSlice.reducer;

export const getSchoolRegisterInfo = createSelector(
  (state) => state?.entities.schoolRegisteration.school,
  (school) => {
    const {
      schoolName,
      schoolEmail,
      password,
      schoolPhoneNumber,
      schoolAddress,
      schoolLatitude,
      schoolLongitude,
      suburbName,
      state,
      postcode,
    } = school;

    // getting school registration data from global storage
    const result = getSchoolFirstFormData();
    if (result) {
      return result;
    }

    return {
      schoolName,
      schoolEmail,
      password,
      schoolPhoneNumber,
      schoolAddress,
      schoolLatitude,
      schoolLongitude,
      suburbName,
      state,
      postcode,
    };
  }
);

export const getSchoolHeaderInfo = createSelector(
  (state) => state?.entities.schoolRegisteration.school,
  (school) => {
    const { schoolName, profileURL } = school;

    return {
      schoolName,
      profileURL,
    };
  }
);
