import {
  clearRegistrationData,
  getRegistrationData,
  setRegistrationData,
} from "./localStorage";

const teacherFirstForm = "teacher-first-form";
const teacherSecondForm = "teacher-second-form";
const teacherThirdForm = "teacher-third-form";

export const getTeacherFirstFormData = () => {
  return getRegistrationData(teacherFirstForm);
};

export const getTeacherSecondFormData = () => {
  return getRegistrationData(teacherSecondForm);
};

export const getTeacherThirdFormData = () => {
  return getRegistrationData(teacherThirdForm);
};

export const setTeacherFirstFormData = (data) => {
  setRegistrationData(teacherFirstForm, data);
};

export const setTeacherSecondFormData = (data) => {
  setRegistrationData(teacherSecondForm, data);
};

export const setTeacherThirdFormData = (data) => {
  setRegistrationData(teacherThirdForm, data);
};

export const clearTeacherRegistrationData = () => {
  clearRegistrationData(teacherFirstForm);
  clearRegistrationData(teacherSecondForm);
  clearRegistrationData(teacherThirdForm);
};
