/*
@reference https://www.telerik.com/blogs/integrating-google-places-autocomplete-api-react-app
*/
import { useRef, useEffect, useCallback } from "react";
import { useField } from "formik";

// components
import { FormErrorMessage } from ".";

import styles from "./input.module.css";

// map icons
import mapIcon from "../../assets/icon/map-pin.png";

// You need to create a new one for your application.
// const GOOGLE_MAPS_API_KEY = "AIzaSyCFBH-mgRmkUSGhDVakP-iyh8MoTqYaUeo";

// function loadScript(src, position, id) {
//   if (!position) {
//     return;
//   }

//   const script = document.createElement("script");
//   script.setAttribute("async", "");
//   script.setAttribute("id", id);
//   script.src = src;
//   position.appendChild(script);
// }

function FormLocationInput({
  label,
  signal,
  showError = true,
  onCordinateChange,
  error,
  success,
  ...otherProps
}) {
  const [field, meta, helper] = useField(otherProps);

  // const [cord, setCord] = useState({ lat: 0, lng: 0 });
  const { setValue, setTouched } = helper;

  const autoCompleteRef = useRef();
  const inputRef = useRef();
  // const loaded = useRef(false);

  // if (typeof window !== "undefined" && !loaded.current) {
  //   if (!document.querySelector("#google-maps")) {
  //     loadScript(
  //       `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
  //       document.querySelector("head"),
  //       "google-maps"
  //     );
  //   }

  //   loaded.current = true;
  // }

  const getDataFunction = useCallback(async function () {
    const place = await autoCompleteRef.current.getPlace();
    // console.log({ place });
    // console.log(place.geometry.location.lat());
    // console.log(place.geometry.location.lng());
    // console.log(inputRef.current.value);
    // setLocation(inputRef.current.value);
    setValue(inputRef.current.value);

    onCordinateChange({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    // setCord({
    //   lat: place.geometry.location.lat(),
    //   lng: place.geometry.location.lng(),
    // });
  }, []);

  useEffect(() => {
    const options = {
      componentRestrictions: { country: "au" },
      fields: ["address_components", "geometry", "icon", "name"],
      types: ["establishment"],
    };
    if (inputRef && window.google) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      autoCompleteRef.current.addListener("place_changed", getDataFunction);
    }
  }, []);

  let className;
  if (meta.touched && meta.error) {
    className = "error";
  } else if (meta.touched && !meta.error) {
    className = "success";
  }

  let icon = (
    <div className={styles.iconContainer}>
      <img src={mapIcon} className={styles.icon} alt="eye icon" />
    </div>
  );

  return (
    <div className={styles.main}>
      <div
        className={`${styles.container} ${styles[className]}`}
        onBlur={() => setTouched(true)}
      >
        <input
          type="text"
          className={styles.inputText}
          required
          {...otherProps}
          // {...field}
          ref={inputRef}
          defaultValue={field.value}
        />
        <span className={styles.floatingLabel}>{label}</span>
        {icon && icon}
      </div>
      {showError && <FormErrorMessage name={field.name} />}
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
    </div>
  );
}

export default FormLocationInput;
