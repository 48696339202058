import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./redirectTabs.module.css";

function RedirectTabs({ redirectList = [] }) {
  const lastIndex = redirectList.length - 1;

  return (
    <div className={styles.redirectContainer}>
      {redirectList.map(({ to, tabName }, index) => {
        return (
          <NavLink
            key={index}
            to={to}
            className={(navData) =>
              index === lastIndex ? `${styles.active} ` : `${styles.tab}`
            }
          >
            {index ? <span className={styles.arrow}> › </span> : ""}{" "}
            <span>{tabName}</span>
          </NavLink>
        );
      })}
    </div>
  );
}

export default RedirectTabs;
