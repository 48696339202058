import { Stack } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { ModalWrapper } from "../../../pages/components";
import { FormButton, FormInput, FormInputDate, FormSelect } from "../../Forms";
import { useDispatch } from "react-redux";
import { teacherAddCertificate } from "../../../stores/teacherRegisterationReducer";

const initialValues = {
  documentNumber: "",
  documentTitle: "",
  documentType: "",
  expiryDate: "",
};

const documentTypeList = [
  {
    key: "Certificate",
    value: "Certificate",
  },
  {
    key: "License",
    value: "License",
  },
];

const validationSchema = Yup.object().shape({
  documentNumber: Yup.string()
    .min(5)
    .label("Document number")
    .required("Document number is required"),
  documentTitle: Yup.string()
    .min(5)
    .label("Document title")
    .required("Document title is required"),
  documentType: Yup.string()
    .min(5)
    .label("Document Type")
    .required("Document Type is required"),
  expiryDate: Yup.string().required().label("Expiry date"),
});

function DocumentsForm({
  showModal,
  toggleModal,
  onConfirm,
  title,
  subtitle,
  note,
  error,
  loading = false,
  CancelButtonText = "Cancel",
  ConfirmButtonText = "Confirm",
}) {
  const dispatch = useDispatch();

  const onSubmit = (data, options) => {
    dispatch(teacherAddCertificate(data));
    toggleModal();
    options.resetForm();
  };

  if (!showModal) return null;

  return (
    <ModalWrapper
      toggleModal={toggleModal}
      title={title}
      subtitle={subtitle}
      note={note}
      error={error}
    >
      <Stack>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {() => (
            <>
              <Stack spacing={1}>
                <FormInput
                  label="Document Number"
                  name="documentNumber"
                  type="text"
                />
                <FormInput
                  label="Document Title"
                  name="documentTitle"
                  type="text"
                />
                <FormSelect
                  label="Select Document"
                  name="documentType"
                  placeholder=""
                  options={documentTypeList}
                />

                <FormInputDate
                  label="Expiry Date"
                  name="expiryDate"
                  type="date"
                />
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                sx={{
                  marginTop: "2rem",
                }}
              >
                <FormButton
                  buttonText="Cancel"
                  type="button"
                  submitButton={false}
                  isBackground={false}
                  onClick={toggleModal}
                  // isDisabled={true}
                />
                <FormButton
                  buttonText="Save"
                  type="submit"
                  submitButton={true}
                  isDisabled={true}
                />
              </Stack>
            </>
          )}
        </Formik>
      </Stack>
    </ModalWrapper>
  );
}

export default DocumentsForm;
