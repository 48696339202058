import React, { useRef, useState } from "react";
import { useField } from "formik";

// components
import { CheckBoxSingleSelect } from ".";
import { FormErrorMessage } from ".";

// hooks
import useOnClickOutside from "../../hooks/useOnClickOutside";

// eye icons
import chevrondown from "../../assets/icon/chevron-down.png";

import styles from "./multiSelect.module.css";
import styles2 from "./input.module.css";

function FormSelect(props) {
  const { label, name, options, onChange, showError = true, ...rest } = props;
  const ref = useRef(null);
  const [isOpen, toggleOpen] = useState(false);
  const [field, meta, helper] = useField(name);
  const { setValue, setTouched } = helper;

  useOnClickOutside(ref, () => {
    toggleOpen(false);
    // setTouched(true);
  });

  let className;
  if (meta.touched && meta.error) {
    className = "error";
  } else if (meta.touched && !meta.error) {
    className = "success";
  }

  const isValue = field.value;

  return (
    <div className={styles.container} ref={ref}>
      <div
        className={`${styles.selectBtn}  ${styles[className]}`}
        onClick={() => {
          if (isOpen) toggleOpen(false);
          else toggleOpen(true);

          setTouched(true);
        }}
      >
        <div>
          {label && (
            <span
              className={`${styles.btnText} ${isValue && styles.btnTextSmall}`}
            >
              {label}
            </span>
          )}
          {field.value && <p className={styles.selectedValue}>{field.value}</p>}
        </div>
        <img
          src={chevrondown}
          className={styles2.icon}
          alt="chevrondown icon"
        />
      </div>

      <div className={`${styles.checkboxContainer} ${isOpen && styles.open}`}>
        {options.map((option) => (
          <CheckBoxSingleSelect
            key={option.key}
            field={field}
            option={option}
            setValue={(value) => {
              setValue(value);
              toggleOpen(false);
              onChange && onChange(value);
            }}
            {...rest}
          />
        ))}
      </div>

      {showError && <FormErrorMessage name={field.name} />}
    </div>
  );
}

export default FormSelect;
