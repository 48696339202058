import React from "react";
import { Stack } from "@mui/material";
import { Card, Portal } from ".";
import { HeaderTitleSubtitle, ErrorMessage } from "../../components";
import styles from "./modal.module.css";

function ModalWrapper({ toggleModal, title, subtitle, note, error, children }) {
  return (
    <Portal toggleModal={toggleModal}>
      <div className={styles.modalContainer}>
        <Card className={`${styles.confirmModal}`}>
          <Stack spacing={2} marginBottom={0} className="mb-10">
            <Stack spacing={0.5} marginTop={2} className="mb-10">
              <HeaderTitleSubtitle
                title={title}
                subtitle={subtitle}
                note={note}
              />
              {error && <ErrorMessage message={error} />}
            </Stack>
            {children}
          </Stack>
        </Card>
      </div>
    </Portal>
  );
}

export default ModalWrapper;
