import jwtDecode from "jwt-decode";
import http from "./httpService";

const apiEndpoint = http.apiUrl + "school-auth";

const tokenKey = "school-token";

http.setSchoolJwt(getJwt());

export function checkSchoolRegistered(schoolEmailData) {
  return http.post(apiEndpoint + "/check-email", schoolEmailData);
}

export function schoolRegister(schoolData) {
  return http.post(apiEndpoint + "/register", schoolData);
}

export function schoolLogin(loginData) {
  return http.post(apiEndpoint + "/login", loginData);
}

export function schoolForgotPassword(emailObject) {
  return http.post(apiEndpoint + "/forget-password", emailObject);
}

export function schoolResetPassword(passwordObject, resetToken) {
  return http.put(
    apiEndpoint + "/password-reset/" + resetToken,
    passwordObject
  );
}

export function logout() {
  sessionStorage.removeItem(tokenKey);
}

export function getSchoolUser() {
  try {
    const jwt = sessionStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function validateToken(jwt) {
  try {
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function setJWT(jwt) {
  http.setJwt(jwt);
  sessionStorage.setItem(tokenKey, jwt);
}

export function getJwt() {
  return sessionStorage.getItem(tokenKey);
}

const authObject = {
  schoolLogin,
  logout,
  setJWT,
  getSchoolUser,
  getJwt,
};

export default authObject;
