import axios from "axios";
import * as toast from "../Toast";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 409 &&
    error.response.status <= 500;

  if (expectedError) {
    toast.error(`An unexpected error occurrred, Internal Server Error.`);
    // alert("An unexpected error occurrred, Internal Server Error");
  }

  return Promise.reject(error);
});

// const schoolToken =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzM2QwZTMyMmEwZDJiM2YzODgyYTFiZSIsImVtYWlsIjoibWFuaXNoLmhlZGF1QGpoYXZ0ZWNoLmNvbSIsInJvbGUiOiJzY2hvb2wiLCJpc0FkbWluIjp0cnVlLCJpYXQiOjE2NjU5ODIyNDYsImV4cCI6MTY2ODU3NDI0Nn0.9n4Ex8ikfwcC8DWfjVm5KB7yA75GC1I0o7NMs4c25DA";

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
  // axios.defaults.headers.common["x-auth-token"] = schoolToken;
}

function setSchoolJwt(jwt) {
  axios.defaults.headers.common["x-school-token"] = jwt;
}

function setTeacherJwt(jwt) {
  axios.defaults.headers.common["x-teacher-token"] = jwt;
}

// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.common["Content-Type"] = "application/json";

export const baseUrl = "https://staff-view-backend.herokuapp.com/";
// export const baseUrl = "http://localhost:8080/";

const apiUrl = `${baseUrl}api/v1/`;

const imageBaseUrl = baseUrl + "images/";

const httpMethodsObject = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  setJwt,
  setTeacherJwt,
  setSchoolJwt,
  apiUrl,
  baseUrl,
  imageBaseUrl,
};

export default httpMethodsObject;
