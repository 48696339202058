import React from "react";
import moment from "moment";
import styles from "./home.module.css";

function HomeJobItem({ job, onJobClick }) {
  const {
    id,
    jobTitle,
    classYear,
    startDate,
    schoolName,
    schoolAddress,
    jobType,
    recurrence,
  } = job;
  return (
    <div className={styles.listingItem} onClick={() => onJobClick(id)}>
      <div className={styles.rowContainer}>
        <JobTitle title={jobTitle} />
        <p className={styles.classYearText}>{classYear}</p>
      </div>
      <div className={styles.rowContainer}>
        <p className={styles.classYearText}>{schoolName}</p>
        <p className={styles.address}>{schoolAddress}</p>
      </div>
      <ul className={styles.unorderList}>
        <li className={styles.startDate}>
          {moment(startDate).format("DD MMM YYYY")}
        </li>
        <li
          className={`${styles.jobType} ${
            jobType === "Long-term" ? styles.longItem : styles.emergency
          }`}
        >
          {jobType}
        </li>
        <li className={styles.recurrence}>{recurrence}</li>
      </ul>
    </div>
  );
}

const JobTitle = ({ title, ...otherProps }) => {
  return (
    <h4 className={styles.positionTitle} {...otherProps}>
      {title}
    </h4>
  );
};

export default HomeJobItem;
