import React from "react";

// components
import { CardWrapper } from "../../../components/details";
import { ContentItem } from "../../../../../../../../teacher/pages/dashboard/pages/jobs/components/ScheduleDetails";

function ContantDetail({ title, detail }) {
  const { email, phoneNumber } = detail;

  return (
    <CardWrapper title={title} style={{ padding: "2.4rem 1.2rem 1.6rem" }}>
      <ContentItem title="Email Address">{email}</ContentItem>
      <ContentItem title="Phone Number">{phoneNumber}</ContentItem>
    </CardWrapper>
  );
}

export default ContantDetail;
