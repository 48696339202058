import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// component
import AllJobListing from "./AllJobListing";
import JobDetailPage from "../JobDetailPage";
import ApplyJobPage from "../ApplyJobPage";

// services
import { getAllJobWithPagination } from "../../../../../../../services/teacherJobService";

// options
import { teacherAllJobStatusOptions } from "../../../../../../components/job-form-options";

function getNavLinkList(jobId) {
  const navLinkList = [
    {
      to: "/teacher/dashboard/jobs",
      tabName: "Jobs",
    },
    {
      to: `/teacher/dashboard/jobs/detail/${jobId}`,
      tabName: "Job Details",
    },
  ];

  return navLinkList;
}

function getAppliedNavLinkList(jobId) {
  const navLinkList = [
    {
      to: "/teacher/dashboard/jobs",
      tabName: "Jobs",
    },
    {
      to: `/teacher/dashboard/jobs/detail/${jobId}/`,
      tabName: "Job Details",
    },
    {
      to: `/teacher/dashboard/jobs/detail/${jobId}/apply`,
      tabName: "Job Application",
    },
  ];

  return navLinkList;
}

function AllJobs() {
  const navigate = useNavigate();
  const [jobId, setJobId] = useState("");

  const handleCellClick = (jobId) => {
    setJobId(jobId);
    navigate(`/teacher/dashboard/jobs/detail/${jobId}`);
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AllJobListing
            apiFunc={getAllJobWithPagination}
            onCellClick={handleCellClick}
            statusOptions={teacherAllJobStatusOptions}
          />
        }
      />
      <Route
        path="/detail/:id"
        element={
          <JobDetailPage
            navList={getNavLinkList(jobId)}
            activeTabName="active"
            activeTabTitle="Active Clients"
            setJobId={setJobId}
          />
        }
      />

      <Route
        path="/detail/:id/apply"
        element={
          <ApplyJobPage
            navList={getAppliedNavLinkList(jobId)}
            activeTabName="active"
            activeTabTitle="Active Clients"
          />
        }
      />
    </Routes>
  );
}

export default AllJobs;
