import React from "react";

import { Stack } from "@mui/system";

import {
  ListFilterDropdownModal,
  ShowMoreList,
} from "../../../../../../../../components/job-form";
import { ProfileImage } from "../../../../../../../components/TopBar";

// hooks
import useVisible from "../../../../../../../../hooks/useVisible";

import styles from "../jobListing.module.css";
import styles2 from "./styles.module.css";

const filterActionOptions = [
  {
    key: "Send Shift Request",
    value: "booked",
  },
  {
    key: "Reject Teacher Application",
    value: "rejected",
  },
];

function ApplicantItem({
  jobApplicant,
  onCellClick,
  onSendRequest,
  onSendReject,
}) {
  const [showFilterAction, toggleShowFilterAction] = useVisible();
  const {
    id: applicantId,
    teacherId: { id: teacherId, profileURL },
    fullName,
    phoneNumber,
    // email,
    // experienceClassYears,
    experienceExpertise,
    licenses,
    certificates,
    status,
    // notes,
    totalCompletedJob,
  } = jobApplicant;

  const handleFilterSelection = async (selectedOption) => {
    if (selectedOption === "booked") {
      await onSendRequest({ applicantId, teacherId });
    }

    if (selectedOption === "rejected") {
      await onSendReject({ applicantId, teacherId });
    }

    toggleShowFilterAction();
  };

  const bookedClass = status === "booked" ? "highlightRow" : "";
  return (
    <tr
      className={`${styles.itemContainer} ${styles.rowContainer} ${styles2.rowContainer} ${styles2[bookedClass]}`}
    >
      <td
        className={`${styles.rowColumnText} ${styles2.cursor}`}
        onClick={() => onCellClick(teacherId)}
      >
        <Stack
          display="flex"
          flexDirection="row"
          columnGap={1}
          alignItems="center"
        >
          {/* {profileURL && (
            <Avatar alt={fullName} src={http.imageBaseUrl + profileURL} />
          )} */}

          <ProfileImage imageUrl={profileURL} name={fullName} />
          <div>
            <p className={styles2.fullName}>{fullName}</p>
            <p className={styles2.contantNumber}>{phoneNumber}</p>
          </div>
        </Stack>
      </td>
      <td className={styles.rowColumnText}>
        <ShowMoreList list={experienceExpertise} />
      </td>
      <td className={styles.rowColumnText}>{totalCompletedJob}</td>
      <td className={styles.rowColumnText}>
        {licenses.concat(certificates).length ? (
          <ShowMoreList list={licenses.concat(certificates)} />
        ) : (
          "-"
        )}
      </td>
      <td className={`${styles.rowColumnText} ${styles2.filterAction}`}>
        <span onClick={toggleShowFilterAction}>...</span>
        {showFilterAction ? (
          <ListFilterDropdownModal
            showModal={showFilterAction}
            toggleShowModal={toggleShowFilterAction}
            value={status}
            options={filterActionOptions}
            onChange={handleFilterSelection}
          />
        ) : null}
      </td>
    </tr>
  );
}

export default ApplicantItem;
