import React from "react";

function ShowListWithComma({ list }) {
  return (
    <>
      {list.map((value, index) => (
        <span key={index}>
          {index ? ", " : ""} {value}
        </span>
      ))}
    </>
  );
}

export default ShowListWithComma;
