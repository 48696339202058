import React from "react";
import styles from "./styles.module.css";

function CertificateLicenseItem({ certificateLicense }) {
  const { documentNumber, documentTitle, documentType, expiryDate } =
    certificateLicense;

  return (
    <tr className={`${styles.itemContainer} ${styles.rowContainer} `}>
      <td className={styles.rowColumnText}>{documentTitle}</td>
      <td className={styles.rowColumnText}>{documentNumber}</td>
      <td className={styles.rowColumnText}>{documentType}</td>
      <td className={styles.rowColumnText}>{expiryDate}</td>
    </tr>
  );
}
export default CertificateLicenseItem;
