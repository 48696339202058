import React from "react";

import styles from "../jobListing.module.css";
import styles2 from "./styles.module.css";

function ApplicantListingHeader() {
  return (
    <tr
      className={`${styles.itemContainer}  ${styles.headerCellContainer} ${styles2.rowContainer}`}
    >
      <th className={styles.headerText}>Name & TRN</th>
      <th className={styles.headerText}>Expertise</th>
      <th className={styles.headerText}>Jobs Completed</th>
      <th className={styles.headerText}>Certificates</th>
      <th className={styles.headerText}>Action</th>
    </tr>
  );
}

export default ApplicantListingHeader;
