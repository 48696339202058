import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";

// components
import Card from "./Card";
import {
  Input,
  InputDate,
  Select,
  RadioButtonGroup,
  MultiSelect,
} from "../../components/job-form";
import { LabelWithInputField } from ".";
import { Title } from "../../components";
import { FormButton, MFormInputTime } from "../../components/Forms";

// styles
import styles from "./form.module.css";
import styles2 from "./newJob.module.css";

// job form options
import {
  categoryOptions,
  classYearOptions,
  expertiesOptions,
  jobTypeOptions,
  positionsOptions,
  recurrenceOptions,
  weekDaysOptions,
} from "./job-form-options";

// teacher registeration validationSchema
const validationSchema = Yup.object().shape({
  jobTitle: Yup.string().required().min(2).max(100).label("Job Title").trim(),
  jobType: Yup.string().required().min(2).label("Job Type").trim(),
  recurrence: Yup.string().required().min(2).label("Recurrence"),
  startDate: Yup.string().required().label("Start date"),
  endDate: Yup.string()
    .label("End date")
    .test("test", "End date is a required field ", function (value) {
      if (this.parent.recurrence && this.parent.recurrence === "Recurring") {
        return value ? true : false;
      }
      return true;
    }),
  time: Yup.date("Please select time").required().label("Time"),
  weekDays: Yup.array()
    .label("Days")
    .test("test", "Days is a required field ", function (value) {
      if (this.parent.recurrence && this.parent.recurrence === "Recurring") {
        return value.length ? true : false;
      }
      return true;
    }),
  category: Yup.string().required().label("Category"),
  classYear: Yup.string().required().label("Class Year"),
  position: Yup.string().required().label("Position"),
  expertise: Yup.string().required().label("Expertise"),
  description: Yup.string()
    .required()
    .min(2)
    .max(2000)
    .label("Description")
    .trim(),
  notes: Yup.string().min(2).max(2000).label("Notes").trim(),
});

export function CardTitle({ children, ...props }) {
  return (
    <h1 {...props} className={styles.formTitle}>
      {children}
    </h1>
  );
}

function JobForm({ onSubmit, onCancel, loading }) {
  const [recurrence, setRecurrence] = useState("Recurring");
  const [category, setCategory] = useState("Early Learning");

  return (
    <div>
      <Title>Create New Job</Title>
      <div className={`${styles.container} ${styles2.container}`}>
        <Formik
          initialValues={{
            jobTitle: "",
            jobType: "Emergency",
            recurrence: "Non-recurring",
            startDate: "",
            endDate: "",
            weekDays: [],
            time: null,
            category: "Early Learning",
            classYear: "",
            position: "",
            expertise: "",
            description: "",
            notes: "",
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          className={styles.formContainer}
        >
          {({ values, setFieldValue }) => (
            <>
              <Card>
                <div className={`${styles.formChildContainer}`}>
                  <CardTitle>Job Schedule Details</CardTitle>
                  <LabelWithInputField label="Job Title">
                    <Input name="jobTitle" label="Job Title" />
                  </LabelWithInputField>
                  <LabelWithInputField label="Job Type">
                    <div className={styles2.radioFlexContainer}>
                      <RadioButtonGroup
                        name="jobType"
                        options={jobTypeOptions}
                      />
                    </div>
                  </LabelWithInputField>
                  <LabelWithInputField label="Recurrence">
                    <div className={styles2.radioFlexContainer}>
                      <RadioButtonGroup
                        name="recurrence"
                        options={recurrenceOptions}
                        onChange={(value) => {
                          if (value !== recurrence) {
                            setRecurrence(value);
                            setFieldValue("startDate", "");
                            setFieldValue("endDate", "");
                            setFieldValue("weekDays", []);
                            setFieldValue("time", null);
                          }
                        }}
                      />
                    </div>
                  </LabelWithInputField>
                  {values.recurrence === "Recurring" ? (
                    <>
                      <LabelWithInputField label="Date">
                        <div className={styles2.flexContainer}>
                          <InputDate name="startDate" />
                          <span>to</span>
                          <InputDate name="endDate" />
                        </div>
                      </LabelWithInputField>
                      <LabelWithInputField label="Time & Days">
                        <div className={styles.gridContainer}>
                          <MFormInputTime name="time" placeholder="Time" />
                          <MultiSelect
                            name="weekDays"
                            label="Select days"
                            options={weekDaysOptions}
                          />
                        </div>
                      </LabelWithInputField>
                    </>
                  ) : (
                    <LabelWithInputField label="Date & Time">
                      <div className={styles.gridContainer}>
                        <InputDate name="startDate" />
                        <MFormInputTime name="time" placeholder="Time" />
                      </div>
                    </LabelWithInputField>
                  )}
                </div>
              </Card>
              <Card>
                <div className={`${styles.formChildContainer}`}>
                  <CardTitle>Experience & Expertise Details</CardTitle>
                  <LabelWithInputField label="Category">
                    <div className={styles2.gridContainerWithThreeCol}>
                      <RadioButtonGroup
                        name="category"
                        options={categoryOptions}
                        onChange={(value) => {
                          if (value !== category) {
                            setCategory(value);
                            setFieldValue("classYear", "");
                            setFieldValue("position", "");
                            setFieldValue("expertise", "");
                          }
                        }}
                      />
                    </div>
                  </LabelWithInputField>
                  <LabelWithInputField label="Class Year">
                    <Select
                      name="classYear"
                      label="Select"
                      options={classYearOptions[values.category]}
                    />
                  </LabelWithInputField>
                  <LabelWithInputField label="Position to Cover">
                    <div className={styles.gridContainer}>
                      <Select
                        name="position"
                        label="Select"
                        options={positionsOptions[values.category]}
                      />
                      <Select
                        name="expertise"
                        label="Expertise"
                        options={expertiesOptions[values.category]}
                      />
                    </div>
                  </LabelWithInputField>
                  <LabelWithInputField label="Description">
                    <Input
                      name="description"
                      label="Type a short description"
                    />
                  </LabelWithInputField>
                </div>
              </Card>
              <Card>
                <div className={`${styles.formChildContainer}`}>
                  <CardTitle>Additional Details</CardTitle>
                  <LabelWithInputField label="Notes">
                    <Input name="notes" label="Any notes about the job" />
                  </LabelWithInputField>
                </div>
              </Card>
              <div className={styles.gridContainer}>
                <FormButton
                  buttonText="Cancel"
                  type="button"
                  submitButton={false}
                  isBackground={false}
                  onClick={onCancel}
                />
                <FormButton
                  buttonText="Post Job"
                  type="submit"
                  submitButton={true}
                  isDisabled={true}
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default JobForm;
