import React from "react";
import moment from "moment";

// components
import { CardTitle } from "../../../../../../../components";
import { HomeCard } from "../../../../../../components/dashboard";

import styles from "./jobDetails.module.css";

function ScheduleDetails({ scheduleDetail }) {
  const {
    jobTitle,
    jobType,
    startDate,
    endDate,
    time,
    weekDays = [],
    recurrence,
  } = scheduleDetail;

  let dateAndTime;
  if (recurrence === "Recurring") {
    dateAndTime = `${moment(startDate).format("DD MMM YYYY ")}  to
    ${moment(endDate).format("DD MMM YYYY ")} at
    ${moment(time).format("  h:mm A")} (${weekDays.map((day) => day + " ")})`;
  } else {
    dateAndTime = `${moment(startDate).format("DD MMM YYYY")} at
    ${moment(time).format("h:mm A")}`;
  }

  return (
    <HomeCard>
      <CardTitle>Schedule Details</CardTitle>
      <ContentWrapper>
        <ContentItem title="Job Title">{jobTitle}</ContentItem>
        <ContentItem title="Date & Time">
          {dateAndTime && dateAndTime}
        </ContentItem>
        <ContentItem title="Job Type">{jobType}</ContentItem>
        <ContentItem title="Recurrence">{recurrence}</ContentItem>
      </ContentWrapper>
    </HomeCard>
  );
}

export default ScheduleDetails;

export const ContentItem = ({ title, children }) => {
  return (
    <ContentItemWrapper>
      <p className={styles.labelText}>{title}</p>
      <p className={styles.valueText}>{children}</p>
    </ContentItemWrapper>
  );
};

export const ContentItemWrapper = ({ children, ...props }) => {
  return (
    <div className={styles.contentItem} {...props}>
      {children}
    </div>
  );
};

export const ContentWrapper = ({ children, ...props }) => {
  return (
    <div className={styles.contentContainer} {...props}>
      {children}
    </div>
  );
};
