import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

// components
import { ErrorMessage, Loader, Title } from "../../../../../../components";
import {
  ApplyJobForm,
  ConfirmationModal,
  RedirectTabs,
} from "../../../../../components";
import { CardTitle } from "../../../../../components/JobForm";
import { JobDetail } from "./components";

// hooks
import useResponse from "../../../../../../hooks/useResponse";
import useVisible from "../../../../../../hooks/useVisible";

// services
import { getJobDetailsForTeacher } from "../../../../../../services/jobDetailsService";
import { applyForJob } from "../../../../../../services/jobApplicationService";

// selectors
import {
  getTeacherLicenses,
  getTeacherCertificates,
  getTeacherRegisterInfo,
} from "../../../../../../stores/teacherRegisterationReducer";

import * as toast from "../../../../../../Toast";

import styles from "./components/jobDetails.module.css";

function getOptionList(teacherdocuments = []) {
  const optionList = teacherdocuments.map((document, index) => ({
    key: document.documentTitle,
    value: `${index + 1}`,
    // value: document.documentTitle,
  }));

  return optionList;
}

function getSelectedOptionList(selectedOption = [], options = []) {
  const selectedOptionList = [];

  for (let option of options) {
    if (selectedOption.includes(option.value)) {
      selectedOptionList.push(option.key);
    }
  }

  return selectedOptionList;
}

function ApplyJobPage({ navList }) {
  const navigate = useNavigate();
  const [state, action] = useResponse();
  const [showModal, toggleModal] = useVisible();
  const [applyJobData, setApplyJobData] = useState(null);
  const params = useParams();
  const teacherLicenseList = useSelector(getTeacherLicenses);
  const teacherCertificateList = useSelector(getTeacherCertificates);
  const teacherInfo = useSelector(getTeacherRegisterInfo);
  const jobId = params.id;

  const onError = (error) => {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status <= 409
    ) {
      navigate("/not-found");
    }
  };

  const { isError, isLoading, data, error } = useQuery(
    ["race-edit", jobId],
    () => getJobDetailsForTeacher(jobId),
    { refetchOnWindowFocus: true, onError, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const job = data.data.data["job"];
  const { category, schoolId: school } = job;
  const schoolId = school.id;
  const { fullName, email, phoneNumber } = teacherInfo;

  // options
  const certificateOptionList = getOptionList(teacherCertificateList);
  const licenseOptionList = getOptionList(teacherLicenseList);

  const initialValue = {
    fullName,
    email,
    phoneNumber,
    experienceClassYears: [],
    experienceExpertise: [],
    licenses: [],
    certificates: [],
    notes: "",
  };

  const handleSubmit = (applyJobData) => {
    setApplyJobData(applyJobData);
    toggleModal();
  };

  const navigateToListing = () => {
    navigate("/teacher/dashboard/home");
  };

  const handleApplyJobConfirm = async () => {
    try {
      action.sendingRequst();

      const licenses = getSelectedOptionList(
        applyJobData?.licenses,
        licenseOptionList
      );
      const certificates = getSelectedOptionList(
        applyJobData?.certificates,
        certificateOptionList
      );

      const response = await applyForJob({
        ...applyJobData,
        licenses,
        certificates,
        jobId,
        schoolId,
      });
      const { message } = await response.data;

      toggleModal();
      navigateToListing();

      toast.success(message);
      action.successResponse(message);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        action.gotError(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <div>
      <ConfirmationModal
        subtitle="Please make sure you have entered all details correctly."
        ConfirmButtonText="Apply to Job"
        CancelButtonText="Continue Editing"
        showModal={showModal}
        onConfirm={handleApplyJobConfirm}
        toggleModal={toggleModal}
        loading={state.loading}
      />
      <RedirectTabs redirectList={navList} />
      <Title>Job Application</Title>
      <div className={styles.mainContainer}>
        <div className={styles.jobDetailsContainer}>
          <CardTitle>Enter your details to apply to the job opening</CardTitle>
          <div>
            <ApplyJobForm
              category={category}
              certificateOptionList={certificateOptionList}
              licenseOptionList={licenseOptionList}
              initialValue={initialValue}
              onSubmit={handleSubmit}
              onCancel={navigateToListing}
            />
          </div>
        </div>
        <div className={styles.schoolDetailsContainer}>
          <div className={styles.jobDetailsContainer}>
            <CardTitle>Job Details</CardTitle>
            <div>
              <JobDetail job={job} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplyJobPage;
