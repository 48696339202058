import React from "react";

// components
import { NoRecordFound } from "../../../../../../../../../components";
import CertificateLicenseHeader from "./CertificateLicenseHeader";
import CertificateLicenseItem from "./CertificateLicenseItem";

import styles from "./styles.module.css";

function CertificateLicenseTable({ certificateLicenseList = [] }) {
  // displaying the certificate license list
  let listings;
  if (certificateLicenseList && certificateLicenseList.length > 0) {
    listings = (
      <tbody>
        {certificateLicenseList.map((certificateLicense, index) => (
          <CertificateLicenseItem
            certificateLicense={certificateLicense}
            key={index + 1}
          />
        ))}
      </tbody>
    );
  } else {
    listings = (
      <tbody>
        <tr>
          <td>
            <NoRecordFound />
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <table className={styles.listingContainer}>
      <thead className={styles.headerContainer}>
        <CertificateLicenseHeader />
      </thead>
      {listings && listings}
    </table>
  );
}

export default CertificateLicenseTable;
