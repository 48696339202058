import React from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// components
import Card from "./Card";
import { Form, FormInputPassword, FormButton } from "../../components/Forms";

// common validator
import { whitespaceValidation, passwordValidation } from "../validator/common";

import styles from "./form.module.css";

// @reference https://stackoverflow.com/questions/12090077/javascript-regular-expression-password-validation-having-special-characters
// reset password validationSchema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required()
    .min(5)
    .max(30)
    .trim()
    .test(
      "type",
      "Password must not contain Whitespaces.",
      whitespaceValidation
    )
    .test(
      "type",
      "Password must contain at least one Special Character(Special characters @ # $ ! allowed).",
      passwordValidation
    )
    .label("Password"),
  cPassword: Yup.string()
    .required()
    .min(5)
    .max(30)
    .label("Confirm Password")
    .oneOf([Yup.ref("password")], "Your passwords do not match.")
    .trim(),
});

function ResetPassForm({ title, path, onSubmit, error, loading }) {
  return (
    <div className={styles.container}>
      <h1 className={styles.titleHeading}>{title}</h1>
      <Form
        initialValues={{
          password: "",
          cPassword: "",
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        className={styles.formContainer}
      >
        <Card>
          <div className={styles.formChildContainer}>
            <p className={styles.formSubtitle}>
              Set new password for your StaffView account.
            </p>
            <FormInputPassword
              label="Password"
              name="password"
              type="password"
            />
            <FormInputPassword
              label="Confirm Password"
              name="cPassword"
              type="password"
            />
            <Link to={path} className={styles.link}>
              Back to Login
            </Link>
          </div>
          {/* {error && <p className="error">{error}</p>} */}
        </Card>
        <div className={styles.formContainer} style={{ marginTop: "5rem" }}>
          <FormButton
            buttonText="Reset Password"
            type="submit"
            submitButton={true}
            loading={loading}
          />
        </div>
      </Form>
    </div>
  );
}

export default ResetPassForm;
