import React from "react";
import styles from "./header.module.css";

function LabelWithInputField({ label, children }) {
  return (
    <div className={`${styles.headerContainer} ${styles.containerPadding}`}>
      <div className={styles.labelContainer}>
        <h3 className={styles.label}>{label}</h3>
      </div>
      <div className={styles.formFieldContainer}>{children}</div>
    </div>
  );
}

export default LabelWithInputField;
