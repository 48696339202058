import React from "react";
import { useFormikContext } from "formik";
import DotLoader from "../DotLoader";
import styles from "./formButton.module.css";

function FormButton({
  buttonText,
  onClick,
  size = 1.6,
  isBackground = true,
  submitButton = false,
  isDisabled = false,
  borderRadius = 8,
  loading = false,
  ...otherProps
}) {
  const { handleSubmit, isValid, dirty } = useFormikContext();

  let isSubmit = true;
  if (isDisabled) {
    isSubmit = submitButton ? dirty && isValid : true;
  }

  const handleClick = (e) => {
    if (submitButton) {
      handleSubmit(e);
    } else {
      onClick();
    }
  };

  return (
    <button
      style={{ fontSize: `${size}rem`, borderRadius: `${borderRadius}px` }}
      className={`${styles.button} ${
        isBackground ? styles.withBackground : styles.noBackground
      }`}
      onClick={handleClick}
      {...otherProps}
      disabled={loading || !isSubmit}
    >
      {loading ? <DotLoader /> : buttonText}
    </button>
  );
}

export default FormButton;
