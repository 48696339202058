import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
// components
import Layout from "../../../../components/Layout";
import ResetPassForm from "../../../components/ResetPassForm";

// hooks
import useResponse from "../../../../hooks/useResponse";

// image
import WelcomeImage from "../../../../assets/images/t-login.png";

// services
import * as teacherAuthService from "../../../../services/teacherAuthService";
import * as toast from "../../../../Toast";

function ResetPassword() {
  const [state, action] = useResponse();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const user = teacherAuthService.validateToken(token);
    if (!user) {
      navigate("not-found");
    }
  }, [token, navigate]);

  const handleSubmit = async (loginData, formkiObject) => {
    action.sendingRequst();
    try {
      const response = await teacherAuthService.teacherResetPassword(
        {
          password: loginData.password,
        },
        token
      );
      toast.success(response.data.message);
      window.location = "/teacher/";
      formkiObject.resetForm();
      action.successResponse(response.data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        action.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <Layout imageUrl={WelcomeImage}>
      <ResetPassForm
        title="Reset Password"
        path="/teacher/login"
        onSubmit={handleSubmit}
        error={state.error}
        loading={state.loading}
      />
    </Layout>
  );
}

export default ResetPassword;
