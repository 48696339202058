import React from "react";
import { useNavigate } from "react-router-dom";
// components
import Layout from "../../../../components/Layout";
import LoginForm from "../../../components/LoginForm";

// hooks
import useResponse from "../../../../hooks/useResponse";

// image
import WelcomeImage from "../../../../assets/images/t-login.png";

// services
import * as teacherAuthService from "../../../../services/teacherAuthService";
import * as auth from "../../../../services/authService";
import * as toast from "../../../../Toast";

function TeacherLogin() {
  const navigator = useNavigate();
  const [state, action] = useResponse();

  const navigateToTeacherRegistation = () => {
    navigator("/teacher/register-step-first");
  };

  const handleSubmit = async (loginData, formkiObject) => {
    action.sendingRequst();
    // triming object
    try {
      const response = await teacherAuthService.teacherLogin(loginData);
      const { data } = await response.data;

      // setting auth token for login user
      teacherAuthService.setJWT(data.token);
      auth.setJWT(data.token);

      window.location = "/teacher/dashboard/";
      // formkiObject.resetForm();
      toast.success("Successfully logged in.");
      action.successResponse(response.data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        action.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <Layout imageUrl={WelcomeImage} showTermPrivary>
      <LoginForm
        title="Hi There!"
        path="/teacher/forgot-password"
        onRegister={navigateToTeacherRegistation}
        onSubmit={handleSubmit}
        error={state.error}
        loading={state.loading}
      />
    </Layout>
  );
}

export default TeacherLogin;
