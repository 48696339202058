import React from "react";

// components
import { CardTitle } from "../../../../../../../components";
import { HomeCard } from "../../../../../../components/dashboard";
import { ContentWrapper } from "./ScheduleDetails";

import schoolImage from "../../../../../../../assets/images/schoolImage.png";

import styles from "./jobDetails.module.css";

function SchoolDetails({ school }) {
  const {
    schoolName,
    schoolAddress,
    profileURL,
    // state,
    // suburbName,
    schoolDescription,
  } = school;
  return (
    <HomeCard>
      <CardTitle>School Details</CardTitle>
      <ContentWrapper>
        {profileURL && (
          <img className={styles.schoolImage} src={schoolImage} alt="" />
        )}
        <CardTitle>{schoolName}</CardTitle>
        <p className={styles.schoolLocation}>{schoolAddress}</p>
        <p className={styles.labelText}>{schoolDescription}</p>
      </ContentWrapper>
    </HomeCard>
  );
}

export default SchoolDetails;
