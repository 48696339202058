import React from "react";
import { Routes, Route } from "react-router-dom";

// pages
import AllTeacher from "./AllTeacher/AllTeacher";
import FavTeacher from "./FavTeacher/FavTeacher";

function SchoolDashboardMyTeachers() {
  return (
    <Routes>
      <Route path="/*" element={<AllTeacher />} />
      <Route path="/fav-teachers/*" element={<FavTeacher />} />
    </Routes>
  );
}

export default SchoolDashboardMyTeachers;
