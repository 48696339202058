const jobTypeOptions = [
  { key: "Emergency", value: "Emergency" },
  { key: "Long-term", value: "Long-term" },
];

const jobStatusOptions = [
  { key: "Open", value: "open" },
  { key: "Booked", value: "booked" },
  { key: "Cancelled", value: "cancelled" },
  { key: "Completed", value: "completed" },
];

const recurrenceOptions = [
  { key: "Recurring", value: "Recurring" },
  { key: "Non-recurring", value: "Non-recurring" },
];

const categoryOptions = [
  { key: "Early Learning", value: "Early Learning" },
  { key: "Primary School", value: "Primary School" },
  { key: "High School", value: "High School" },
];

const weekDaysOptions = [
  { key: "Monday", value: "Monday" },
  { key: "Tuesday", value: "Tuesday" },
  { key: "Wednesday", value: "Wednesday" },
  { key: "Thursday", value: "Thursday" },
  { key: "Friday", value: "Friday" },
];

// Class Years
// - Early Learning
// ==> Childcare
// ==> Preschool

// - Primary School
// ==> Junior Primary
// ==> Middle primary
// ==> Upper Primary
// ==> Specialist Teacher
// ==> Various Release

// - High School
// ==> High School

const classYearOptions = {
  "Early Learning": [
    { key: "Childcare", value: "Childcare" },
    { key: "Preschool", value: "Preschool" },
  ],
  "Primary School": [
    { key: "Junior Primary", value: "Junior Primary" },
    { key: "Middle primary", value: "Middle primary" },
    { key: "Upper Primary", value: "Upper Primary" },
    { key: "Specialist Teacher", value: "Specialist Teacher" },
    { key: "Various Release", value: "Various Release" },
  ],
  "High School": [{ key: "High School", value: "High School" }],
};

// Positions to Cover
// - Early Learning
// ==> Classroom Teacher
// ==> Qualified Staff
// ==> Support staff
// ==> Other
// ==> Director

// Primary/HighSchool
// ==> Classroom Teacher
// ==> Classroom Support
// ==> Special Event Staff
// ==> Leadership
// ==> Clerical
// ==> Other

const primaryAndHighSchoolPositionOptions = [
  { key: "Classroom Teacher", value: "Classroom Teacher" },
  { key: "Classroom Support", value: "Classroom Support" },
  { key: "Special Event Staff", value: "Special Event Staff" },
  { key: "Leadership", value: "Leadership" },
  { key: "Clerical", value: "Clerical" },
  // { key: "Other", value: "Other" },
];

const positionsOptions = {
  "Early Learning": [
    { key: "Classroom Teacher", value: "Classroom Teacher" },
    { key: "Qualified Staff", value: "Qualified Staff" },
    { key: "Support staff", value: "Support staff" },
    // { key: "Other", value: "Other" },
    { key: "Director", value: "Director" },
  ],
  "Primary School": primaryAndHighSchoolPositionOptions,
  "High School": primaryAndHighSchoolPositionOptions,
};

// Expertise
// - Early Years
// *Degree
// *Certificate
// *Diploma
// *Other

// -Primary school
// *Behaviour management
// *Special needs
// *PE
// *Arts and Music
// *Technology
// *Language
// *Kitchen/garden
// *Other

// High school
// (It was suggested we use faculties and there might be more to add as I need high school input)
// *Mathematics
// *Science
// *Design, Tech and Engineering
// *Languages
// *HASS
// *health and PE
// *Home economics
// *The Arts
// *Other

const expertiesOptions = {
  "Early Learning": [
    { key: "Degree", value: "Degree" },
    { key: "Certificate", value: "Certificate" },
    { key: "Diploma", value: "Diploma" },
    // { key: "Other", value: "Other" },
  ],
  "Primary School": [
    { key: "Behaviour management", value: "Behaviour management" },
    { key: "Special needs", value: "Special needs" },
    { key: "PE", value: "PE" },
    { key: "Arts and Music", value: "Arts and Music" },
    { key: "Technology", value: "Technology" },
    { key: "Kitchen/garden", value: "Kitchen/garden" },
    // { key: "Other", value: "Other" },
  ],
  "High School": [
    { key: "Mathematics", value: "Mathematics" },
    { key: "Science", value: "Science" },
    {
      key: "Design, Tech and Engineering",
      value: "Design, Tech and Engineering",
    },
    { key: "Languages", value: "Languages" },
    { key: "HASS", value: "HASS" },
    { key: "health and PE", value: "health and PE" },
    { key: "Home economics", value: "Home economics" },
    { key: "The Arts", value: "The Arts" },
    // { key: "Other", value: "Other" },
  ],
};

// const subjectOptions = [
//   { key: "Mathematics", value: "Mathematics" },
//   { key: "English", value: "English" },
//   { key: "Chemistry", value: "Chemistry" },
//   { key: "Sciences", value: "Sciences" },
// ];

// teacher Jobs
const teacherAllJobStatusOptions = [
  { key: "Open", value: "open" },
  { key: "Booked", value: "booked" },
  { key: "Completed", value: "completed" },
];

const teacherAppliedJobStatusOptions = [{ key: "Open", value: "open" }];

const teacherBookedJobStatusOptions = [
  { key: "Booked", value: "booked" },
  { key: "Completed", value: "completed" },
];

export {
  // subjectOptions,
  categoryOptions,
  classYearOptions,
  expertiesOptions,
  jobTypeOptions,
  jobStatusOptions,
  positionsOptions,
  recurrenceOptions,
  weekDaysOptions,

  // teacher job options
  teacherAllJobStatusOptions,
  teacherAppliedJobStatusOptions,
  teacherBookedJobStatusOptions,
};
