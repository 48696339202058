import React from "react";
import { Box, Stack } from "@mui/material";
import { NoRecordFound } from "../../../../../../../../../components";
import Testimonial from "./Testimonial";

function TestimonialList({ testimonials }) {
  let children;
  if (testimonials && testimonials.length > 0) {
    children = (
      <>
        {/* <Stack
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          flexWrap="wrap"
          rowGap={2}
          columnGap={1}
        > */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "1.2rem",
            width: "100%",
          }}
        >
          {testimonials.map((testimonial) => (
            <>
              <Testimonial testimonial={testimonial} />
            </>
          ))}
        </Box>
        {/* </Stack> */}
      </>
    );
  } else {
    children = <NoRecordFound />;
  }

  return children;
}

export default TestimonialList;
