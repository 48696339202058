import React from "react";

// components
import { Title, CardTitle } from ".";

function HeaderTitleSubtitle({ title, subtitle, note }) {
  return (
    <>
      {title && <Title>{title}</Title>}
      {subtitle && (
        <CardTitle style={{ textAlign: "center" }}>{subtitle}</CardTitle>
      )}
      {note && (
        <Title>
          <span className="w-600">Note : </span>
          {note}
        </Title>
      )}
    </>
  );
}

export default HeaderTitleSubtitle;
