import http from "./httpService";

const apiEndpoint = http.apiUrl + "teacher-job";

const constructionURL = (API_URL_ROUTE, { page, limit, filter = {} }) => {
  const url = new URL(apiEndpoint + API_URL_ROUTE);

  url.searchParams.set("page", page);
  url.searchParams.set("limit", limit);

  if (filter.jobType) {
    url.searchParams.set("jobType", filter.jobType);
  }

  if (filter.category) {
    url.searchParams.set("category", filter.category);
  }

  if (filter.status) {
    url.searchParams.set("status", filter.status);
  }

  return url;
};

// service is used to get all applied jobs with pagination
export function getAllJobWithPagination({ page = 1, limit = 10, filter = {} }) {
  const url = constructionURL("/all-job", { page, limit, filter });
  return http.get(url);
}

// service is used to get all applied jobs with pagination
export function getAllAppliedJobWithPagination({
  page = 1,
  limit = 10,
  filter = {},
}) {
  const url = constructionURL("/applied-job", { page, limit, filter });
  return http.get(url);
}

// service is used to get all booked jobs with pagination
export function getAllBookedJobWithPagination({
  page = 1,
  limit = 10,
  filter = {},
}) {
  const url = constructionURL("/booked-job", { page, limit, filter });
  return http.get(url);
}
