import React from "react";
// components
import Layout from "../../../../components/Layout";
import ForgotPassForm from "../../../components/ForgotPassForm";

// hooks
import useResponse from "../../../../hooks/useResponse";

// image
import WelcomeImage from "../../../../assets/images/t-login.png";

// services
import * as schoolAuthService from "../../../../services/schoolAuthService";
import * as toast from "../../../../Toast";

function ForgotPassword() {
  const [state, action] = useResponse();

  const handleSubmit = async (loginData, formkiObject) => {
    action.sendingRequst();
    try {
      const response = await schoolAuthService.schoolForgotPassword(loginData);
      formkiObject.resetForm();
      toast.info(response.data.message);
      action.successResponse(response.data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        action.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <Layout imageUrl={WelcomeImage}>
      <ForgotPassForm
        title="Forgot Password"
        path="/school/login"
        onSubmit={handleSubmit}
        error={state.error}
        loading={state.loading}
      />
    </Layout>
  );
}

export default ForgotPassword;
