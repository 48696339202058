import { styled } from "@mui/material/styles";
import Button from "../../components/Button";

export const ProfilePhoto = styled("img")`
  width: 200px;
  height: 200px;
  border-radius: 8px;
`;

export const UploadPhotoContainer = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const DragPhotoButton = styled(Button)`
  border: none;
`;

export const ProfilePhotoInput = styled("input")`
  display: block !important;
  border: none;
  outline: none;
  width: 100%;
  padding: 1.5rem 2rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  border-radius: 8px;

  text-align: center;
  background-color: ${(props) =>
    props.primary ? "var(--primary-full)" : "transparent"};

  color: ${(props) => (props.primary ? "#fff" : "var(--primary-full)")};

  border: 2px solid
    ${(props) => (props.primary ? "transparent" : "var(--primary-full)")};

  opacity: 1;

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
