import React from "react";

// components
import { ModalWrapper } from ".";
import { ConfirmButton } from "../../components/Forms";

import styles from "./form.module.css";

function ConfirmationModal({
  showModal,
  toggleModal,
  onConfirm,
  title,
  subtitle,
  note,
  error,
  loading = false,
  hideCancel = false,
  CancelButtonText = "Cancel",
  ConfirmButtonText = "Confirm",
}) {
  if (!showModal) return null;

  return (
    <ModalWrapper
      toggleModal={toggleModal}
      title={title}
      subtitle={subtitle}
      note={note}
      error={error}
    >
      {hideCancel === true ? (
        <ConfirmButton
          size={1.6}
          buttonText={ConfirmButtonText}
          type="button"
          loading={loading}
          disabled={loading}
          onClick={onConfirm}
        />
      ) : (
        <CancelAndConfirmButton
          CancelButtonText={CancelButtonText}
          ConfirmButtonText={ConfirmButtonText}
          loading={loading}
          onCancel={toggleModal}
          onConfirm={onConfirm}
        />
      )}
    </ModalWrapper>
  );
}

export default ConfirmationModal;

const CancelAndConfirmButton = ({
  ConfirmButtonText,
  CancelButtonText,
  onCancel,
  onConfirm,
  loading,
}) => {
  return (
    <div className={styles.gridContainer}>
      <ConfirmButton
        size={1.6}
        buttonText={CancelButtonText}
        type="button"
        isBackground={false}
        onClick={onCancel}
      />
      <ConfirmButton
        size={1.6}
        buttonText={ConfirmButtonText}
        type="button"
        loading={loading}
        disabled={loading}
        onClick={onConfirm}
      />
    </div>
  );
};
