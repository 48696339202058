import React from "react";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { CardTitle } from "../../../../../../../components";
import { HomeCard } from "../../../../../../components/dashboard";
import { Link, useLocation } from "react-router-dom";

import * as teacherAuthService from "../../../../../../../services/teacherAuthService";
import * as authService from "../../../../../../../services/authService";
import { toast } from "react-toastify";
import { ConfirmationModal } from "../../../../../../components";
import useVisible from "../../../../../../../hooks/useVisible";

const data = [
  {
    name: "Edit Profile",
    link: "/teacher/dashboard/settings/edit-profile",
    pathname: "/teacher/dashboard/settings/edit-profile",
  },
  {
    name: "Certificates & Licenses",
    link: "/teacher/dashboard/settings/certificates-licenses",
    pathname: "/teacher/dashboard/settings/certificates-licenses",
  },
  {
    name: "Payment Method",
    link: "/teacher/dashboard/settings/payment-method",
    pathname: "/teacher/dashboard/settings/payment-method",
  },
  {
    name: "Account Settings",
    link: "/teacher/dashboard/settings/account-settings",
    pathname: "/teacher/dashboard/settings/account-settings",
  },
  {
    name: "About",
    link: "/teacher/dashboard/settings/about",
    pathname: "/teacher/dashboard/settings/about",
  },
];

const SidebarContainer = styled("div")`
  /* padding: 24px 16px; */
`;

const SidebarList = styled(Stack)`
  gap: 1rem;
  padding-top: 2rem;
`;

const SidebarLink = styled(Link)`
  letter-spacing: 0.25px;
  text-decoration: none;
  font-size: 1.5rem;

  :hover {
    color: var(--primary-full);
  }

  color: ${(props) =>
    props.active === "true" ? "var(--primary-full)" : "var(--text-secondary)"};
`;

const LogoutBtn = styled("button")`
  outline: none;
  border: none;
  background-color: transparent;
  text-align: left;
  letter-spacing: 0.25px;
  text-decoration: none;
  font-size: 1.5rem;

  cursor: pointer;

  /* :hover {
    color: var(--primary-full);
  } */

  color: ${(props) =>
    props.active === "true" ? "var(--primary-full)" : "var(--text-secondary)"};
`;

const Sidebar = () => {
  let location = useLocation();

  const [isLogoutModalVisible, toggleLogoutModal] = useVisible();

  // console.log("Environment : ", process.env.REACT_APP_DEV_MODE);
  // console.log("Public key : ", process.env.React_STRIPE_PUBLISHABLE_KEY);

  const handleLogout = () => {
    teacherAuthService.logout();
    authService.logout();
    toast.success("Successfully logged out!");
    window.location.reload();
  };

  return (
    <SidebarContainer>
      <HomeCard>
        <CardTitle>General Settings</CardTitle>
        <ConfirmationModal
          subtitle="Are you sure you want to sign out?"
          ConfirmButtonText="Sign Out"
          CancelButtonText="Cancel"
          showModal={isLogoutModalVisible}
          onConfirm={handleLogout}
          toggleModal={toggleLogoutModal}
        />
        <SidebarList spacing={1}>
          {data.map((item, index) => (
            <SidebarLink
              active={item.pathname === location.pathname ? "true" : "false"}
              to={item.link}
              key={index}
            >
              {item.name}
            </SidebarLink>
          ))}
          <LogoutBtn onClick={toggleLogoutModal}>Sign Out</LogoutBtn>
        </SidebarList>
      </HomeCard>
    </SidebarContainer>
  );
};

export default Sidebar;
