import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// pages
import TeacherProfile from "../TeacherProfile/TeacherProfile";
import FavTeacherListing from "./components/FavTeacherListing";

function getNavLinkList(teacherId) {
  const navLinkList = [
    {
      to: "/school/dashboard/my-teachers",
      tabName: "All Teachers",
    },
    {
      to: `/school/dashboard/my-teachers/fav-teachers`,
      tabName: "Favourite Teachers",
    },
    {
      to: `/school/dashboard/my-teachers/fav-teachers/profile/${teacherId}`,
      tabName: "Teacher’s Profile",
    },
  ];

  return navLinkList;
}

function FavTeacher() {
  const navigate = useNavigate();
  const [teacherId, setTeacherId] = useState("");

  const handleCellClick = (teacherId) => {
    setTeacherId(teacherId);
    navigate(`/school/dashboard/my-teachers/fav-teachers/profile/${teacherId}`);
  };

  return (
    <Routes>
      <Route
        path="/"
        element={<FavTeacherListing onCellClick={handleCellClick} />}
      />
      <Route
        path="/profile/:id"
        element={<TeacherProfile navList={getNavLinkList(teacherId)} />}
      />
    </Routes>
  );
}

export default FavTeacher;
