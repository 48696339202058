import React from "react";
import { Field } from "formik";

// components
import { FormErrorMessage } from "../Forms";

import styles from "./radioButton.module.css";

function RadioButton({ option, field, onChange, ...otherProps }) {
  const handleChange = (event) => {
    field.onChange(event);
    onChange(event.target.value);
  };

  return (
    <div className={styles.group}>
      <input
        className={styles.radioInput}
        type="radio"
        id={option.value}
        {...field}
        {...otherProps}
        onChange={handleChange}
        value={option.value}
        checked={field.value === option.value}
      />
      <label htmlFor={option.value} className={styles.label}>
        {option.key}
      </label>
    </div>
  );
}

function RadioButtonGroup(props) {
  const { label, name, options, onChange, ...rest } = props;
  return (
    <>
      {label && <label className="text-normal">{label}</label>}
      <Field name={name}>
        {({ field }) => {
          return options.map((option) => (
            <RadioButton
              key={option.key}
              field={field}
              option={option}
              onChange={onChange}
              {...rest}
            />
          ));
        }}
      </Field>
      <FormErrorMessage name={name} />
    </>
  );
}

export default RadioButtonGroup;
