import moment from "moment";
import React from "react";
import { ShowListWithComma } from "../../../../../../../../components/job-form";
import styles from "./allJobListing.module.css";

const statusObject = {
  open: "Pending",
  cancelled: "Cancelled",
  booked: "Booked",
  completed: "Completed",
};

function AllJobItem({ job = {}, onCellClick }) {
  const {
    classYear,
    endDate,
    id,
    schoolId: {
      // id: schoolId,
      schoolName,
      state,
      suburbName,
    },
    jobTitle,
    jobType,
    recurrence,
    startDate,
    status,
    time,
    weekDays = [],
  } = job;
  return (
    <tr
      className={`${styles.itemContainer} ${styles.rowContainer}`}
      onClick={() => onCellClick(id)}
    >
      <td className={`${styles.rowColumnText} ${styles.dateTimeColumn}`}>
        {recurrence === "Recurring" ? (
          <>
            <p className={styles.rowColumnText}>
              {moment(startDate).format("DD MMM YYYY")} -{" "}
              {moment(endDate).format("DD MMM YYYY")}
            </p>
            <p className={styles.rowColumnText}>
              at {moment(time).format("h:mm A")}
              ( <ShowListWithComma list={weekDays} />)
            </p>
          </>
        ) : (
          <>
            <p className={styles.rowColumnText}>
              {moment(startDate).format("DD MMM YYYY")}
            </p>
            <p className={styles.rowColumnText}>
              {moment(time).format("h:mm A")}
            </p>
          </>
        )}
      </td>
      <td className={`${styles.rowColumnText} ${styles.schoolLocation}`}>
        {jobTitle}
      </td>
      <td className={styles.rowColumnText}>{classYear}</td>
      <td className={`${styles.rowColumnText} ${styles.schoolLocation}`}>
        <p className={styles.rowColumnText}>{schoolName}</p>
        <p className={styles.rowColumnText}>
          {suburbName}, {state}
        </p>
      </td>
      <td className={styles.rowColumnText}>{jobType}</td>
      <td className={`${styles.rowColumnText} ${styles[status]}`}>
        {statusObject[status]}
      </td>
    </tr>
  );
}

export default AllJobItem;
