export const setRegistrationData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getRegistrationData = (key) => {
  const data = localStorage.getItem(key);
  return JSON.parse(data);
};

export const clearRegistrationData = (key) => {
  localStorage.removeItem(key);
};
