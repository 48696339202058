import "./App.css";
import { Routes, Route } from "react-router-dom";

// pages
import Home from "./pages/Home";
import School from "./pages/school";
import Teacher from "./pages/teacher";
import PageNotFound from "./pages/notFound";

// guest & protected route
import { GuestRoute } from "./components";

// toast notifaction
import ToastNotificationContainer from "./Toast";

// full calender view
/**
 * @reference https://reactjsexample.com/an-open-source-fullcalendar-scheduler-using-react/
 */

function App() {
  return (
    <div className="app">
      <ToastNotificationContainer />
      <Routes>
        <Route
          path="/"
          element={
            <GuestRoute>
              <Home />
            </GuestRoute>
          }
        />
        <Route path="/school/*" element={<School />} />
        <Route path="/teacher/*" element={<Teacher />} />

        {/* page not found route */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
