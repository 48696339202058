import React from "react";
import Grid from "@mui/material/Grid";
import { Navigate, Route, Routes } from "react-router-dom";
import { Title } from "../../../../../../components";
import { Stack } from "@mui/material";
import Sidebar from "./components/Sidebar";

import EditProfile from "../settings/pages/EditProfile/EditProfile";
import CertificatesLicenses from "../settings/pages/CertificatesLicenses/CertificatesLicenses";
import AccountSettings from "../settings/pages/AccountSettings/AccountSettings";
import About from "../settings/pages/About/About";
import PaymentMethod from "./pages/PaymentMethod/PaymentMethod";

// import {
//   About,
//   AccountSettings,
//   CertificatesLicenses,
//   EditProfile,
// } from "./pages";

function TeacherDashboardSettings() {
  return (
    <Stack>
      <Title>Settings</Title>
      <Grid container spacing={2} columns={12}>
        <Grid item xs={3}>
          <Sidebar />
        </Grid>
        <Grid item xs={9}>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate to="/teacher/dashboard/settings/edit-profile" />
              }
            />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route
              path="/certificates-licenses"
              element={<CertificatesLicenses />}
            />
            <Route path="/payment-method" element={<PaymentMethod />} />
            <Route path="/account-settings" element={<AccountSettings />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default TeacherDashboardSettings;
