import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Grid, Stack } from "@mui/material";

// components
import { HomeCard } from "../../../../../../components/dashboard";
import { Loader } from "../../../../../../../components";

// services
import { getRecentlyPostedJobs } from "../../../../../../../services/schoolHomeService";

// constants
import { JOB_STATUS } from "../../../../../../../constants/job";

// styles
import styles from "../home.module.css";
import moment from "moment";

const CardTitle = styled("h2")`
  font-size: 2rem;
  font-weight: 500;
  color: #181c29;
`;

const Card = styled(Link)`
  border: 1px solid #eee9e8;
  padding: 12px 16px 12px;
  border-radius: 8px;
  display: block;
  text-decoration: none;
  cursor: pointer;
`;

const SchoolJobTitle = styled("p")`
  font-size: 1.6rem;
  line-height: 24px;
  color: #181c29;
`;

const Flex = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
  /* justify-content: flex-start; */
  margin-bottom: 0.5rem;
`;

const SchoolYear = styled("p")`
  font-size: 1.4rem;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #565b64;
`;

const ApplicantNum = styled("p")`
  font-size: 1.3rem;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #181c29;
`;

const SchoolDate = styled("p")`
  color: #565b64;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 16px;
`;

const Dot = styled("span")`
  width: 6px;
  height: 6px;
  background: #99a0ac;
  border-radius: 50%;
`;

const Tag = styled("span")`
  padding: 4px 8px;
  background: #f8f5f1;
  border-radius: 12px;
  font-size: 1rem;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 500;

  color: ${(props) => {
    if (props.variant === "applied") {
      return "#4A7D9A";
    }

    if (props.variant === "booked") {
      return "#6C6A94";
    }

    if (props.variant === "assigned") {
      return "#50AB7F";
    }

    if (props.variant === "open") {
      return "#50AB7F";
    }

    if (props.variant === "cancelled") {
      return "#A04D47";
    }
    return "#4A7D9A";
  }};
`;

function RecentlyPostedJobs() {
  const { isError, isLoading, data } = useQuery(
    ["recently-posted-jobs"],
    () => getRecentlyPostedJobs(),
    { refetchOnWindowFocus: true, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <p>Something went Wrong</p>;
  }

  const postedJobs = data.data["jobs"] ?? [];

  return (
    <HomeCard className={styles.gridThirdchild}>
      <CardTitle
        style={{
          marginBottom: "1rem",
        }}
      >
        Recently Posted Jobs
      </CardTitle>

      <Grid
        sx={{
          height: "100%",
          paddingBottom: "2rem",
        }}
        container
        spacing={2}
        columns={12}
      >
        {postedJobs.map((postedJob) => (
          <Grid item xs={12} md={6} lg={4} key={postedJob.id}>
            <Card to={`/school/dashboard/job/details/${postedJob.id}`}>
              <Flex>
                <SchoolJobTitle>{postedJob.jobTitle}</SchoolJobTitle>
                <SchoolYear>{postedJob.classYear}</SchoolYear>
              </Flex>
              {/* <Flex>
                <SchoolName>{postedJob.school.schoolName}</SchoolName>
                <SchoolLocation>
                  {postedJob.school.suburbName}, {postedJob.school.state}
                </SchoolLocation>
              </Flex> */}
              <Flex>
                <SchoolDate>
                  {moment(postedJob.startDate).format("D MMM YYYY")}
                </SchoolDate>
                <Dot />
                <SchoolDate>{postedJob.jobType}</SchoolDate>
              </Flex>

              <Flex>
                <ApplicantNum>
                  {postedJob.applicants.length} Applicants
                </ApplicantNum>

                <Tag variant={postedJob.status}>
                  {JOB_STATUS[postedJob.status]}
                </Tag>
              </Flex>
            </Card>
          </Grid>
        ))}

        {postedJobs.length === 0 ? (
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <p
              style={{
                letterSpacing: "0.1px",
                color: "var(--text-tertiary)",
                fontSize: "17px",
                lineHeight: "24px",
                fontWeight: 600,
              }}
            >
              No Recently Applied Jobs
            </p>
          </Stack>
        ) : null}
      </Grid>
    </HomeCard>
  );
}

export default RecentlyPostedJobs;
