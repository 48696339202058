import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// components
import Card from "../../../components/Card";
import Layout from "../../../../components/Layout";
import { ConfirmButton } from "../../../../components/Forms";

// hooks
import useResponse from "../../../../hooks/useResponse";

// image
import WelcomeImage from "../../../../assets/images/t-login.png";

import styles from "../../../components/form.module.css";

// services
import * as teacherAuthService from "../../../../services/teacherAuthService";
import * as toast from "../../../../Toast";

function Home() {
  const [state, action] = useResponse();
  const [currentUser, setCurrentUser] = useState();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const user = teacherAuthService.validateToken(token);
    if (!user) {
      navigate("not-found");
    }
    setCurrentUser(user);
  }, [token, navigate]);

  const navigateToTeacher = () => {
    navigate("/teacher/login");
  };

  const handleActivateClick = async () => {
    action.sendingRequst();
    try {
      const response = await teacherAuthService.teacherActivateAccount(token);
      toast.success(response.data.message);

      // navigate to teacher login
      navigateToTeacher();
      action.successResponse(response.data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        action.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <Layout imageUrl={WelcomeImage}>
      <div className={styles.container}>
        <div
          className={styles.formContainer}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Card>
            <div className={styles.formChildContainer}>
              <h1 className={styles.formTitle}>
                {currentUser?.email}, Please click on button below to active
                your teacher account.
              </h1>
            </div>
          </Card>
          <ConfirmButton
            size={1.6}
            buttonText="Activate account"
            // isBackground={false}
            onClick={handleActivateClick}
            loading={state.loading}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Home;
