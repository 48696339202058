import React from "react";

// white icons
import DashboardWhite from "../../assets/icon/grid_white.png";
// import CalendarWhite from "../../assets/icon/calendar_white.png";
import JobWhite from "../../assets/icon/briefcase_white.png";
import SettingWhite from "../../assets/icon/settings_white.png";
import NotificationWhite from "../../assets/icon/bell_white.png";

// Black icons
import DashboardBlack from "../../assets/icon/grid_black.png";
// import CalendarBlack from "../../assets/icon/calendar_black.png";
import JobBlack from "../../assets/icon/briefcase_black.png";
import SettingBlack from "../../assets/icon/settings_black.png";
import NotificationBlack from "../../assets/icon/bell.png";
import SideNav from "./Sidebar";

const NavLinkList = [
  {
    id: "273044b2-da6f-4c66-b906-6d2486a2cda5",
    to: "/teacher/dashboard/home",
    menuName: "Dashboard",
    whiteIcon: DashboardWhite,
    blackIcon: DashboardBlack,
  },
  // {
  //   id: "8a84e7b4-b5e0-46eb-a4bd-50f3cf406c0f",
  //   to: "/teacher/dashboard/Calender",
  //   menuName: "Calendar",
  //   whiteIcon: CalendarWhite,
  //   blackIcon: CalendarBlack,
  // },
  {
    id: "ab9d4bd7-3f7d-4fe1-b969-2ca1bb9cbea9",
    to: "/teacher/dashboard/jobs",
    menuName: "Jobs",
    whiteIcon: JobWhite,
    blackIcon: JobBlack,
  },

  {
    id: "ab9d4bd7-3f7d-4fe1-bh69-2ca1bb9cbea9",
    to: "/teacher/dashboard/notifications",
    menuName: "Notifications",
    whiteIcon: NotificationWhite,
    blackIcon: NotificationBlack,
  },

  {
    id: "643cc41d-01d1-44bc-ad37-38151db7f325",
    to: "/teacher/dashboard/settings",
    menuName: "Settings",
    whiteIcon: SettingWhite,
    blackIcon: SettingBlack,
  },
];

function TeacherSidebar() {
  return <SideNav navLinkList={NavLinkList} />;
}

export default TeacherSidebar;
