import React from "react";
import { Navigate } from "react-router-dom";

// auth services
import auth from "../services/authService";

function GuestRoute({ children }) {
  const user = auth.getCurrentUser();

  if (!user) {
    return children;
  }

  const role = user.role;
  return (
    <Navigate
      to={role === "school" ? "/school/dashboard/" : "/teacher/dashboard/"}
      replace
    />
  );
}

export default GuestRoute;
