import React from "react";
import moment from "moment";
import { Stack } from "@mui/material";

import {
  ListFilterDropdownModal,
  ShowListWithComma,
  ShowMoreList,
} from "../../../../../../../../components/job-form";
import { ProfileImage } from "../../../../../../../components/TopBar";

// hooks
import useVisible from "../../../../../../../../hooks/useVisible";

import styles from "./allTeacher.module.css";

const filterActionOptions = [
  {
    key: "Remove from Favourite",
    value: "fav",
  },
];

function FavTeacherItem({ teacher = {}, onUnfavouriteTeacher, onCellClick }) {
  const [showFilterAction, toggleShowFilterAction] = useVisible();

  const {
    // email ,
    fullName,
    teacherRegNumber,
    profileURL,
    availability,
    expertise,
    id: teacherId,
  } = teacher.teacherId;
  const verifiedTeacher = teacher.verifiedTeacher;
  const jobId = teacher.id;

  const handleFilterSelection = async (selectedOption) => {
    await onUnfavouriteTeacher(jobId, teacherId);
    toggleShowFilterAction();
  };

  return (
    <tr className={`${styles.itemContainer} ${styles.rowContainer}`}>
      <td
        className={`${styles.rowColumnText} ${styles.cursor}`}
        onClick={() => onCellClick(teacherId)}
      >
        <Stack
          display="flex"
          flexDirection="row"
          columnGap={1}
          alignItems="center"
        >
          {/* {profileURL && (
            <Avatar alt={fullName} src={http.imageBaseUrl + profileURL} />
          )} */}
          <ProfileImage imageUrl={profileURL} name={fullName} />
          <div>
            <p className={styles.fullName}>{fullName}</p>
            <p className={styles.contantNumber}>{teacherRegNumber}</p>
          </div>
        </Stack>
      </td>
      <td className={`${styles.rowColumnText} ${styles.expertiesColumn}`}>
        <ShowMoreList list={expertise} />
      </td>
      <td className={`${styles.rowColumnText} ${styles.expertiesColumn}`}>
        <ShowListWithComma list={availability} />
      </td>
      <td className={`${styles.rowColumnText} ${styles.verifyDate}`}>
        {verifiedTeacher
          ? moment(verifiedTeacher.createdAt).format("D MMM YYYY")
          : "-"}
      </td>
      <td className={`${styles.rowColumnText} ${styles.filterAction}`}>
        <span onClick={toggleShowFilterAction}>...</span>
        {showFilterAction ? (
          <ListFilterDropdownModal
            showModal={showFilterAction}
            toggleShowModal={toggleShowFilterAction}
            value=""
            options={filterActionOptions}
            onChange={handleFilterSelection}
          />
        ) : null}
      </td>
    </tr>
  );
}

export default FavTeacherItem;
