import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// components
import Layout from "../../../../components/Layout";
import TeacherSignupFormSecond from "../../../components/TeacherSignupFormSecond";

// image
import StepSecondImage from "../../../../assets/images/t-step4.png";

// action creator
import { setTeacherStepSecondData } from "../../../../stores/teacherRegisterationReducer";

function RegisterStepSecond() {
  const navigator = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (teacherData) => {
    dispatch(
      setTeacherStepSecondData({
        ...teacherData,
      })
    );
    navigator("/teacher/register-step-third");
  };

  return (
    <Layout imageUrl={StepSecondImage}>
      <TeacherSignupFormSecond
        title="Create Your Account"
        onSubmit={handleSubmit}
      />
    </Layout>
  );
}

export default RegisterStepSecond;
