import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

// components
import { Stack } from "@mui/system";
import { ConfirmationModal, RedirectTabs } from "../../../../../../components";
import {
  AboutDetail,
  BioDetail,
  Card,
  CertificateLicenseTable,
  ContantDetail,
  Header,
  PersonalDetail,
  ProfileImage,
  TestimonialList,
} from "./components";
import { ConfirmButton } from "../../../../../../../components/Forms";
import {
  ErrorMessage,
  IconButton,
  Loader,
} from "../../../../../../../components";

// hooks
import useResponse from ".././../../../../../../hooks/useResponse";
import useVisible from "../../../../../../../hooks/useVisible";

// services
import { getApplicantProfile } from "../../../../../../../services/applicantProfileService";
import {
  sendRequestToJobApplicant,
  sendRejectJobApplicant,
} from "../../../../../../../services/jobApplicationService";
import {
  verifyTeacherBySchool,
  unVerifiedTeacherBySchool,
} from "../../../../../../../services/verfiedTeacherService";

// image
import checkIcon from "../../../../../../../assets/icon/check-circle.png";

import styles from "./applicantprofile.module.css";
import * as toast from "../../../../../../../Toast";

function getNavLinkList(jobId, applicantId) {
  const navLinkList = [
    {
      to: "/school/dashboard/job",
      tabName: "Jobs",
    },
    {
      to: `/school/dashboard/job/details/${jobId}`,
      tabName: "Applicants List",
    },
    {
      to: `/school/dashboard/job/details/${jobId}/applicant-list/${applicantId}`,
      tabName: "Applicant Profile",
    },
  ];

  return navLinkList;
}

function ApplicantProfile() {
  const navigate = useNavigate();
  const params = useParams();
  const [showSendShiftModal, toggleSendShiftModal] = useVisible();
  const [showSendRejectModal, toggleSendRejectModal] = useVisible();
  const [showPaymentFailedModal, togglePaymentFailedModal] = useVisible();
  const [showVerifyModal, toggleVerifyModal] = useVisible();
  const [sendRequestState, sendRequestAction] = useResponse();
  const [sendRejectState, sendRejectAction] = useResponse();
  const [verifiedState, verifiedAction] = useResponse();
  const jobId = params.jobId;
  // const applicantId = params.id;
  const teacherId = params.id;

  const onError = (error) => {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status <= 409
    ) {
      navigate("/not-found");
    }
  };

  const { isError, isLoading, data, error, refetch } = useQuery(
    ["applicant-profile", jobId, teacherId],
    () => getApplicantProfile(jobId, teacherId),
    { refetchOnWindowFocus: true, onError, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const {
    teacher,
    jobApplication,
    verifiedTeacher,
    teacherdocuments,
    testimonials,
  } = data.data.data;

  const {
    email,
    fullName,
    phoneNumber,
    profileURL,
    teacherRegNumber,
    classPreference,
    expertise,
    locationAddress,
    bio,
  } = teacher;
  const { id: applicantId, status } = jobApplication;

  const handleSendShiftRequestConfirm = async () => {
    sendRequestAction.sendingRequst();
    try {
      const response = await sendRequestToJobApplicant({
        applicantId,
        jobId,
        teacherId,
      });

      const { message, data } = await response.data;
      if (data.paymentFailed) {
        toggleSendShiftModal();

        // open payment failed modal
        togglePaymentFailedModal();
      } else if (data.isAlreadyBooked) {
        toast.info(message);

        // toggle send shift request modal
        toggleSendShiftModal();
      } else {
        toast.success(message);
        // refetching the applicant profile data
        await refetch();

        // toggle send shift request modal
        toggleSendShiftModal();
      }
      sendRequestAction.successResponse(message);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        toast.error(error.response.data.message);
        sendRequestAction.gotError(error.response.data.message);
      }
    }
  };

  const handleSendRejectRequestConfirm = async () => {
    sendRejectAction.sendingRequst();
    try {
      const response = await sendRejectJobApplicant({
        applicantId,
        jobId,
        teacherId,
      });

      const { message } = await response.data;

      toast.success(message);
      sendRejectAction.successResponse(message);

      // toggle send shift reject request modal
      toggleSendRejectModal();

      navigate(`/school/dashboard/job/details/${jobId}`);

      // refetching the applicant profile data
      await refetch();
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        toast.error(error.response.data.message);
        sendRejectAction.gotError(error.response.data.message);
      }
    }
  };

  const handleVerifiedTeacherConfirm = async () => {
    verifiedAction.sendingRequst();
    try {
      const response = await verifyTeacherBySchool({
        teacherId,
      });

      const { message } = await response.data;

      // refetching the applicant profile data
      await refetch();

      // toggle send shift request modal
      toggleVerifyModal();

      toast.success(message);
      verifiedAction.successResponse(message);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        toast.error(error.response.data.message);
        verifiedAction.gotError(error.response.data.message);
      }
    }
  };

  const handleUnVerifiedTeacherConfirm = async () => {
    verifiedAction.sendingRequst();
    try {
      const response = await unVerifiedTeacherBySchool({
        teacherId,
      });

      const { message } = await response.data;

      // refetching the applicant profile data
      await refetch();

      // toggle send shift request modal
      toggleVerifyModal();

      toast.success(message);
      verifiedAction.successResponse(message);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        toast.error(error.response.data.message);
        verifiedAction.gotError(error.response.data.message);
      }
    }
  };

  const subtitle = (
    <>
      Teacher's added payment method is not active/valid.
      <br />
      So you can't send shift request to this teacher.
    </>
  );

  const cardStyle = { minheight: "18rem", width: "27rem" };
  return (
    <>
      <ConfirmationModal
        subtitle={subtitle}
        ConfirmButtonText="Okay"
        showModal={showPaymentFailedModal}
        onConfirm={togglePaymentFailedModal}
        toggleModal={togglePaymentFailedModal}
        hideCancel
      />
      <ConfirmationModal
        subtitle={`Are you sure you want to assign this job to teacher '${fullName}'?`}
        ConfirmButtonText="Yes"
        CancelButtonText="No"
        showModal={showSendShiftModal}
        onConfirm={handleSendShiftRequestConfirm}
        toggleModal={toggleSendShiftModal}
        loading={sendRequestState.loading}
      />
      <ConfirmationModal
        subtitle={`Are you sure you want to reject '${fullName}' from this job?`}
        ConfirmButtonText="Yes"
        CancelButtonText="No"
        showModal={showSendRejectModal}
        onConfirm={handleSendRejectRequestConfirm}
        toggleModal={toggleSendRejectModal}
        loading={sendRejectState.loading}
      />
      <ConfirmationModal
        subtitle={
          verifiedTeacher === null
            ? "Do you want to make this teacher as verified?"
            : "Do you want to make this teacher as unverified?"
        }
        ConfirmButtonText="Yes"
        CancelButtonText="No"
        showModal={showVerifyModal}
        onConfirm={
          verifiedTeacher === null
            ? handleVerifiedTeacherConfirm
            : handleUnVerifiedTeacherConfirm
        }
        toggleModal={toggleVerifyModal}
        loading={verifiedState.loading}
      />

      <div>
        <RedirectTabs redirectList={getNavLinkList(jobId, teacherId)} />
        <Header title="Applicant Profile">
          {status !== "rejected" ? (
            <ConfirmButton
              buttonText="Reject"
              size={1.6}
              isBackground={false}
              onClick={toggleSendRejectModal}
            />
          ) : (
            <div></div>
          )}
          {status !== "booked" ? (
            <ConfirmButton
              buttonText="Send Shift Request"
              size={1.6}
              onClick={toggleSendShiftModal}
            />
          ) : (
            <div></div>
          )}
        </Header>
        <div className={styles.profileContainer}>
          <Stack display="flex" flexDirection="row" columnGap={2.4}>
            <ProfileImage imageUrl={profileURL} name={fullName} />
            <Card style={cardStyle}>
              <PersonalDetail
                title="Personal Details"
                detail={{
                  fullName,
                  teacherRegNumber,
                  locationAddress,
                  experties: expertise[0],
                }}
              />
            </Card>
          </Stack>
          <Stack
            display="flex"
            flexDirection="row"
            // justifyContent="flex-end"
            columnGap={2.4}
          >
            <Card style={cardStyle}>
              <ContantDetail
                title="Contact Details"
                detail={{ email, phoneNumber }}
              />
            </Card>
            <Card style={cardStyle}>
              <AboutDetail
                title="About"
                detail={{ classPreference, expertise }}
              />
            </Card>
          </Stack>
        </div>
        <Card>
          <BioDetail title="Other Details" content={bio} />
        </Card>

        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          columnGap={2.4}
          mt={2}
        >
          <IconButton
            style={{ width: "20rem" }}
            icon={checkIcon}
            white
            onClick={toggleVerifyModal}
          >
            {verifiedTeacher === null ? "Verify Teacher" : "Verified"}
          </IconButton>
        </Stack>
        <CertificateTestimonial
          certificateLicenseList={teacherdocuments}
          testimonialList={testimonials}
        />
      </div>
    </>
  );
}

export default ApplicantProfile;

const CertificateTestimonial = ({
  certificateLicenseList,
  testimonialList,
}) => {
  const [showTestimonial, setShowTestimonial] = useState(true);

  let children = (
    <Stack display="flex" flexDirection="row" columnGap={2.4} mt={1.6}>
      <TestimonialList testimonials={testimonialList} />
    </Stack>
  );

  if (showTestimonial) {
    children = (
      <CertificateLicenseTable
        certificateLicenseList={certificateLicenseList}
      />
    );
  }

  const tabItemClass = "active";

  return (
    <div>
      <div className={styles.tabContainer}>
        <div
          className={`${styles.tabItem} ${
            showTestimonial && styles[tabItemClass]
          }`}
          onClick={() => setShowTestimonial(true)}
        >
          <p>Licenses and Certificates</p>
          <span></span>
        </div>
        <div
          className={`${styles.tabItem} ${
            !showTestimonial && styles[tabItemClass]
          }`}
          onClick={() => setShowTestimonial(false)}
        >
          <p>Testimonials</p>
          <span></span>
        </div>
      </div>
      {children && children}
    </div>
  );
};

// const certificateLicenseList = [
//   {
//     id: 1,
//     documentNumber: "29dhiwjdou92u92",
//     documentTitle: "Python Certificate",
//     documentType: "Certificate",
//     expiryDate: "2022-10-30",
//   },
//   {
//     id: 2,
//     documentNumber: "29dhiwjdou92u92",
//     documentTitle: "Cyber Security License",
//     documentType: "License",
//     expiryDate: "2022-10-30",
//   },
//   {
//     id: 3,
//     documentNumber: "29dhiwjdou92u92",
//     documentTitle: "Python Certificate",
//     documentType: "Certificate",
//     expiryDate: "2022-10-30",
//   },
//   {
//     id: 4,
//     documentNumber: "29dhiwjdou92u92",
//     documentTitle: "Cyber Security License",
//     documentType: "License",
//     expiryDate: "2022-10-30",
//   },
// ];
