import http from "./httpService";

const apiEndpoint = http.apiUrl + "job";

function SchoolJobURl(id) {
  return `${apiEndpoint}/${id}`;
}

function cancelJobUrl(id) {
  return `${apiEndpoint}/cancel/${id}`;
}

const constructionURL = (API_URL_ROUTE, { page, limit, filter = {} }) => {
  const url = new URL(apiEndpoint + API_URL_ROUTE);

  url.searchParams.set("page", page);
  url.searchParams.set("limit", limit);

  if (filter.jobType) {
    url.searchParams.set("jobType", filter.jobType);
  }

  if (filter.category) {
    url.searchParams.set("category", filter.category);
  }

  if (filter.status) {
    url.searchParams.set("status", filter.status);
  }

  return url;
};

// service is used to get all job
export function getAllJob() {
  return http.get(apiEndpoint);
}

// service is used to get all jobs with pagination
export function getAllJobWithPagination({ page = 1, limit = 10, filter = {} }) {
  const url = constructionURL("/listing", { page, limit, filter });
  return http.get(url);
}

// service is used to get details of single job
export function getSingleJob(id) {
  return http.get(SchoolJobURl(id));
}

// service is used to create job
export function createJob(data) {
  return http.post(apiEndpoint, data);
}

// service is used to cancelled job
export function cancelledJob(id) {
  return http.put(cancelJobUrl(id));
}

// service is used to delete job
export function deleteJob(id) {
  return http.delete(SchoolJobURl(id));
}
