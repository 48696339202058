import React, { useState } from "react";
import ShowListWithComma from "./ShowListWithComma";

function ShowMoreList({ list = [] }) {
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      {showMore ? <ShowListWithComma list={list} /> : <span>{list[0]}</span>}
      {showMore ? null : (
        <>
          {list.length > 1 && (
            <span
              style={{
                cursor: "pointer",
              }}
              onClick={() => setShowMore(true)}
            >
              +{list.length - 1} more
            </span>
          )}
        </>
      )}
    </>
  );
}

export default ShowMoreList;
