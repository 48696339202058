import React from "react";

import { Card } from "../../../../../../components";
import { CardTitle } from "../../../../../../../components";
import { FilterSelect } from "../../../../../../../components/job-form";

import {
  categoryOptions,
  jobTypeOptions,
  jobStatusOptions,
} from "../../../../../../components/job-form-options";

import styles from "./jobListing.module.css";

function JobHeaderFilter({
  filter,
  onChangeJobType,
  onChangeJobStatus,
  onChangeJobCategory,
  onReset,
  statusOptions = jobStatusOptions,
}) {
  return (
    <div style={{ marginTop: "2.4rem" }}>
      <Card>
        <div className={styles.filterContainer}>
          <CardTitle>Filters</CardTitle>
          <FilterSelect
            name="jobType"
            label="Job Type"
            options={jobTypeOptions}
            onChange={onChangeJobType}
            value={filter.jobType}
          />
          <FilterSelect
            name="category"
            label="Category"
            options={categoryOptions}
            onChange={onChangeJobCategory}
            value={filter.category}
          />
          <FilterSelect
            name="status"
            label="Job Status"
            options={statusOptions}
            onChange={onChangeJobStatus}
            value={filter.status}
          />
          <p
            className={`${styles.headerText} ${styles.link}`}
            onClick={onReset}
          >
            Clear
          </p>
        </div>
      </Card>
    </div>
  );
}

export default JobHeaderFilter;
