import { styled } from "@mui/material/styles";

const Card = styled("div")`
  height: auto;
  width: 100%;
  background-color: var(--background-card);
  border-radius: var(--border-radius);
  border: 1px solid ${(props) => (props.border ? "#EEE9E8" : "transparent")};
`;

export default Card;
