import React, { useRef, useState } from "react";

// components
import { CheckBoxSingleSelectFilter } from "../Forms";

// hooks
import useOnClickOutside from "../../hooks/useOnClickOutside";

// eye icons
import chevrondown from "../../assets/icon/chevron-down.png";

import styles from "../Forms/multiSelect.module.css";
import styles2 from "./formInput.module.css";

function FilterSelect(props) {
  const { label, name, options, value, onChange, ...rest } = props;
  const ref = useRef(null);
  const [isOpen, toggleOpen] = useState(false);

  useOnClickOutside(ref, () => {
    toggleOpen(false);
  });

  const handleChange = (event) => {
    onChange(event.target.value);
    toggleOpen(false);
  };

  const field = { value, onChange: handleChange };
  const isValue = field.value;

  return (
    <div
      className={`${styles2.InputfieldContainer} ${styles2.filterMainContainer}`}
      ref={ref}
    >
      <div
        className={`${styles2.selectBtn} ${styles2.filterSelectedButton}`}
        onClick={() => {
          if (isOpen) toggleOpen(false);
          else toggleOpen(true);
        }}
      >
        <div>
          {label && (
            <p className={`${styles2.label} ${isValue && styles2.hideLabel}`}>
              {label}
            </p>
          )}
          {field.value && <p className={styles.selectedValue}>{field.value}</p>}
        </div>
        <img
          src={chevrondown}
          className={styles2.icon}
          alt="chevrondown icon"
        />
      </div>

      <div
        className={`${styles.checkboxContainer} ${styles2.checkboxContainer} ${
          styles2.filterCheckbox
        } ${isOpen && styles.open}`}
      >
        {options.map((option) => (
          <CheckBoxSingleSelectFilter
            key={option.key}
            field={field}
            option={option}
            setValue={onChange}
            {...rest}
          />
        ))}
      </div>
    </div>
  );
}

export default FilterSelect;
