import React from "react";
import { Stack } from "@mui/material";
import { Loader, NotificationCard, Title } from "../../../../../../components";
import { useQuery } from "react-query";
import { styled } from "@mui/material/styles";
import {
  getTeacherNotifications,
  markAllNotificationsReadTeacher,
} from "../../../../../../services/notificationService";
import { useEffect } from "react";
import { Pagination } from "../../../../../components";
import { useState } from "react";

const NotificationDropdown = styled("div")`
  background: #ffffff;
  border-radius: 12px;

  /* border: 1px solid #eee9e8; */
  /* width: 361px; */
`;

const Notifications = () => {
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalCount, setTotalCount] = useState(1);

  const { isError, isLoading, data, refetch } = useQuery({
    queryKey: [
      "notifications",
      {
        page: currentPage,
        limit: 10,
      },
    ],
    queryFn: getTeacherNotifications,
    refetchOnWindowFocus: true,
    cacheTime: 100,
  });

  const notifications = data?.data?.data?.result;
  const total = data?.data?.data?.total;
  const unread = data?.data?.data?.unread;

  // setTotalCount(total);

  useEffect(() => {
    if (unread > 0) {
      markAllNotificationsReadTeacher();
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unread]);

  const page = currentPage;

  const setPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <Stack>
      <Title>Notifications</Title>

      <NotificationDropdown>
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <p
            style={{
              padding: "20px",
            }}
          >
            Some Error Occurred
          </p>
        ) : total === 0 ? (
          <p
            style={{
              padding: "20px",
            }}
          >
            No Notification to Show
          </p>
        ) : (
          <>
            {notifications.map((notification) => (
              <NotificationCard
                key={notification._id}
                notification={notification}
              />
            ))}

            <Pagination
              currentPage={page}
              totalCount={total}
              pageSize={10}
              onPageChange={setPage}
            />
          </>
        )}
      </NotificationDropdown>
    </Stack>
  );
};

export default Notifications;
