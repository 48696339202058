import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import Layout from "../../../../components/Layout";
import TeacherSignupFormFirst from "../../../components/TeacherSignupFormFirst";

// hooks
import useResponse from "../../../../hooks/useResponse";

// image
import StepFirstImage from "../../../../assets/images/t-step2.png";

// action creator
import { setTeacherStepFirstData } from "../../../../stores/teacherRegisterationReducer";

// services
import * as teacherAuthService from "../../../../services/teacherAuthService";
import * as toast from "../../../../Toast";

function RegisterStepFirst() {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [state, action] = useResponse();

  const handleSubmit = async (teacherData) => {
    const { email } = teacherData;

    action.sendingRequst();
    try {
      // calling api to check email is already exist or not
      const response = await teacherAuthService.checkTeacherRegistered({
        email: email,
      });
      const { data } = await response.data;

      if (data.isUserFound === false) {
        dispatch(
          setTeacherStepFirstData({
            ...teacherData,
          })
        );
        navigator("/teacher/register-step-second");

        // toast.success("Successfully logged in.");
        action.successResponse(response.data.message);
      } else {
        action.gotError(
          "User with same email already exist, please use another email to register."
        );
        toast.error(
          "User with same email already exist, please use another email to register."
        );
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        action.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <Layout imageUrl={StepFirstImage}>
      <TeacherSignupFormFirst
        title="Create Your Account"
        onSubmit={handleSubmit}
        error={state.error}
        loading={state.loading}
      />
    </Layout>
  );
}

export default RegisterStepFirst;
