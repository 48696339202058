import jwtDecode from "jwt-decode";
import http from "./httpService";

const apiEndpoint = http.apiUrl + "teacher-auth";

const tokenKey = "teacher-token";

http.setTeacherJwt(getJwt());

export function checkTeacherRegistered(teacherEmailData) {
  return http.post(apiEndpoint + "/check-email", teacherEmailData);
}

export function teacherRegister(teacherData) {
  return http.post(apiEndpoint + "/register", teacherData);
}

export function teacherLogin(loginData) {
  return http.post(apiEndpoint + "/login", loginData);
}

export function teacherForgotPassword(emailObject) {
  return http.post(apiEndpoint + "/forget-password", emailObject);
}

export function teacherResetPassword(passwordObject, resetToken) {
  return http.put(
    apiEndpoint + "/password-reset/" + resetToken,
    passwordObject
  );
}

export function teacherActivateAccount(activateToken) {
  return http.put(apiEndpoint + "/active/" + activateToken);
}

export function logout() {
  sessionStorage.removeItem(tokenKey);
}

export function getTeacherUser() {
  try {
    const jwt = sessionStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function validateToken(jwt) {
  try {
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function setJWT(jwt) {
  sessionStorage.setItem(tokenKey, jwt);
}

export function getJwt() {
  return sessionStorage.getItem(tokenKey);
}

const authObject = {
  teacherLogin,
  logout,
  setJWT,
  getTeacherUser,
  getJwt,
};

export default authObject;
