import React from "react";

import { CardTitle } from "../../../../../../../../components";
import { FilterSelect } from "../../../../../../../../components/job-form";
import { Card } from "../../../../../../../components";

import { jobTypeOptions } from "../../../../../../../components/job-form-options";

import styles from "./allTeacher.module.css";

function AllTeacherFilter({ filter, onChangeJobType, onReset }) {
  return (
    <div style={{ marginTop: "2.4rem" }}>
      <Card>
        <div className={styles.filterContainer}>
          <CardTitle>Filters</CardTitle>
          <FilterSelect
            name="availability"
            label="Availabiltiy"
            options={jobTypeOptions}
            onChange={onChangeJobType}
            value={filter.jobType}
          />
          <p
            className={`${styles.headerText} ${styles.cursor}`}
            onClick={onReset}
          >
            Clear
          </p>
        </div>
      </Card>
    </div>
  );
}

export default AllTeacherFilter;
