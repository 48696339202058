import React, { useRef, useState } from "react";
import { useField } from "formik";

// components
import { CheckBoxMultiSelect, FormErrorMessage } from "../Forms";

// hooks
import useOnClickOutside from "../../hooks/useOnClickOutside";

// eye icons
import chevrondown from "../../assets/icon/chevron-down.png";

import styles from "../Forms/multiSelect.module.css";
import styles2 from "./formInput.module.css";

function MultiSelect(props) {
  const { label, name, options, showError = true, ...rest } = props;
  const ref = useRef(null);
  const [isOpen, toggleOpen] = useState(false);
  const [field, meta, helper] = useField(name);
  const { setTouched } = helper;

  useOnClickOutside(ref, () => {
    toggleOpen(false);
    // setTouched(true);
  });

  let className;
  if (meta.touched && meta.error) {
    className = "error";
  } else if (meta.touched && !meta.error) {
    className = "success";
  }

  const lastIndex = field.value.length;

  return (
    <div className={styles2.InputfieldContainer} ref={ref}>
      <div
        className={`${styles2.selectBtn}  ${styles2[className]}`}
        onClick={() => {
          if (isOpen) toggleOpen(false);
          else toggleOpen(true);
          setTouched(true);
        }}
      >
        <div>
          {label && (
            <p className={`${styles2.label} ${lastIndex && styles2.hideLabel}`}>
              {label}
            </p>
          )}
          {field.value.length > 0 && (
            <p className={styles.selectedValue}>
              {field.value.map((value, index) => (
                <>
                  {index ? ", " : ""} {value}
                </>
              ))}
            </p>
          )}
        </div>
        <img
          src={chevrondown}
          className={styles2.icon}
          alt="chevrondown icon"
        />
      </div>
      <div
        className={`${styles.checkboxContainer} ${styles2.checkboxContainer} ${
          isOpen && styles.open
        }`}
      >
        {options.map((option) => (
          <CheckBoxMultiSelect
            key={option.key}
            field={field}
            option={option}
            {...rest}
          />
        ))}
      </div>

      {showError && <FormErrorMessage name={name} />}
    </div>
  );
}

export default MultiSelect;
