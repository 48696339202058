import http from "./httpService";

const apiEndpoint = http.apiUrl + "job-details";

function jobDetailsURl(id) {
  return `${apiEndpoint}/${id}`;
}

// service is used to get details of single job
export function getJobDetailsForTeacher(id) {
  return http.get(jobDetailsURl(id) + "/t");
}

export function getJobDetailsForSchool(id) {
  return http.get(jobDetailsURl(id) + "/s");
}
