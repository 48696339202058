import React from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// components
import Card from "./Card";
import { Form, FormInput, FormButton } from "../../components/Forms";

import styles from "./form.module.css";

// forgot password validationSchema
const validationSchema = Yup.object().shape({
  email: Yup.string().required().min(6).email().label("Email").trim(),
});

function ForgotPassForm({ title, path, onSubmit, error, loading }) {
  return (
    <div className={styles.container}>
      <h1 className={styles.titleHeading}>{title}</h1>
      <Form
        initialValues={{
          email: "",
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        className={styles.formContainer}
      >
        <Card>
          <div className={styles.formChildContainer}>
            <p className={styles.formSubtitle}>
              Enter your registered email address to reset your password.
            </p>

            <FormInput label="Email address" name="email" type="text" />
            <Link to={path} className={styles.link}>
              Back to Login
            </Link>
          </div>
          {/* {error && <p className="error">{error}</p>} */}
        </Card>
        <div className={styles.formContainer} style={{ marginTop: "5rem" }}>
          <FormButton
            buttonText="Forgot Password"
            type="submit"
            submitButton={true}
            loading={loading}
          />
        </div>
      </Form>
    </div>
  );
}

export default ForgotPassForm;
