import React from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

// components
import { Stack } from "@mui/system";
import { ConfirmButton } from "../../../../../../components/Forms";
import { ConfirmationModal, RedirectTabs } from "../../../../../components";
import { CardTitle } from "../../../../../components/JobForm";
import { ErrorMessage, Loader } from "../../../../../../components";
import { ApplicantList, Card, CardWrapper } from "./components/details";
import {
  ContentItem,
  ContentWrapper,
} from "../../../../../teacher/pages/dashboard/pages/jobs/components/ScheduleDetails";

// hooks
import useVisible from "../../../../../../hooks/useVisible";
import useResponse from "../../../../../../hooks/useResponse";

// services
import { getJobDetailsForSchool } from "../../../../../../services/jobDetailsService";
import { cancelledJob } from "../../../../../../services/jobService";
import * as toast from "../../../../../../Toast";

// import styles from "./jobDetails.module.css";

function getNavLinkList(jobId) {
  const navLinkList = [
    {
      to: "/school/dashboard/job",
      tabName: "Jobs",
    },
    {
      to: `/school/dashboard/job/details/${jobId}`,
      tabName: "Applicants List",
    },
  ];

  return navLinkList;
}

function JobDetails() {
  const [showCancelModal, toggleCancelModal] = useVisible();
  const [cancelJobState, cancelJobAction] = useResponse();
  const navigate = useNavigate();
  const params = useParams();
  const jobId = params.id;

  const onError = (error) => {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status <= 409
    ) {
      navigate("/not-found");
    }
  };

  const { isError, isLoading, data, error, refetch } = useQuery(
    ["job-details", jobId],
    () => getJobDetailsForSchool(jobId),
    { refetchOnWindowFocus: true, onError, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const job = data.data.data["job"];
  const {
    jobTitle,
    jobType,
    startDate,
    // time,
    recurrence,
    classYear,
    expertise,
    // position,
    notes,
    status,
    // schoolId,
  } = job;

  const handleCancelJobConfirm = async () => {
    cancelJobAction.sendingRequst();
    try {
      const response = await cancelledJob(jobId);
      const { message } = await response.data;

      cancelJobAction.successResponse(message);
      toast.success(message);

      // toggle send reject request modal
      toggleCancelModal();

      // navigate to listing page
      navigate("/school/dashboard/job");

      // refresh job details
      // await refetch();
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        toast.error(error.response.data.message);
        cancelJobAction.gotError(error.response.data.message);
      }
    }
  };

  return (
    <>
      <ConfirmationModal
        subtitle="Are you sure you want to cancel this job?"
        ConfirmButtonText="Cancel Job"
        CancelButtonText="Not Now"
        showModal={showCancelModal}
        onConfirm={handleCancelJobConfirm}
        toggleModal={toggleCancelModal}
        loading={cancelJobState.loading}
      />
      <div>
        <RedirectTabs redirectList={getNavLinkList(jobId)} />
        <Card>
          <Stack direction="row" justifyContent="space-between">
            <CardTitle>Job Details</CardTitle>
            {status === "open" ? (
              <ConfirmButton
                size={1.6}
                style={{ width: "20rem" }}
                buttonText="Cancel Job"
                onClick={toggleCancelModal}
              />
            ) : null}
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            columnGap={3}
          >
            <CardWrapper title="Basic Details">
              <ContentWrapper>
                <ContentItem title="Job Title">{jobTitle}</ContentItem>
                <ContentItem title="Date">
                  {moment(startDate).format("DD MMM YYYY")}
                </ContentItem>
              </ContentWrapper>
            </CardWrapper>
            <CardWrapper title="Schedule Details">
              <ContentWrapper>
                <ContentItem title="Job Type">{jobType}</ContentItem>
                <ContentItem title="Recurrence">{recurrence}</ContentItem>
              </ContentWrapper>
            </CardWrapper>
            <CardWrapper title="Experience Details">
              <ContentWrapper>
                <ContentItem title="Class Year">{classYear}</ContentItem>
                <ContentItem title="Expertise">{expertise}</ContentItem>
              </ContentWrapper>
            </CardWrapper>
            <CardWrapper title="Additional Details">
              <ContentWrapper>
                <ContentItem title="Notes">{notes ? notes : "-"}</ContentItem>
              </ContentWrapper>
            </CardWrapper>
          </Stack>
        </Card>

        <Stack mt={2.4}>
          <ApplicantList jobId={jobId} refechJob={refetch} />
        </Stack>
      </div>
    </>
  );
}

export default JobDetails;
