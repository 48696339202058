import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

// components
import {
  HomeCard,
  HomeJobListing,
} from "../../../../../../components/dashboard";
import { CardTitle } from "../../../../../../components/JobForm";
import { Loader } from "../../../../../../../components";

// services
import { getAllAvailableJob } from "../../../../../../../services/teacherHomeService";
// import * as toast from "../../../../../../../Toast";

import styles from "../home.module.css";
import { Stack } from "@mui/material";

function AvailableJobCard() {
  const navigate = useNavigate();
  const { isError, isLoading, data, error } = useQuery(
    ["available-jobs"],
    () => getAllAvailableJob(),
    { refetchOnWindowFocus: true, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <p>{error.response.data.message}</p>;
  }

  const jobs = data.data.data["jobs"];

  const handleClickOnJob = (jobId) => {
    navigate(`/teacher/dashboard/jobs/detail/${jobId}`);
  };

  let noRecord;
  let jobListingUI;

  if (jobs.length > 0) {
    jobListingUI = (
      <HomeJobListing jobListing={jobs} onJobClick={handleClickOnJob} />
    );
  } else {
    noRecord = (
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          padding: "3rem",
        }}
      >
        <p
          style={{
            letterSpacing: "0.1px",
            color: "var(--text-tertiary)",
            fontSize: "17px",
            lineHeight: "24px",
            fontWeight: 600,
          }}
        >
          No Available Jobs
        </p>
      </Stack>
    );
  }

  return (
    <HomeCard className={styles.gridThirdchild}>
      <CardTitle>Available New Jobs</CardTitle>
      {noRecord && noRecord}
      {jobListingUI && jobListingUI}
    </HomeCard>
  );
}

export default AvailableJobCard;
