import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./listingTab.module.css";

const navLinkList = [
  {
    name: "All Jobs",
    link: "/teacher/dashboard/jobs",
    pathname: "/teacher/dashboard/jobs",
  },
  {
    name: "Applied Jobs",
    link: "/teacher/dashboard/jobs/applied-jobs",
    pathname: "/teacher/dashboard/jobs/applied-jobs",
  },
  {
    name: "Booked Jobs",
    link: "/teacher/dashboard/jobs/booked-jobs",
    pathname: "/teacher/dashboard/jobs/booked-jobs",
  },
];

function JobListingTabs({ tabList = navLinkList }) {
  let location = useLocation();
  return (
    <div className={styles.tabContainer}>
      {tabList.map(({ name, link, pathname }, index) => (
        <NavLink
          key={index}
          to={link}
          className={(navData) =>
            pathname === location.pathname
              ? `${styles.active} ${styles.tabItem}`
              : styles.tabItem
          }
        >
          <p>{name}</p>
          <span></span>
        </NavLink>
      ))}
    </div>
  );
}

export default JobListingTabs;
