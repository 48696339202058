import jwtDecode from "jwt-decode";
// import http from "./httpService";

const tokenKey = "token";

// http.setJwt(getJwt());

export function logout() {
  sessionStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = sessionStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function validateToken(jwt) {
  try {
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function setJWT(jwt) {
  sessionStorage.setItem(tokenKey, jwt);
}

export function getJwt() {
  return sessionStorage.getItem(tokenKey);
}

const authObject = {
  logout,
  setJWT,
  getCurrentUser,
  getJwt,
};

export default authObject;
