import React, { useState, useEffect, useCallback } from "react";

// components
import AllTeacherFilter from "./AllTeacherFilter";
import AllTeacherHeader from "./AllTeacherHeader";
import AllTeacherItem from "./AllTeacherItem";
import { Header, Pagination } from "../../../../../../../components";
import { DotLoader, NoRecordFound } from "../../../../../../../../components";
import { JobListingTabs } from "../../../../../../../teacher/pages/dashboard/pages/jobs/components";

// hooks
// import useResponse from "../../../../../../../../hooks/useResponse";

// services
import {
  getAllSchoolTeacherWithPagination,
  makeTeacherFavorite,
} from "../../../../../../../../services/schoolTeacherService";
import * as toast from "../../../../../../../../Toast";

import styles from "./allTeacher.module.css";

const defaultFilter = { availability: "" };

const navLinkList = [
  {
    name: "All Teachers",
    link: "/school/dashboard/my-teachers",
    pathname: "/school/dashboard/my-teachers",
  },
  {
    name: "Favourite Teachers",
    link: "/school/dashboard/my-teachers/fav-teachers",
    pathname: "/school/dashboard/my-teachers/fav-teachers",
  },
];

function TeacherListing({ onCellClick }) {
  // const [state, action] = useResponse();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);

  const [filter, setFilter] = useState(defaultFilter);

  const page = currentPage;

  const setPage = (page) => {
    setCurrentPage(page);
  };

  const setPageToStart = () => {
    setCurrentPage(1);
  };

  const fetchData = useCallback(async ({ page, filter }) => {
    try {
      setLoading(true);
      const response = await getAllSchoolTeacherWithPagination({
        page: page,
        limit: 5,
        filter,
      });
      const { data } = await response.data;
      setData(data.result);
      setTotalCount(data.total);
    } catch (ex) {
      toast.error(ex.response.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData({ page: page, filter });
  }, [fetchData, page, filter]);

  if (loading) {
    return <DotLoader />;
  }

  const handleTeacherFavorite = async (jobId, teacherId) => {
    // action.sendingRequst();
    try {
      const response = await makeTeacherFavorite({
        teacherId,
        jobId,
      });

      const { message } = await response.data;

      toast.success(message);
      // action.successResponse(message);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        toast.error(error.response.data.message);
        // action.gotError(error.response.data.message);
      }
    }
  };

  let listings;
  if (data && data.length > 0) {
    listings = (
      <tbody>
        {data.map((teacher, index) => (
          <>
            <AllTeacherItem
              teacher={teacher}
              onFavouriteTeacher={handleTeacherFavorite}
              onCellClick={onCellClick}
              key={index + 1}
            />
          </>
        ))}
      </tbody>
    );
  } else {
    listings = (
      <tbody>
        <tr>
          <td>
            <NoRecordFound />
          </td>
        </tr>
      </tbody>
    );
  }

  const handleResetFilter = () => {
    setFilter(defaultFilter);
    setPageToStart();
  };

  const handleJobTypeChange = (jobType) => {
    setFilter((prev) => {
      return { ...prev, jobType };
    });
    setPageToStart();
  };

  return (
    <div>
      <Header title="My Teachers" />
      <JobListingTabs tabList={navLinkList} />
      <AllTeacherFilter
        filter={filter}
        onChangeJobType={handleJobTypeChange}
        onReset={handleResetFilter}
      />
      <table className={styles.listingContainer}>
        <thead className={styles.headerContainer}>
          <AllTeacherHeader />
        </thead>
        {listings && listings}
      </table>
      <Pagination
        currentPage={page}
        totalCount={totalCount}
        pageSize={5}
        onPageChange={setPage}
      />
    </div>
  );
}

export default TeacherListing;
