import React from "react";

// white icons
import DashboardWhite from "../../assets/icon/grid_white.png";
// import CalendarWhite from "../../assets/icon/calendar_white.png";
import JobWhite from "../../assets/icon/briefcase_white.png";
import MyTeachersWhite from "../../assets/icon/users_white.png";
import SettingWhite from "../../assets/icon/settings_white.png";
import NotificationWhite from "../../assets/icon/bell_white.png";

// Black icons
import DashboardBlack from "../../assets/icon/grid_black.png";
// import CalendarBlack from "../../assets/icon/calendar_black.png";
import JobBlack from "../../assets/icon/briefcase_black.png";
import MyTeachersBlack from "../../assets/icon/users_black.png";
import SettingBlack from "../../assets/icon/settings_black.png";
import NotificationBlack from "../../assets/icon/bell.png";
import SideNav from "./Sidebar";

const NavLinkList = [
  {
    id: 1,
    to: "/school/dashboard/home",
    menuName: "Dashboard",
    whiteIcon: DashboardWhite,
    blackIcon: DashboardBlack,
  },
  // {
  //   id: 2,
  //   to: "/school/dashboard/Calender",
  //   menuName: "Calendar",
  //   whiteIcon: CalendarWhite,
  //   blackIcon: CalendarBlack,
  // },
  {
    id: 3,
    to: "/school/dashboard/job",
    menuName: "Jobs",
    whiteIcon: JobWhite,
    blackIcon: JobBlack,
  },
  {
    id: 4,
    to: "/school/dashboard/my-teachers",
    menuName: "My Teachers",
    whiteIcon: MyTeachersWhite,
    blackIcon: MyTeachersBlack,
  },

  {
    id: 5,
    to: "/school/dashboard/notifications",
    menuName: "Notifications",
    whiteIcon: NotificationWhite,
    blackIcon: NotificationBlack,
  },

  {
    id: 6,
    to: "/school/dashboard/settings",
    menuName: "Settings",
    whiteIcon: SettingWhite,
    blackIcon: SettingBlack,
  },
];

function SchoolSidebar() {
  return <SideNav navLinkList={NavLinkList} />;
}

export default SchoolSidebar;
