import React from "react";
import { Title } from "../../../../../../components";
import styles from "./home.module.css";

// import RecentlyPostedJobs from "./components/RecentlyPostedJobs";

import RecentlyVerifiedTeachers from "./components/RecentlyVerifiedTeachers";
import ActivityCard from "./components/ActivityCard";
import RecentlyPostedJobs from "./components/RecentlyPostedJobs";

function SchoolDashboardHome() {
  return (
    <>
      <Title>Dashboard</Title>
      <div className={styles.mainContainer}>
        <ActivityCard />
        <RecentlyVerifiedTeachers />
        <RecentlyPostedJobs />
      </div>
    </>
    // <p>richard</p>
  );
}

export default SchoolDashboardHome;
