import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { CardTitle, Loader } from "../../../../../../../../components";
import { HomeCard } from "../../../../../../../components/dashboard";
import { Grid, Stack } from "@mui/material";
import useVisible from "../../../../../../../../hooks/useVisible";
import { DocumentsForm } from "../../../../../../../../components/Modal";

import AddIcon from "../../../../../../../../assets/icon/plus-circle.svg";

import {
  AddDocumentButton,
  AddIconStyles,
  CertificateRow,
  CertificateRowHeading,
  CertificateRowValue,
  DeleteButon,
  GridContainer,
  Header,
} from "./CertificatesLicenses.styles";
import {
  addCertificateToDelete,
  teacherDeleteCertificate,
} from "../../../../../../../../stores/teacherRegisterationReducer";
import { ConfirmationModal } from "../../../../../../../components";

const CertificatesLicenses = () => {
  const dispatch = useDispatch();

  const { loading, teacher, error, deleteCertificate } = useSelector(
    (state) => state.entities.teacherRegisteration
  );

  const header = [
    "Document Number",
    "Document Title",
    "Document Type",
    "Expiry Date",
    "Delete",
  ];

  const [isAddCertificate, toggleAddCertificate] = useVisible();
  const [isDeleteConfirm, toggleIsDeleteConfirm] = useVisible();

  const handleDeleteCertificate = (document) => {
    dispatch(addCertificateToDelete(document));
    toggleIsDeleteConfirm();
  };

  const deleteDocumentHandler = () => {
    dispatch(teacherDeleteCertificate(deleteCertificate.id));
    toggleIsDeleteConfirm();
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    // return <ErrorMessage message={error.response.data.message} />;
    return <p>something went wrong</p>;
  }

  return (
    <Stack spacing={2}>
      <HomeCard>
        <DocumentsForm
          subtitle="Add Certificate or License"
          ConfirmButtonText="Add"
          CancelButtonText="Go Back"
          showModal={isAddCertificate}
          // onConfirm={handleAddDocuments}
          toggleModal={toggleAddCertificate}
        />

        <ConfirmationModal
          subtitle={`"Are you sure you want to delete ${deleteCertificate?.documentType} - ${deleteCertificate?.documentTitle} ?"`}
          ConfirmButtonText="Confirm Delete"
          CancelButtonText="Cancel"
          showModal={isDeleteConfirm}
          onConfirm={deleteDocumentHandler}
          toggleModal={toggleIsDeleteConfirm}
        />

        <Grid
          container
          columns={12}
          spacing={2}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={8}>
            <CardTitle
              style={{
                fontSize: "2rem",
              }}
            >
              Certificates & Licenses Details
            </CardTitle>
          </Grid>

          <Grid item xs={4}>
            <AddDocumentButton
              transparent
              type="button"
              onClick={toggleAddCertificate}
            >
              <AddIconStyles src={AddIcon} alt="plus" />
              Add New Document
            </AddDocumentButton>
          </Grid>
        </Grid>
      </HomeCard>

      <HomeCard>
        <Header container columns={10} spacing={2}>
          {header.map((value, index) => (
            <GridContainer item key={index} xs={2}>
              <CertificateRowHeading>{value}</CertificateRowHeading>
            </GridContainer>
          ))}
        </Header>

        {teacher.teacherdocuments.map((document, index) => (
          <CertificateRow container key={index} columns={10} spacing={2}>
            <GridContainer item xs={2}>
              <CertificateRowValue>
                {document.documentNumber}
              </CertificateRowValue>
            </GridContainer>
            <GridContainer item xs={2}>
              <CertificateRowValue>
                {document.documentTitle}
              </CertificateRowValue>
            </GridContainer>
            <GridContainer item xs={2}>
              <CertificateRowValue>{document.documentType}</CertificateRowValue>
            </GridContainer>
            <GridContainer item xs={2}>
              <CertificateRowValue>{document.expiryDate}</CertificateRowValue>
            </GridContainer>
            <GridContainer item xs={2}>
              <DeleteButon
                onClick={() => handleDeleteCertificate(document)}
                primary
              >
                Delete
              </DeleteButon>
            </GridContainer>
          </CertificateRow>
        ))}

        {teacher.teacherdocuments.length === 0 ? (
          <CertificateRowValue
            style={{
              textAlign: "center",
            }}
          >
            No documents to display
          </CertificateRowValue>
        ) : null}
      </HomeCard>
    </Stack>
  );
};

export default CertificatesLicenses;
