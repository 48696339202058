import http from "./httpService";

const apiEndpoint = http.apiUrl + "applicant-profile";

function applicantProfileUrl(jobId, teacherId) {
  return `${apiEndpoint}/${jobId}/${teacherId}`;
}

function teacherProfileURl(id) {
  return `${apiEndpoint}/teacher/${id}`;
}

// service is used to get applicant profile
export function getApplicantProfile(jobId, teacherId) {
  return http.get(applicantProfileUrl(jobId, teacherId));
}

// service is used to get applicant profile
export function getTeacherProfile(teacherId) {
  return http.get(teacherProfileURl(teacherId));
}
