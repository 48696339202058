import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import {
  JobForm,
  ConfirmationModal,
  RedirectTabs,
} from "../../../../../components";

// hooks
import useResponse from "../../../../../../hooks/useResponse";
import useVisible from "../../../../../../hooks/useVisible";

// job services
import { createJob } from "../../../../../../services/jobService";
import * as toast from "../../../../../../Toast";

const navLinkList = [
  {
    to: "/school/dashboard/job",
    tabName: "Jobs",
  },
  {
    to: `/school/dashboard/job/new-job`,
    tabName: "Create New Job",
  },
];

function NewJob() {
  const [showModal, toggleModal] = useVisible();
  const [isCancelled, toggleCancelled] = useVisible();
  const [jobFomData, setJobFomData] = useState();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [state, action] = useResponse();

  const handleSubmit = (data) => {
    setJobFomData(data);
    toggleModal();
  };

  const navigateToListing = () => {
    navigate(-1);
  };

  const handleJobCreateConfirm = async () => {
    action.sendingRequst();
    try {
      const response = await createJob({
        ...jobFomData,
      });

      const { message } = await response.data;
      toggleModal();
      navigateToListing();

      toast.success(message);
      action.successResponse(message);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        action.gotError(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  };

  const handleCancelOnConfirm = () => {
    navigateToListing();
  };

  return (
    <div>
      <ConfirmationModal
        subtitle="Please make sure all information entered for job is correct."
        ConfirmButtonText="Post Job"
        CancelButtonText="Continue Editing"
        showModal={showModal}
        onConfirm={handleJobCreateConfirm}
        toggleModal={toggleModal}
        loading={state.loading}
      />
      <ConfirmationModal
        subtitle="Are you sure you want to discard this job post?"
        ConfirmButtonText="Discard"
        CancelButtonText="Continue Editing"
        showModal={isCancelled}
        onConfirm={handleCancelOnConfirm}
        toggleModal={toggleCancelled}
      />

      <RedirectTabs redirectList={navLinkList} />
      <JobForm onSubmit={handleSubmit} onCancel={toggleCancelled} />
    </div>
  );
}

export default NewJob;
