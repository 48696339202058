import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "../../components/Button";

export const Header = styled(Grid)`
  margin: 0 auto;
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  border-bottom: 0.5px solid var(--gray-border);

  /* &:not(:last-child) {
  } */
`;

export const AddIconStyles = styled("img")`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

export const AddDocumentButton = styled(Button)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const DeleteButon = styled(Button)`
  padding: 5px 0;
  width: 80%;
  /* margin: 0 auto; */
`;

export const GridContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

// export const HeaderRow = styled();

export const CertificateRow = styled(Grid)`
  margin: 0 auto;
  margin-bottom: 1rem;
  padding-bottom: 2rem;

  &:not(:last-child) {
    border-bottom: 0.5px solid var(--gray-border);
  }
`;

export const CertificateRowHeading = styled("p")`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #181c29;
`;

export const CertificateRowValue = styled("p")`
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #565b64;
`;
