import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// components
import { Stack } from "@mui/system";
import {
  Loader,
  ErrorMessage,
  IconButton,
} from "../../../../../../../components";
import {
  AboutDetail,
  BioDetail,
  Card,
  CertificateLicenseTable,
  ContantDetail,
  Header,
  PersonalDetail,
  ProfileImage,
  TestimonialList,
} from "../../job/ApplicantProfile/components";
import { RedirectTabs } from "../../../../../../components";
import { CardTitle } from "../../../../../../components/JobForm";
import { TestimonialModal } from "../../../../../../../components/Modal";

// hooks
import useResponse from ".././../../../../../../hooks/useResponse";
import useVisible from "../../../../../../../hooks/useVisible";

// services
import { getTeacherProfile } from "../../../../../../../services/applicantProfileService";
import { createTeacherTestimonial } from "../../../../../../../services/testimonialService";

import * as toast from "../../../../../../../Toast";

// plus icon
import plusIcon from "../../../../../../../assets/icon/plus-circle.png";

import styles from "./teacherprofile.module.css";

function TeacherProfile({ navList }) {
  const navigate = useNavigate();
  const params = useParams();
  const [testimonialModal, toggleTestimonialModal] = useVisible(false);
  const [testimonialState, testimonialAction] = useResponse();
  const teacherId = params.id;

  const onError = (error) => {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status <= 409
    ) {
      navigate("/not-found");
    }
  };

  const { isError, isLoading, data, error, refetch } = useQuery(
    ["teacher-profile", teacherId],
    () => getTeacherProfile(teacherId),
    { refetchOnWindowFocus: true, onError, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const { teacher, teacherdocuments, testimonials } = data.data.data;

  const {
    email,
    fullName,
    phoneNumber,
    profileURL,
    teacherRegNumber,
    classPreference,
    expertise,
    locationAddress,
    bio,
  } = teacher;

  const handleTestimonialSubmit = async (testimonial) => {
    testimonialAction.sendingRequst();

    try {
      const response = await createTeacherTestimonial({
        ...testimonial,
        teacherId,
      });

      const { message } = await response.data;

      // removing testimonial popup
      toggleTestimonialModal();

      toast.success(message);
      testimonialAction.successResponse(message);

      // refetching the teacher profile data
      await refetch();
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        toast.error(error.response.data.message);
        testimonialAction.gotError(error.response.data.message);
      }
    }
  };

  const cardStyle = { minheight: "18rem", width: "27rem" };
  return (
    <>
      <TestimonialModal
        onSubmit={handleTestimonialSubmit}
        showModal={testimonialModal}
        toggleModal={toggleTestimonialModal}
        loading={testimonialState.loading}
      />
      <div>
        <RedirectTabs redirectList={navList} />
        <Header title="Teacher Profile"></Header>

        <CardTitle style={{ marginTop: "3rem" }}>Personal Details</CardTitle>
        <Card style={{ marginBottom: "1.6rem", marginTop: "2.3rem" }}>
          <div className={styles.profileContainer}>
            <Stack
              display="flex"
              flexDirection="row"
              columnGap={2.4}
              marginTop={0}
            >
              <ProfileImage imageUrl={profileURL} name={fullName} />
              <Card style={cardStyle}>
                <PersonalDetail
                  // title="Personal Details"
                  detail={{
                    fullName,
                    teacherRegNumber,
                    locationAddress,
                    experties: expertise[0],
                  }}
                />
              </Card>
            </Stack>
            <Stack
              display="flex"
              flexDirection="row"
              columnGap={2.4}
              marginTop={0}
            >
              <Card border style={cardStyle}>
                <ContantDetail
                  title="Contact Details"
                  detail={{ email, phoneNumber }}
                />
              </Card>
              <Card border style={cardStyle}>
                <AboutDetail
                  title="About"
                  detail={{ classPreference, expertise }}
                />
              </Card>
            </Stack>
          </div>
        </Card>
        <Card>
          <BioDetail title="Other Details" content={bio} />
        </Card>

        <Stack display="flex" flexDirection="row" columnGap={2.4} mt={2}>
          <CertificateTestimonial
            certificateLicenseList={teacherdocuments}
            testimonialList={testimonials}
            onAddTestimonial={toggleTestimonialModal}
          />
        </Stack>
      </div>
    </>
  );
}

export default TeacherProfile;

const CertificateTestimonial = ({
  certificateLicenseList,
  testimonialList = [],
  onAddTestimonial,
}) => {
  const [showLicense, setShowLicense] = useState(true);
  const { school } = useSelector((state) => state.entities.schoolRegisteration);

  let children;
  if (showLicense) {
    children = (
      <CertificateLicenseTable
        certificateLicenseList={certificateLicenseList}
      />
    );
  } else {
    children = (
      <Stack display="flex" flexDirection="row" columnGap={2.4} mt={1.6}>
        <TestimonialList testimonials={testimonialList} />
      </Stack>
    );
  }

  const schoolId = school.id;
  const filteredTestimonialLength = testimonialList.filter(
    (testimonial) => testimonial.schoolId.id === schoolId
  ).length;
  /*
   * using not operator here because school has already given testimonial to teacher
   * then show testimonial button is false
   */
  let showTestimonialButton = !Boolean(filteredTestimonialLength);

  // let showTestimonialButton = true;

  const tabItemClass = "active";
  return (
    <div style={{ width: "100%" }}>
      <Stack
        width={"100%"}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <div className={styles.tabContainer}>
          <div
            className={`${styles.tabItem} ${
              showLicense && styles[tabItemClass]
            }`}
            onClick={() => setShowLicense(true)}
          >
            <p>Licenses and Certificates</p>
            <span></span>
          </div>
          <div
            className={`${styles.tabItem} ${
              !showLicense && styles[tabItemClass]
            }`}
            onClick={() => setShowLicense(false)}
          >
            <p>Testimonials</p>
            <span></span>
          </div>
        </div>
        {!showLicense && showTestimonialButton && (
          <IconButton
            icon={plusIcon}
            white
            style={{ width: "25rem" }}
            onClick={onAddTestimonial}
          >
            Add Testomonial
          </IconButton>
        )}
      </Stack>
      {children && children}
    </div>
  );
};
