import React from "react";
import { styled } from "@mui/material/styles";

const CustomButton = styled("button")`
  border: none;
  outline: none;
  width: 100%;
  padding: 1.5rem 2rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  border-radius: 8px;

  text-align: center;
  background-color: ${(props) =>
    props.primary ? "var(--primary-full)" : "transparent"};

  color: ${(props) => (props.primary ? "#fff" : "var(--primary-full)")};

  border: 2px solid
    ${(props) => (props.primary ? "transparent" : "var(--primary-full)")};

  opacity: 1;

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Button = ({ children, ...otherProps }) => {
  return <CustomButton {...otherProps}>{children}</CustomButton>;
};

export default Button;
