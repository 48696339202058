import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import styles from "./sideNav.module.css";

// import * as authService from "../../../services/authService";

// logo
import Logo from "../../assets/logo/Logo.svg";

function SideNav({ navLinkList }) {
  // const [showLogoutModal, toggleLogoutModal] = useVisible(false);
  const location = useLocation();

  // const handleLogout = () => {
  //   authService.logout();
  //   toast.success("Successfully logged out!");
  //   toggleLogoutModal();
  //   window.location.reload();
  // };

  return (
    <>
      {/* <ConfirmationModal
        title="Are you sure you want to logout?"
        showModal={showLogoutModal}
        toggleModal={toggleLogoutModal}
        onConfirm={handleLogout}
        ConfirmButtonText="LOGOUT"
      /> */}
      <div className={styles.sideNav}>
        <div className={styles.logoHeader}>
          <img
            className={styles.logoImage}
            src={Logo}
            title="staff view logo"
            // width="80"
            // height="80"
            alt="staff view logo"
          />
        </div>
        <div className={styles.menuList}>
          {navLinkList.map(({ whiteIcon, blackIcon, menuName, to, id }) => (
            <NavLink
              key={id}
              to={to}
              className={(navData) =>
                navData.isActive
                  ? `${styles.active} ${styles.menuItem}`
                  : styles.menuItem
              }
            >
              {location.pathname.startsWith(to) ? (
                <img src={whiteIcon} className={styles.icon} alt="Nav icons" />
              ) : (
                <img src={blackIcon} className={styles.icon} alt="Nav icons" />
              )}
              {/* <img src={blackIcon} className={styles.icon} alt="Nav icons" /> */}
              <span>{menuName}</span>
            </NavLink>
          ))}

          {/* <div className={styles.menuItem} onClick={toggleLogoutModal}>
            <LogoutIcon className={styles.icon} />
            <span>Logout</span>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default SideNav;
