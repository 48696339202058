import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

// components
import { HomeCard } from "../../../../../../components/dashboard";
import { CardTitle } from "../../../../../../components/JobForm";

import styles from "../home.module.css";
import { useQuery } from "react-query";
import { Loader } from "../../../../../../../components";
import { getRecentlyAppliedJobs } from "../../../../../../../services/teacherHomeService";
import { JOB_STATUS } from "../../../../../../../constants/job";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import moment from "moment";

const Card = styled(Link)`
  border: 1px solid #eee9e8;
  padding: 12px 16px 16px;
  // box-shadow: inset 0px 0px 1px 0.5px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  display: block;
  text-decoration: none;
  cursor: pointer;
`;

const SchoolJobTitle = styled("p")`
  font-size: 1.6rem;

  color: #181c29;
`;

const Flex = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
  /* justify-content: flex-start; */
  margin-bottom: 0.5rem;
`;

const SchoolYear = styled("p")`
  font-size: 1.4rem;
  color: #565b64;
`;

const SchoolName = styled("p")`
  font-size: 1.3rem;
  color: #565b64;
`;

const SchoolLocation = styled("p")`
  font-size: 1.2rem;
  color: #99a0ac;
`;

const SchoolDate = styled("p")`
  color: #565b64;
  text-transform: uppercase;
  font-size: 1rem;
`;

const Dot = styled("span")`
  width: 6px;
  height: 6px;
  background: #99a0ac;
  border-radius: 50%;
`;

const Tag = styled("span")`
  padding: 4px 8px;
  background: #f8f5f1;
  border-radius: 12px;
  font-size: 1rem;

  text-transform: uppercase;

  color: ${(props) => {
    if (props.variant === "applied") {
      return "#4A7D9A";
    }

    if (props.variant === "booked") {
      return "#6C6A94";
    }

    if (props.variant === "assigned") {
      return "#50AB7F";
    }

    if (props.variant === "cancelled") {
      return "#A04D47";
    }
    return "#4A7D9A";
  }};
`;

function RecentlyAppliedJobCard() {
  const { isError, isLoading, data } = useQuery(
    ["recently-applied-jobs"],
    () => getRecentlyAppliedJobs(),
    { refetchOnWindowFocus: true, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <p>Some error occurred</p>;
  }

  const appliedJobs = data.data.jobs;

  return (
    <HomeCard className={styles.gridSecondchild}>
      <CardTitle
        style={{
          marginBottom: "1rem",
        }}
      >
        Recently Applied Jobs
      </CardTitle>

      <Grid
        // sx={{
        //   height: "100%",
        // }}
        container
        spacing={2}
        columns={12}
      >
        {appliedJobs.map((appliedJob) => (
          <Grid item xs={6} key={appliedJob._id}>
            <Card to={`/teacher/dashboard/jobs/detail/${appliedJob.jobId}`}>
              <Flex>
                <SchoolJobTitle>{appliedJob.job.jobTitle}</SchoolJobTitle>
                <SchoolYear>{appliedJob.job.classYear}</SchoolYear>
              </Flex>
              <Flex>
                <SchoolName>{appliedJob.school.schoolName}</SchoolName>
                <SchoolLocation>
                  {appliedJob.school.suburbName}, {appliedJob.school.state}
                </SchoolLocation>
              </Flex>
              <Flex>
                <SchoolDate>
                  {moment(appliedJob.job.startDate).format("D MMM YYYY")}
                </SchoolDate>
                <Dot />
                <Tag variant={appliedJob.status}>
                  {JOB_STATUS[appliedJob.status]}
                </Tag>
              </Flex>
            </Card>
          </Grid>
        ))}
      </Grid>

      {appliedJobs.length === 0 ? (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <p
            style={{
              color: "var(--text-tertiary)",
              fontSize: "1.7rem",
              fontWeight: 600,
            }}
          >
            No Recently Applied Jobs
          </p>
        </Stack>
      ) : null}
    </HomeCard>
  );
}

export default RecentlyAppliedJobCard;
