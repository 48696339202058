import React from "react";
import { Formik } from "formik";

function AppForm({
  initialValues,
  onSubmit,
  validationSchema,
  children,
  style,
  className,
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <form
          onSubmit={formik.handleSubmit}
          style={{ width: "100%", ...style }}
          className={className}
        >
          {children}
        </form>
      )}
    </Formik>
  );
}

export default AppForm;
