import React from "react";
import * as Yup from "yup";

import { Stack } from "@mui/material";
import { Formik } from "formik";
import { TextArea } from "../../job-form";
import { ModalWrapper } from "../../../pages/components";
import { FormButton } from "../../Forms";
import Rating from "@mui/material/Rating";
import CardTitle from "../../CardTitle";

const initialValues = {
  comment: "",
  ratings: 5,
};

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .min(10)
    .max(200)
    .label("Testimonial Feedback")
    .required("Testimonial Feedback is required"),
  ratings: Yup.number()
    .min(1)
    .max(5)
    .label("Ratings")
    .required("Ratings is required"),
});

function TestimonialModal({
  showModal,
  toggleModal,
  onSubmit,
  title,
  subtitle,
  note,
  error,
  loading = false,
}) {
  if (!showModal) return null;

  return (
    <ModalWrapper
      toggleModal={toggleModal}
      title={title}
      subtitle={subtitle}
      note={note}
      error={error}
    >
      <Stack>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <>
              <Stack spacing={1}>
                <CardTitle component="legend">Ratings</CardTitle>
                <Rating
                  name="simple-controlled"
                  size="large"
                  value={values.ratings}
                  onChange={(event, newValue) => {
                    setFieldValue("ratings", newValue);
                  }}
                  precision={0.5}
                  style={{ fontSize: "5rem" }}
                />
                <CardTitle component="legend">Testimonial Feedback</CardTitle>
                <TextArea
                  label="Testimonial Feedback"
                  name="comment"
                  type="text"
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  marginTop: "2rem",
                }}
              >
                <FormButton
                  buttonText="Cancel"
                  type="button"
                  submitButton={false}
                  isBackground={false}
                  onClick={toggleModal}
                />
                <FormButton
                  buttonText="Submit"
                  type="submit"
                  submitButton={true}
                  isDisabled={true}
                  loading={loading}
                />
              </Stack>
            </>
          )}
        </Formik>
      </Stack>
    </ModalWrapper>
  );
}

export default TestimonialModal;
