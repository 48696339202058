import React from "react";
import styles from "./styles.module.css";

function CertificateLicenseHeader() {
  return (
    <tr className={`${styles.itemContainer}  ${styles.headerCellContainer}`}>
      <th className={styles.headerText}>Document Number</th>
      <th className={styles.headerText}>Document Title</th>
      <th className={styles.headerText}>Document Type</th>
      <th className={styles.headerText}>Expiry Date</th>
    </tr>
  );
}

export default CertificateLicenseHeader;
