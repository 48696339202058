import {
  createSelector,
  createSlice,
  createAsyncThunk,
  // current,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addCertificateTeacher,
  deleteCertificateTeacher,
  getTeacherInfo,
  updateTeacherChangePassord,
  updateTeacherEditProfile,
} from "../services/profileService";

import {
  getTeacherFirstFormData,
  getTeacherSecondFormData,
  // getTeacherThirdFormData,
  setTeacherFirstFormData,
  setTeacherSecondFormData,
  setTeacherThirdFormData,
  // getSchoolSecondFormData,
} from "../utils/teacherRegisterData";

export const fetchTeacherInfo = createAsyncThunk(
  "teacher/fetchAll",
  async () => {
    const response = await getTeacherInfo();
    const data = await response.data;

    return data;
  }
);

export const editProfileUpdate = createAsyncThunk(
  "teacher/update-edit-profile",
  async (details) => {
    try {
      const response = await updateTeacherEditProfile(details);
      const data = await response.data;
      toast.success(response.data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      // console.log();
      return error;
    }
  }
);

export const teacherChangePassword = createAsyncThunk(
  "teacher/change-password",
  async (details) => {
    try {
      const response = await updateTeacherChangePassord(details);
      const data = await response.data;
      toast.success(response.data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return error;
    }
  }
);

export const teacherAddCertificate = createAsyncThunk(
  "teacher/add-certificate",
  async (details) => {
    try {
      const response = await addCertificateTeacher(details);
      const data = await response.data;
      toast.success(response.data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return new Error(error);
    }
  }
);

export const teacherDeleteCertificate = createAsyncThunk(
  "teacher/delete-certificate",
  async (id) => {
    try {
      const response = await deleteCertificateTeacher(id);
      const data = await response.data;
      toast.success(response.data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return new Error(error);
    }
  }
);

const initialState = {
  loading: false,
  teacher: {
    id: "",
    fullName: "",
    email: "",
    password: "",
    phoneNumber: "",
    suburbName: "",
    state: "",
    postcode: "",
    teacherRegNumber: "",
    availability: [],
    category: "",
    classPreference: [],
    expertise: [],
    bio: "",
    locationAddress: "",
    latitude: "",
    longitude: "",
    radius: "",
    teacherdocuments: [],
    profileURL: "",
  },
  deleteCertificate: {},
  error: "",
};

const techerRegisterationSlice = createSlice({
  name: "teacher-register",
  initialState: initialState,
  reducers: {
    setTeacherStepFirstData: (state, action) => {
      state.teacher = { ...state.teacher, ...action.payload };
      delete action.payload.cPassword;

      // storing in local storage
      setTeacherFirstFormData(action.payload);
    },
    setTeacherStepSecondData: (state, action) => {
      state.teacher = { ...state.teacher, ...action.payload };

      // storing in local storage
      setTeacherSecondFormData(action.payload);
    },
    setTeacherStepThirdData: (state, action) => {
      state.teacher = { ...state.teacher, ...action.payload };

      // storing in local storage
      setTeacherThirdFormData(action.payload);
    },
    addCertificateToDelete: (state, action) => {
      state.deleteCertificate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeacherInfo.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchTeacherInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.teacher = {
          ...action.payload.data.teacher,
          teacherdocuments: action.payload.data.teacherdocuments,
        };
      })
      .addCase(fetchTeacherInfo.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.error;
      });

    builder
      .addCase(editProfileUpdate.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(editProfileUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.teacher = {
          ...state.teacher,
          ...action.payload.data.teacher,
        };
      })
      .addCase(editProfileUpdate.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.error;
      });

    builder
      .addCase(teacherChangePassword.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(teacherChangePassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(teacherChangePassword.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.error;
      });

    builder
      .addCase(teacherAddCertificate.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(teacherAddCertificate.fulfilled, (state, action) => {
        let teacherdocuments = state.teacher.teacherdocuments;
        teacherdocuments.push(action.payload.data.document);
        state.loading = false;
        state.teacher.teacherdocuments = teacherdocuments;
      })
      .addCase(teacherAddCertificate.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.error;
      });

    builder
      .addCase(teacherDeleteCertificate.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(teacherDeleteCertificate.fulfilled, (state, action) => {
        let teacherdocuments = state.teacher.teacherdocuments;
        teacherdocuments = teacherdocuments.filter(
          (item) => item.id !== action.payload.data.document.id
        );

        state.loading = false;
        state.teacher.teacherdocuments = teacherdocuments;
      })
      .addCase(teacherDeleteCertificate.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const {
  setTeacherStepFirstData,
  setTeacherStepSecondData,
  setTeacherStepThirdData,
  addCertificateToDelete,
} = techerRegisterationSlice.actions;
export default techerRegisterationSlice.reducer;

export const getTeacherRegisterInfo = createSelector(
  (state) => state?.entities.teacherRegisteration.teacher,
  (teacher) => {
    const {
      fullName,
      email,
      password,
      phoneNumber,
      suburbName,
      state,
      postcode,
      teacherRegNumber,
      availability,
      category,
      classPreference,
      expertise,
      bio,
      locationAddress,
      latitude,
      longitude,
      radius,
    } = teacher;

    // getting teacher registration data from global storage
    let firstFormData = getTeacherFirstFormData();
    let firstSecondData = getTeacherSecondFormData();
    if (firstFormData && firstSecondData) {
      return { ...firstFormData, ...firstSecondData };
    }

    return {
      fullName,
      email,
      password,
      phoneNumber,
      suburbName,
      state,
      postcode,
      teacherRegNumber,
      availability,
      category,
      classPreference,
      expertise,
      bio,
      locationAddress,
      latitude,
      longitude,
      radius,
    };
  }
);

export const getTeacherCertificates = createSelector(
  (state) => state?.entities.teacherRegisteration.teacher,
  (teacher) => {
    const { teacherdocuments } = teacher;
    return teacherdocuments.filter(
      (document) => document.documentType === "Certificate"
    );
  }
);

export const getTeacherLicenses = createSelector(
  (state) => state?.entities.teacherRegisteration.teacher,
  (teacher) => {
    const { teacherdocuments } = teacher;
    return teacherdocuments.filter(
      (document) => document.documentType === "License"
    );
  }
);

export const getTeacherHeaderInfo = createSelector(
  (state) => state?.entities.teacherRegisteration.teacher,
  (teacher) => {
    const { fullName, profileURL } = teacher;

    return {
      fullName,
      profileURL,
    };
  }
);
