import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// school pages
import SchoolLogin from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import SchoolDashboard from "./pages/dashboard";

// guest & protected route
import { GuestRoute, ProtectedRoute } from "../../components";

// register step form
import { RegisterStepFirst, RegisterStepSecond } from "./pages/register";

function School() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/school/login" />} />
        <Route
          path="/login"
          element={
            <GuestRoute>
              <SchoolLogin />
            </GuestRoute>
          }
        />

        <Route
          path="/register-step-first"
          element={
            <GuestRoute>
              <RegisterStepFirst />
            </GuestRoute>
          }
        />

        <Route
          path="/register-step-second"
          element={
            <GuestRoute>
              <RegisterStepSecond />
            </GuestRoute>
          }
        />
        <Route
          path="/login"
          element={
            <GuestRoute>
              <SchoolLogin />
            </GuestRoute>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <GuestRoute>
              <ForgotPassword />
            </GuestRoute>
          }
        />
        <Route
          path="/reset/:token"
          element={
            <GuestRoute>
              <ResetPassword />
            </GuestRoute>
          }
        />
        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute>
              <SchoolDashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default School;
