import React from "react";
import styles from "./button.module.css";

function Button({
  buttonText,
  onClick,
  icon,
  size = 1.6,
  borderRadius = 8,
  isBackground = true,
  loading = false,
  ...otherProps
}) {
  return (
    <button
      className={`${styles.button} ${
        isBackground ? styles.withBackground : styles.noBackground
      }`}
      onClick={onClick}
      style={{
        borderRadius: `${borderRadius}px`,
      }}
      {...otherProps}
    >
      {loading ? (
        "loading..."
      ) : (
        <>
          {icon && <img src={icon} alt="button icon" className={styles.icon} />}
          <span className={styles.buttonText}>{buttonText}</span>
        </>
      )}
    </button>
  );
}

export default Button;
