import React from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { ProfileImage } from "../../pages/components/TopBar";

const CardContainer = styled("div")`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 12px;
  padding: 12px;
  :not(:last-child) {
    border-bottom: 1px solid #eee9e8;
  }
`;
// const ProfilePic = styled("img")`
//   width: 30px;
//   height: 30px;
//   border-radius: 50%;
// `;
const Text = styled("p")`
  font-size: 1.5rem;
  font-weight: 300;

  b {
    font-weight: 500;
  }
`;
const Time = styled("p")`
  font-weight: 300;
  font-size: 1.2rem;
`;

const NotificationCard = ({ notification }) => {
  const { notificationText, createdAt } = notification;
  const { fullName, profileURL } = notification.sender;

  return (
    <CardContainer key={notification._id}>
      <ProfileImage imageUrl={profileURL} name={fullName} />

      <Stack
        direction="column"
        sx={{
          gap: "8px",
        }}
      >
        <Text dangerouslySetInnerHTML={{ __html: notificationText }} />
        <Time>{moment(createdAt).fromNow(true)} ago</Time>
      </Stack>
    </CardContainer>
  );
};

export default NotificationCard;
