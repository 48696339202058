import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Stack } from "@mui/material";
import { ErrorMessage, useField } from "formik";
import SuccessAndErrorMessage from "./SuccessAndErrorMessage";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

// services
import http from "../../services/httpService";

const CustomButton = styled(Button)`
  border: none;
  outline: none;
  width: 100%;
  padding: 1.5rem 2rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  border-radius: 8px;

  :hover {
    border: none;
    outline: none;
    width: 100%;
    padding: 1.5rem 2rem;
    cursor: pointer;
  }

  text-align: center;
  background-color: ${(props) =>
    props.primary ? "var(--primary-full)" : "transparent"};

  color: ${(props) => (props.primary ? "#fff" : "var(--primary-full)")};

  border: 2px solid
    ${(props) => (props.primary ? "transparent" : "var(--primary-full)")};

  :hover {
    border: none;
    outline: none;
    width: 100%;
    padding: 1.5rem 2rem;
    background-color: ${(props) =>
      props.primary ? "var(--primary-full)" : "transparent"};

    color: ${(props) => (props.primary ? "#fff" : "var(--primary-full)")};

    border: 2px solid
      ${(props) => (props.primary ? "transparent" : "var(--primary-full)")};
  }
`;

function FormImageInput({
  name,
  label,
  imageValues,
  checkResolution,
  onChangeSelectedImage,
  onError,
  onCheckRes,
  labelResolution,
}) {
  const [field] = useField(name);
  const [preview, setPreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (event) => {
    if (!event.target.files) {
      return;
    }

    const selectedFile = event.target.files[0];
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    onChangeSelectedImage(event);

    // validate resolutions
    const image = new Image();
    image.src = objectUrl;

    image.onload = (img) => {
      // const width = image.width;
      // const height = image.height;

      // if (
      //   (width < 600 && height < 600) ||
      //   (width >= 600 && height < 600) ||
      //   (width < 600 && height >= 600)
      // ) {
      //   onError("Image size be more than 600x600 pixels.");
      //   setErrorMessage("Image size be more than 600x600 pixels.");
      //   onCheckRes(true);
      // } else {
      onCheckRes(false);
      setErrorMessage("");
      // }
    };

    image.onerror = (error) => {
      onError("Input file contains unsupported image format.");
      setErrorMessage("Input file contains unsupported image format.");
      onCheckRes(true);
    };
  };

  return (
    <>
      <Stack spacing={2}>
        <Stack>
          <CustomButton
            component="label"
            variant="outlined"
            startIcon={<FileUploadOutlinedIcon />}
            sx={{ margin: 0 }}
          >
            Upload {label} {field.value && `- ${field.value.name}`}
            <input
              type="file"
              accept="image/*, image/jpeg"
              hidden
              name={name}
              onBlur={field.onBlur}
              onChange={handleFileChange}
            />
          </CustomButton>
          {/* <p className="note">
            <span className="w-700">Recommended Resolution : </span>
            {labelResolution} (Less than 1 MB in size)
          </p> */}
        </Stack>
        {field.value && (
          <img
            src={field.value ? preview : `${http.baseUrl}images/${imageValues}`}
            alt="venue pic"
            width={100}
          />
        )}
      </Stack>
      <ErrorMessage name={name}>
        {(message) => <SuccessAndErrorMessage error={message} />}
      </ErrorMessage>
      {checkResolution && field.value && (
        <SuccessAndErrorMessage error={errorMessage} />
      )}
    </>
  );
}

export default FormImageInput;
