import React from "react";

import { HomeJobItem } from ".";

import styles from "./home.module.css";

const jobListingExample = [
  {
    id: 1,
    position: "Mathematics Teacher",
    classYear: "Year V",
    schoolName: "Best Primary School",
    schoolAddress: "Barossa Valley, SA",
    startDate: "23 Jan 2021",
    jobType: "Emergency",
    recurrence: "Non-recurring",
  },
  {
    id: 2,
    position: "Mathematics Teacher",
    classYear: "Year V",
    schoolName: "Best Primary School",
    schoolAddress: "Barossa Valley, SA",
    startDate: "23 Jan 2021",
    jobType: "Emergency",
    recurrence: "Non-recurring",
  },
  {
    id: 3,
    position: "Mathematics Teacher",
    classYear: "Year V",
    schoolName: "Best Primary School",
    schoolAddress: "Barossa Valley, SA",
    startDate: "23 Jan 2021",
    jobType: "Emergency",
    recurrence: "Non-recurring",
  },
  {
    id: 1,
    position: "Mathematics Teacher",
    classYear: "Year V",
    schoolName: "Best Primary School",
    schoolAddress: "Barossa Valley, SA",
    startDate: "23 Jan 2021",
    jobType: "Emergency",
    recurrence: "Non-recurring",
  },
  {
    id: 2,
    position: "Mathematics Teacher",
    classYear: "Year V",
    schoolName: "Best Primary School",
    schoolAddress: "Barossa Valley, SA",
    startDate: "23 Jan 2021",
    jobType: "Emergency",
    recurrence: "Non-recurring",
  },
  {
    id: 3,
    position: "Mathematics Teacher",
    classYear: "Year V",
    schoolName: "Best Primary School",
    schoolAddress: "Barossa Valley, SA",
    startDate: "23 Jan 2021",
    jobType: "Emergency",
    recurrence: "Non-recurring",
  },
  {
    id: 1,
    position: "Mathematics Teacher",
    classYear: "Year V",
    schoolName: "Best Primary School",
    schoolAddress: "Barossa Valley, SA",
    startDate: "23 Jan 2021",
    jobType: "Emergency",
    recurrence: "Non-recurring",
  },
  {
    id: 2,
    position: "Mathematics Teacher",
    classYear: "Year V",
    schoolName: "Best Primary School",
    schoolAddress: "Barossa Valley, SA",
    startDate: "23 Jan 2021",
    jobType: "Long-term",
    recurrence: "Non-recurring",
  },
  {
    id: 3,
    position: "Mathematics Teacher",
    classYear: "Year V",
    schoolName: "Best Primary School",
    schoolAddress: "Barossa Valley, SA",
    startDate: "23 Jan 2021",
    jobType: "Long-term",
    recurrence: "Non-recurring",
  },
];

function HomeJobListing({ jobListing, onJobClick }) {
  return (
    <div className={styles.listingContainer}>
      {jobListing.map((job, index) => (
        <HomeJobItem
          job={{
            ...job,
            schoolName: job.schoolId.schoolName,
            schoolAddress: job.schoolId.schoolAddress,
          }}
          onJobClick={onJobClick}
          key={job.id}
        />
      ))}
    </div>
  );
}

export default HomeJobListing;
