import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// pages
import TeacherProfile from "../TeacherProfile/TeacherProfile";
import TeacherListing from "./components/TeacherListing";

function getNavLinkList(teacherId) {
  const navLinkList = [
    {
      to: "/school/dashboard/my-teachers",
      tabName: "All Teachers",
    },

    {
      to: `/school/dashboard/my-teachers/profile/${teacherId}`,
      tabName: "Teacher’s Profile",
    },
  ];
  return navLinkList;
}

function AllTeacher() {
  const navigate = useNavigate();
  const [teacherId, setTeacherId] = useState("");

  const handleCellClick = (teacherId) => {
    setTeacherId(teacherId);
    navigate(`/school/dashboard/my-teachers/profile/${teacherId}`);
  };

  return (
    <Routes>
      <Route
        path="/"
        element={<TeacherListing onCellClick={handleCellClick} />}
      />
      <Route
        path="/profile/:id"
        element={<TeacherProfile navList={getNavLinkList(teacherId)} />}
      />
    </Routes>
  );
}

export default AllTeacher;
