import React from "react";
import { useField } from "formik";

// components
import { FormErrorMessage } from "../Forms";

import styles from "./formInput.module.css";

function getDate(date = new Date()) {
  var today = new Date(date);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  return today;
}

function FormInputDate({ name, label }) {
  const [field, meta] = useField(name);
  let className;

  if (meta.touched && meta.error) {
    className = "error";
  } else if (meta.touched && !meta.error) {
    className = "success";
  }

  return (
    <div className={styles.InputfieldContainer}>
      <input
        type="date"
        className={`${styles.inputText} ${styles[className]}`}
        placeholder={label}
        required
        {...field}
        min={getDate()}
      />
      <FormErrorMessage name={field.name} />
    </div>
  );
}

export default FormInputDate;
