import React from "react";

import styles from "./allJobListing.module.css";

function AllJobHeader() {
  return (
    <tr className={`${styles.itemContainer} ${styles.headerCellContainer}`}>
      <th className={`${styles.headerText} ${styles.dateTimeColumn} `}>
        Date & Time
      </th>
      <th className={`${styles.headerText} ${styles.schoolLocation}`}>
        Job Title
      </th>
      <th className={styles.headerText}>Class Year</th>
      <th className={`${styles.headerText} ${styles.schoolLocation}`}>
        School & Location
      </th>
      <th className={styles.headerText}>Job Type</th>
      <th className={styles.headerText}>Status</th>
    </tr>
  );
}

export default AllJobHeader;
