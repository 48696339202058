import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import Layout from "../../../../components/Layout";
import TeacherSignupFormThird from "../../../components/TeacherSignupFormThird";

// hooks
import useResponse from "../../../../hooks/useResponse";

// image
import StepFirstImage from "../../../../assets/images/t-step1.png";

// action creator
import {
  getTeacherRegisterInfo,
  setTeacherStepThirdData,
} from "../../../../stores/teacherRegisterationReducer";

// services
import * as teacherAuthService from "../../../../services/teacherAuthService";
import * as toast from "../../../../Toast";

// utils
import { clearTeacherRegistrationData } from "../../../../utils/teacherRegisterData";

const radiusListObject = {
  "2Km": 2,
  "5km": 5,
  "10km": 10,
  "50km": 50,
  "100km": 100,
};

function RegisterStepThird() {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [state, action] = useResponse();
  const teacherRegisterInfo = useSelector(getTeacherRegisterInfo);

  const handleRegisterTeacher = async (teacherdocuments) => {
    action.sendingRequst();

    let {
      // availability = [],
      // classPreference = [],
      // expertise = [],
      radius,
    } = teacherRegisterInfo;
    radius = radiusListObject[radius];

    try {
      const response = await teacherAuthService.teacherRegister({
        ...teacherRegisterInfo,
        teacherdocuments,
        // availability: availability.toString(),
        // classPreference: classPreference.toString(),
        // expertise: expertise.toString(),
        radius,
      });
      const { message } = await response.data;

      dispatch(
        setTeacherStepThirdData({
          teacherdocuments,
        })
      );
      navigator("/teacher");
      // formkiObject.resetForm();
      toast.success(message);
      action.successResponse(message);

      // clear school registration data from global storage
      clearTeacherRegistrationData();
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        action.gotError(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  };

  const handleSubmit = async (teacherData) => {
    const { teacherdocuments } = teacherData;
    await handleRegisterTeacher(teacherdocuments);
  };

  const handleSkipContinue = async () => {
    const teacherdocuments = [];
    await handleRegisterTeacher(teacherdocuments);
  };

  return (
    <Layout imageUrl={StepFirstImage}>
      <TeacherSignupFormThird
        title="Create Your Account"
        onSubmit={handleSubmit}
        error={state.error}
        onSkip={handleSkipContinue}
      />
    </Layout>
  );
}

export default RegisterStepThird;
