import React from "react";

import styles from "./multiSelect.module.css";

function CheckBoxSingleSelect({ field, option, setValue, otherProps }) {
  return (
    <div className={styles.checkBoxItem}>
      <label className={styles.label} htmlFor={option.value}>
        {option.key}
      </label>
      <input
        className={styles.checkbox}
        type="checkbox"
        id={option.value}
        {...otherProps}
        {...field}
        onChange={(event) => {
          if (event.target.checked) {
            setValue(event.target.value);
          } else {
            setValue("");
          }
        }}
        value={option.value}
        checked={field.value === option.value}
      />
    </div>
  );
}

export default CheckBoxSingleSelect;
