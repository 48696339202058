import React from "react";
import { styled } from "@mui/material/styles";

// components
import { CardTitle } from "../../../../../../../../../components";
import { CardWrapper } from "../../../components/details";
import { ContentItemWrapper } from "../../../../../../../../teacher/pages/dashboard/pages/jobs/components/ScheduleDetails";

const Text = styled("p")`
  color: var(--text-secondary);
  line-height: 16px;
  letter-spacing: 0.4px;
  font-size: 1.4rem;
  font-weight: 400;
`;

function PersonalDetail({ title, detail }) {
  const { fullName, teacherRegNumber, experties, locationAddress } = detail;
  return (
    <CardWrapper title={title} style={{ padding: "1.8rem 1.2rem 1.6rem" }}>
      <ContentItemWrapper>
        <CardTitle>{fullName}</CardTitle>
        <Text>{teacherRegNumber}</Text>
      </ContentItemWrapper>
      <ContentItemWrapper>
        <CardTitle>{experties}</CardTitle>
        <Text>{locationAddress}</Text>
      </ContentItemWrapper>
    </CardWrapper>
  );
}

export default PersonalDetail;
